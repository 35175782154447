import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setViewChatHistory } from '../../../pages/Homepage/HomeSlice.js';
import TextArea from 'antd/es/input/TextArea.js';
import { ChatsCircle, DotsThree, DownloadSimple, FileMagnifyingGlass, Funnel, MagnifyingGlass, PencilSimpleLine, PenNib, ShareFat, Trash, X } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import { generateDownloadDraftUrl, generateSharedId, resetDeletedThread, resetDownloadUrl, resetUpdateActions, resetUpdatedTitle, setThreadSelectedToShare } from '../threadsSlice.js';
import DeleteThreadModal from '../../../components/chat/Modals/DeleteThreadModal';
import UpdateChatTitleModal from '../../../components/chat/Modals/UpdateThreadTitleModal';
import ShareThreadModal from '../../../components/chat/Modals/ShareThreadModal';
import { trackAnalytics } from 'src/pages/helpers.js';

const ChatHistory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [actionOnThread, setActionOnThread] = useState(""); // actions that can be done on a thread i.e Rename, Delete, Share
    const [updateThreadInfo, setUpdateThreadInfo] = useState({}); // thread which we are updating
    const [updateActionLoading, setUpdateActionLoading] = useState(false);
    const [showChatHistory, setShowChatHistory] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState([]);
    const [renameActiveId, setRenameActiveId] = useState(null);
    const [filteredResults, setFilteredResults] = useState({});
    const [textQuery, setTextQuery] = useState("");
    const [resultsExist, setResultsExist] = useState(false);

    const threads = useSelector((state) => state.threads.threads);
    const viewChatHistory = useSelector((state) => state.homeslice.viewChatHistory);
    const deletedThread = useSelector((state) => state.threads.deletedThread);
    const updatedTitle = useSelector((state) => state.threads.updatedTitle);
    const sidenavCollapsed = useSelector((state) => state.homeslice.sidenavCollapsed);
    const email = useSelector((state) => state.homeslice.email);
    const lawyerHubData = useSelector((state) => state.onboarding.lawyerHubData)
    const downloadDraftUrl = useSelector((state) => state.threads.downloadDraftUrl);
    const downloadDraftUrlStatus = useSelector((state) => state.threads.downloadDraftUrlStatus);

    const dropdownClicked = (index) => {
        setActiveIndex(index);
        dispatch(resetUpdateActions())
        // on clicking here, setactive index, reset share thread status, delete thread status, etc.
    }

    const onPerformThreadAction = (thread, action, duration = null) => {
        setUpdateThreadInfo({ "thread": thread, "duration": duration });
        setActionOnThread(action);
        switch (action) {
            case "Share":
                trackAnalytics("dashboard_history_tab_clicked_share_clicked", { "user_email": email });
                dispatch(setThreadSelectedToShare(thread.id));
                dispatch(generateSharedId({ thread: thread, "duration": duration }))
                break;
            case "Rename":
                trackAnalytics("dashboard_history_tab_clicked_edit_clicked", { "user_email": email });
                dispatch(resetUpdatedTitle())
                setRenameActiveId(thread.id);
                break;
            case "Download":
                trackAnalytics("dashboard_history_tab_clicked_download_file_clicked", { "user_email": email });
                dispatch(generateDownloadDraftUrl({ "thread": thread, "duration": duration }))
                break
            case "Delete":
                trackAnalytics("dashboard_history_tab_clicked_delete_clicked", { "user_email": email });
                break
            default:
                break;
        }
    }

    const modalRef = useRef(null);

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            closeChatHistory();
        }
    };

    const closeChatHistory = () => {
        setFilters([]);
        setShowFilters(false);
        dispatch(setViewChatHistory(false));
    }

    useEffect(() => {
        getFilteredResults(threads, textQuery, filters);
    }, [threads, textQuery, filters]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateFilters = (filter) => {
        var results = [];
        if (filters.indexOf(filter) > -1) {
            results = (filters.filter(f => f !== filter));
        }
        else {
            results = ([...filters, filter]);
        }
        setFilters(results);
        getFilteredResults(threads, textQuery, results);
    }

    const resetFilters = () => {
        setFilters([]);
    }

    const getFilteredResults = (data, textQuery, filters) => {
        const results = {};
        var lengthResults = 0;
        Object.keys(data).forEach((key) => {
            const filtered = data[key].filter(item => {
                const matchesFilter = filters.length === 0 || filters.indexOf(item.type) > -1;
                const matchesQuery = !textQuery || item.title.toLowerCase().includes(textQuery.toLowerCase());
                return matchesFilter && matchesQuery;
            });
            lengthResults += filtered.length
            results[key] = filtered
        });
        if (lengthResults === 0) {
            setResultsExist(false);
        }
        else {
            setResultsExist(true);
        }

        setFilteredResults(results);
    }


    useEffect(() => {
        if (deletedThread && deletedThread.id) {
            if (deletedThread.id === window.location.pathname.split("/")[2]) {
                dispatch(resetDeletedThread())
                setUpdateActionLoading(false);
                navigate("/");
            }
        }
        else if (deletedThread && deletedThread.error) {
            alert("Failed to delete thread");
            setUpdateActionLoading(false);
        }
    }, [deletedThread]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (updatedTitle && updatedTitle.id) {
            setUpdateActionLoading(false);
            dispatch(resetDeletedThread())
        }
        else if (updatedTitle && updatedTitle.error) {
            alert("Failed to update thread title");
            setUpdateActionLoading(false);
        }
    }, [updatedTitle]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setShowChatHistory(viewChatHistory);
        dispatch(resetDownloadUrl())
    }, [viewChatHistory]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (showChatHistory) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showChatHistory]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (downloadDraftUrl) {
            window.open(downloadDraftUrl, "_blank");
        }
    }, [downloadDraftUrl]);

    return (
        <>
            {showChatHistory && (

                <div className="absolute" >
                    <div className={`chat-history flex flex-col h-screen w-[464px] select-none shadow-[4px_0_10px_-1px_rgba(0,0,0,0.02)]  z-50 relative ${sidenavCollapsed ? "left-[64.5px]" : "left-[180px]"} bg-white pr-[16px] pl-[24px] py-[24px] gap-[40px]`} ref={modalRef}
                    >
                        <div className="flex flex-col h-[77px] w-full gap-[10px]">
                            <div className="flex flex-row items-center justify-between bg-white">
                                <h2 className="text-[16px] font-bold leading-[12px]">Your History</h2>
                                <button onClick={() => {
                                    closeChatHistory();
                                }} className="text-[#686868]">
                                    <X size={16} />
                                </button>
                            </div>
                            <div className="flex flex-row items-center justify-between">
                                <div className="input-group-area flex flex-row gap-[12px] h-[45px] px-[12px] border bg-white rounded-[8px] w-[313px]">
                                    <TextArea autoSize maxLength={3000} rows={1} type="text" className="my-auto focus:!border-0 focus:!shadow-none form-control border-0 text-[#B3B3B3]"
                                        placeholder="Search conversations, files"
                                        value={textQuery}
                                        onClick={() => { trackAnalytics("dashboard_history_tab_clicked_search_clicked", { "user_email": email }) }}
                                        onChange={(e) => { setTextQuery(e.target.value); }}
                                    />
                                    <div className='button-box align-baseline flex  gap-[12px] my-auto'>
                                        <MagnifyingGlass size={16} />
                                    </div>
                                </div>
                                <button className="bg-white text-black p-2 rounded w-[87px] border flex justify-center gap-[4px]"
                                    onClick={() => { trackAnalytics("dashboard_history_tab_clicked_filter_apply_clicked", { "user_email": email }); setShowFilters(!showFilters) }}><span className='my-auto'>Filter</span> <Funnel className="my-auto text-black" size={16} /></button>
                                {showFilters && (
                                    <div className={`absolute bg-white border w-[240px] rounded-[12px] shadow z-100  top-[110px] right-[15px]`}>
                                        <ul className="flex flex-col gap-[15px] py-[16px] px-[20px]">
                                            {[["ask", "Advice"], ["draft", "Draft"]].map((filterDetail, index) => (
                                                <li className="flex flex-row gap-[8px] px-[5px] cursor-pointer" key={index}
                                                    onClick={() => { trackAnalytics("dashboard_history_tab_clicked_filter_apply_clicked", { "user_email": email, "filter": filterDetail }); updateFilters(filterDetail[0]) }}>
                                                    <span><input type="checkbox" checked={filters.indexOf(filterDetail[0]) > -1} /></span>
                                                    <span className=" my-auto text-[14px] leading-[21px] cursor-pointer">{filterDetail[1]}</span></li>
                                            ))}
                                        </ul>
                                        {/* <div className='flex justify-between gap-[8px] px-[20px] pt-[0px] pb-[20px]'>
                                            <button className="h-[30px] font-[500] text-[14px] leading-[19.6px] w-[94px] border border-[#D1D1D1] text-black mx-auto my-auto"
                                                onClick={resetFilters}>Reset</button>
                                            <button className="h-[30px] font-[500] text-[14px] leading-[19.6px] w-[94px] text-white bg-black mx-auto my-auto"
                                                onClick={() => { closeChatHistory() }}>Apply</button>
                                        </div> */}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col overflow-y-scroll gap-[32px] pb-[150px]">
                            {resultsExist ? (
                                <>
                                    {
                                        Object.keys(filteredResults).map(duration => (
                                            <>{
                                                filteredResults[duration]?.length > 0 && (
                                                    <div key={duration} className="gap-[32px]">

                                                        <div className="gap-[32px]">
                                                            <p className="uppercase text-[#686868] text-[12px] leading-[12px] font-[500]">{duration.replace(/_/g, " ",)}</p>
                                                            <ul className='mt-[32px] gap-[8px] flex flex-col'>
                                                                {
                                                                    filteredResults[duration]?.map((thread, index) => (
                                                                        <li className="bg-white group" onMouseLeave={() => { setActiveIndex() }}>
                                                                            <div key={index} className={`rounded-[8px] 
                                                                          chat-message w-full flex justify-between p-[12px] gap-[8px]
                                                                        ${thread.type === "ask" && "group-hover:bg-[#C61DCA14]"}
                                                                        ${thread.type === "draft" && "group-hover:bg-[#13866F14]"}
                                                                        ${thread.type === "review" && "group-hover:bg-[#E795711F]"}
                                                                        ${renameActiveId === thread.id && thread.type === "ask" ? "bg-[#C61DCA14]" : ""}
                                                                        ${renameActiveId === thread.id && thread.type === "draft" ? "bg-[#13866F14]" : ""}
                                                                        ${renameActiveId === thread.id && thread.type === "review" ? "bg-[#E795711F]" : ""}
                                                                        `}>
                                                                                <div className={`chat-user flex flex-row gap-[8px] ${renameActiveId === thread.id && "w-full"} `}>
                                                                                    {thread.type === "ask" && <ChatsCircle size={12} className="my-auto group-hover:text-[#C61DCA] text-[#B6B6B6]" />}
                                                                                    {thread.type === "review" && <FileMagnifyingGlass size={12} className="my-auto group-hover:text-[#E79571] text-[#B6B6B6]" />}
                                                                                    {thread.type === "draft" && <PenNib size={12} className="my-auto group-hover:text-[#13866F] text-[#B6B6B6]" />}
                                                                                    <>{renameActiveId === thread.id ?
                                                                                        <UpdateChatTitleModal
                                                                                            open={actionOnThread === 'Rename'}
                                                                                            setActionOnThread={setActionOnThread}
                                                                                            threadInfo={updateThreadInfo}
                                                                                            loading={updateActionLoading}
                                                                                            setLoading={setUpdateActionLoading}
                                                                                            setRenameActiveId={setRenameActiveId}
                                                                                        />

                                                                                        :
                                                                                        <p className="cursor-pointer" onClick={() => { navigate(`/${thread.type}/${thread.id}`); closeChatHistory() }}>{thread.title}</p>
                                                                                    }</>
                                                                                </div>
                                                                                {renameActiveId !== thread.id && (
                                                                                    <div className="">
                                                                                        <div className="rounded-[60px] w-[24px] h-[24px] border mx-auto my-auto transform bg-white hidden shadow group-hover:flex cursor-pointer transition-display duration-300">
                                                                                            <p className="relative mx-auto my-auto text-black"
                                                                                                onClick={() => { dropdownClicked(index) }}><DotsThree size={16} /></p>
                                                                                            {activeIndex === index && (
                                                                                                <div className={`absolute bg-white border ${thread.google_doc_id ? "w-[160px]" : "w-[100px]"} rounded-[12px] shadow z-1000 right-[0px]`}>
                                                                                                    <ul className="flex flex-col gap-[24px] py-[16px] pl-[16px] pr-[24px]">
                                                                                                        {thread.google_doc_id && (
                                                                                                            <>
                                                                                                                {downloadDraftUrlStatus !== "loading" && (
                                                                                                                    <li className="flex flex-row gap-[8px]"
                                                                                                                        onClick={() => { onPerformThreadAction(thread, "Download", duration) }}><span><DownloadSimple size={16} className="my-auto text-[#B6B6B6]" /></span><span className="text-[#353535] my-auto text-[14px] leading-[12px]">Download File</span></li>
                                                                                                                )}
                                                                                                                {downloadDraftUrlStatus === "loading" && (
                                                                                                                    <li className="flex flex-row gap-[8px]"><span className="text-[14px] leading-[12px">Downloading File...</span></li>
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                        <li className="flex flex-row gap-[8px]"
                                                                                                            onClick={() => { onPerformThreadAction(thread, "Rename", duration) }}><span><PencilSimpleLine size={16} className="my-auto text-[#B6B6B6]" /></span><span className="text-[#353535] my-auto text-[14px] leading-[12px]">Rename</span></li>
                                                                                                        <li className="flex flex-row gap-[8px]"
                                                                                                            onClick={() => { onPerformThreadAction(thread, "Share", duration) }}><span><ShareFat size={16} className="my-auto text-[#B6B6B6]" /> </span><span className="text-[#353535] my-auto text-[14px] leading-[12px]">Share</span></li>
                                                                                                        {!lawyerHubData.some(item => item.thread_id === thread.id) && <li className="flex flex-row gap-[8px]"
                                                                                                            onClick={() => { onPerformThreadAction(thread, "Delete", duration) }}><span><Trash className="my-auto text-[#A20009]" /></span><span className="text-[#A20009] my-auto text-[14px] leading-[12px]">Delete</span></li>}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )
                                            }</>
                                        ))
                                    }
                                </>)
                                : <p className="text-center">You don't have any chats</p>
                            }

                        </div >
                    </div>
                </div >
            )}


            <DeleteThreadModal
                open={actionOnThread === 'Delete'}
                setActionOnThread={setActionOnThread}
                threadInfo={updateThreadInfo}
                loading={updateActionLoading}
                setLoading={setUpdateActionLoading}
            />

            <ShareThreadModal
                open={actionOnThread === 'Share'}
                setActionOnThread={setActionOnThread}
                actionOnThread={actionOnThread}
                threadInfo={updateThreadInfo}
                loading={updateActionLoading}
                setLoading={setUpdateActionLoading}
            />

        </>
    )
};

export default ChatHistory;