import { FileText } from '@phosphor-icons/react';

const DocumentGeneratedPrompt = () => {
    return (
        <div>
            <div className={`flex border bg-white p-[16px] rounded-[12px]`}>
                <div className="flex flex-row gap-[8px]">
                    <FileText size={20} className="my-auto" />
                    <p className="my-auto text-[14px] leading-[19.6px] font-[500] text-[#333333]" >Document generated for review</p>
                </div>
            </div>
        </div>
    );
};

export default DocumentGeneratedPrompt;