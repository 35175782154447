import { Check, Sparkle } from '@phosphor-icons/react';
import React, { useEffect } from 'react';
import FollowupInputPrompt from './FollowupInputPrompt';

const QnA = (props) => {
    const {
        index, activeIndex, setActiveIndex, message, submitAnswer, answers, setAnswers, awaitingResponse, questionsAnswered, questionsSkipped, isDocumentGenerated, threadsData, skipped,
        question, answeredIndexes, skipQuestion
    } = props

    const [isDisabled, setIsDisabled] = React.useState(false)
    const [isEditable, setIsEditable] = React.useState(true)
    const [inputValue, setInputValue] = React.useState(answers[index])
    const [isExpanded, setIsExpanded] = React.useState(false)
    const [showActionButtons, setShowActionButtons] = React.useState(false)
    const [showInputPrompt, setShowInputPrompt] = React.useState(false)

    useEffect(() => {
        setIsDisabled(awaitingResponse || (!(questionsAnswered[index] === undefined || questionsAnswered[index] === null)) || (questionsSkipped.indexOf(index) !== -1) || isDocumentGenerated || threadsData.isSharedThread)
        setIsEditable(!(awaitingResponse || (!(questionsAnswered[index] === undefined || questionsAnswered[index] === null)) || (questionsSkipped.indexOf(index) !== -1) || isDocumentGenerated))
        // console.log("activeIndex", activeIndex, "index", index, "questionsAnswered", questionsAnswered, "skipped", skipped, "answeredIndexes", answeredIndexes, "isexpanded", activeIndex === index || Object.keys(questionsAnswered).indexOf(index) !== -1 || skipped.indexOf(index) !== -1)
        setIsExpanded(activeIndex === index || (Object.keys(questionsAnswered).indexOf(index.toString()) !== -1 || Object.keys(questionsAnswered).indexOf(index) !== -1) || skipped.indexOf(index) !== -1)
        setShowActionButtons(!(awaitingResponse || (!(questionsAnswered[index] === undefined || questionsAnswered[index] === null)) || (questionsSkipped.indexOf(index) !== -1) || isDocumentGenerated) && !threadsData?.isSharedThread)
        setShowInputPrompt(
            // question is not skipped
            (skipped.indexOf(index) === -1)

        )
    }, [activeIndex, questionsAnswered, questionsSkipped, isDocumentGenerated, awaitingResponse, threadsData, skipped, answeredIndexes, index])




    return (
        <div>
            <div key={index} className={`flex border w-full bg-white
                                                 ${index === 0 ? "rounded-t-[12px]" : ""} 
                                                 ${index === message.questions?.length - 1 ? "rounded-b-[12px]" : ""}
                                                 ${activeIndex === index ? "!border-l-[#13866F] !border-l-4" : "border-collapse "}`}>
                <div className={`flex flex-col p-[16px] w-full gap-[20px]`}>
                    <div className="flex justify-between">
                        <p className="flex justify-start w-[90%] text-[14px] leading-[19.6px] font-[500] text-[#333333] gap-[8px]" >
                            {/* {question.text} */}
                            <span className='my-auto  flex items-center justify-center bg-[#F2F2F2] w-[24px] h-[24px] rounded-[31.5px] border text-[#7F8C8D]'>
                                {Object.keys(questionsAnswered).indexOf(index) === -1 ? <>{index + 1} </> : <Check size={14} weight="bold" className="text-[#137513]" />}
                            </span>
                            <span className='w-full my-auto text-[16px] font-[500]'>{question?.text || question?.question}</span>
                        </p>
                        <p className="flex justify-center items-center my-auto w-[50px]">
                            <svg
                                className={`w-6 h-8 transition-transform duration-300 transform ${index === activeIndex ? "rotate-180" : ""
                                    }`}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                onClick={() => { if (activeIndex !== index) { setActiveIndex(index) } else { setActiveIndex(null) } }}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </p>
                    </div>
                    <>
                        {/* {(activeIndex >= index || Object.keys(questionsAnswered).indexOf(index) !== -1) && skipped.indexOf(index) === -1 && ( */}
                        {isExpanded && showInputPrompt && (
                            <div className="flex flex-col gap-[20px]">
                                {/* answers need to be taken care of. We will have to pass on something different here as input */}
                                {/* <p>{JSON.stringify(answeredIndexes)}</p> */}
                                <FollowupInputPrompt index={index} answers={answers}
                                    questionsAnswered={questionsAnswered}
                                    activeIndex={activeIndex}
                                    // disabled={awaitingResponse || (!(questionsAnswered[index] === undefined || questionsAnswered[index] === null)) || (questionsSkipped.indexOf(index) !== -1) || isDocumentGenerated || threadsData.isSharedThread}
                                    disabled={isDisabled}
                                    question={question}
                                    submitAnswer={submitAnswer}
                                    setAnswers={setAnswers}
                                    skipQuestion={skipQuestion}
                                    isDocumentGenerated={isDocumentGenerated} />
                                {/* {!(awaitingResponse || (!(questionsAnswered[index] === undefined || questionsAnswered[index] === null)) || (questionsSkipped.indexOf(index) !== -1) || isDocumentGenerated) && !threadsData?.isSharedThread && ( */}
                                {/* {showActionButtons && (
                                    
                                )} */}
                            </div>
                        )}
                        {skipped.indexOf(index) !== -1 && (
                            <div className="flex flex-col gap-[8px] bg-[#FAFAFA] p-[8px] ml-[30px] rounded-[8px]">
                                <p className='flex flex-row text-[#13866F] gap-[8px]'>
                                    <Sparkle size={14.5} className='my-auto text-[#686868]' weight='bold' />
                                    <span className='text-[14px] my-auto leading-[22.5px] font-[600] text-[#686868]'>AI will pick the best option based on your needs</span>
                                </p>
                            </div>
                        )}
                    </>
                </div>
            </div>
        </div>
    );
};

export default QnA;