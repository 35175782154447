import { ArrowSquareOut, Copy, ShieldCheck } from '@phosphor-icons/react';
import React from 'react'

interface LawyerCardProps {
    lawyerImg: string;
    name: string;
    email: string;
    phone: string;
}

const LawyerCard = (props: LawyerCardProps) => {

    const {
        lawyerImg,
        name,
        email,
        phone
    } = props

    return (
        <div className='mt-6 border border-[#E4E4E4] rounded-xl w-full'>
            <section className='flex bg-[#748A8C0A] px-6 py-3'>
                <img alt='lawyer' src={lawyerImg} className='object-cover w-10 h-10 border rounded-full border-[#E4E4E4]' />
                <div className='ml-2'>
                    <p className='text-[#18475A] text-sm font-semibold'>{name}</p>
                    <div className='flex text-xs items-center text-[#949494]'><ShieldCheck className='text-[#949494] mr-1' />Verified InHouse Counsel</div>
                </div>
            </section>
            <section className='flex items-center px-6 py-4'>
                <p className='w-1/2 flex items-center text-[#18475A] text-[10px] font-bold'>Email Id 
                <span className='flex items-center text-[#353535] text-xs font-normal ml-2'>{email} 
                    {/* <ArrowSquareOut className='ml-2 text-[#748A8C]' size={16} /> */}
                    </span></p>
                <p className='w-1/2 flex items-center text-[#18475A] text-[10px] font-bold'>
                Phone Number <span className='flex items-center text-[#353535] text-xs font-normal ml-2'>{phone} 
                    {/* <Copy className='ml-2 text-[#748A8C]' size={16} /> */}
                    </span></p>
            </section>
        </div>
    )
}

export default LawyerCard