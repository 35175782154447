import React from 'react'
import PlanUpgradeIcon from '../../images/lawyer-hub-plan-upgrade.png'
import Button from './Button'
import { ArrowRight } from '@phosphor-icons/react'
import { setShowUpgradePlanNudge } from '../onboarding/onboardingSlice'
import { useDispatch } from 'react-redux'

const UpgradePlanNudge = () => {
    const dispatch = useDispatch()
    const showNudge = () => {
        dispatch(setShowUpgradePlanNudge(true))
    }
    return (
        <div className='flex flex-col items-center justify-center mt-12 pb-[40px]'>
            <img className='h-28' alt='lawyer hub plan upgrade' src={PlanUpgradeIcon} />
            <p className='text-[#353535] font-semibold text-lg mt-10'>You are currently on the Starter Plan</p>
            <p className='text-[#353535] text-base font-medium text-center mt-2'>Make the most out of the lawyer hub by <br />upgrading to our premium plan</p>
            <Button onClick={showNudge} className={'mt-6'} text={<>Upgrade Now <ArrowRight className='ml-2' size={20} />
            </>} />
        </div>
    )
}

export default UpgradePlanNudge