import React, { useEffect } from 'react'
import Modal from '../../components/Modal'
import { Buildings, Check, OfficeChair, X } from '@phosphor-icons/react'
import Button from './Button'
import './lawyerHub.css';
import PlanCards from './components/PlanCards'
import { useDispatch, useSelector } from 'react-redux'
import { setShowCheckoutModal, setShowUpgradePlanNudge } from '../onboarding/onboardingSlice'
import { PLANS, PLAN_TYPES, plans } from '../../components/Constants'
import { getCheckoutUrl, setUserSelectedPlan } from '../chatpage/chatSlice'
import { toast } from 'react-toastify'
import Switch from './components/Switch';
import { Spin } from 'antd';
import { useUser } from '@clerk/clerk-react';


const PlanSelectionPopup = ({ isOpen }) => {
    const dispatch = useDispatch()
    const [planType, setPlanType] = React.useState(PLAN_TYPES.MONTHLY)
    const [plan, setPlan] = React.useState(null);

    const { user } = useUser();

    const showUpgradePlanNudge = useSelector((state) => state.onboarding.showUpgradePlanNudge);
    const threadData = useSelector((state) => state.messageSlice.threadData);
    const checkoutUrl = useSelector((state) => state.chat.checkoutUrl);
    const checkoutUrlStatus = useSelector((state) => state.chat.checkoutUrlStatus);
    const userMetadata = useSelector((state) => state.onboarding.userMetadata);

    const threads_remaining = userMetadata?.threads_remaining;
    const subscription_type = userMetadata?.subscription_type;
    const threads_total = userMetadata?.threads_total;
    const threads_used = threads_total - threads_remaining;


    const closePopup = () => {
        dispatch(setShowUpgradePlanNudge(false))
    }
    useEffect(() => {
        setPlanType(PLAN_TYPES.MONTHLY)
        setPlan(null)
    }, [showUpgradePlanNudge]);


    useEffect(() => {
        if (plan) {
            var data;
            if (plan === PLANS.ADHOC) {
                data = {
                    "payment_type": "ad_hoc_legal_review",
                    "subscription_info": null,
                    "legal_review_thread_id": threadData?.id,
                    "redirect_url": `${process.env.REACT_APP_URL}/`
                }
            }
            else if (plan === PLANS.SUBSCRIBER_BUSINESS || plan === PLANS.SUBSCRIBER_ENTERPRISE) {
                data = {
                    "payment_type": "subscription",
                    "subscription_info": {
                        "subscription_type": plan,
                        "payment_frequency": planType
                    },
                    "legal_review_thread_id": threadData?.id,
                    "redirect_url": `${process.env.REACT_APP_URL}/`
                }
            }
            dispatch(setUserSelectedPlan(plan))
            dispatch(getCheckoutUrl(data))
        }
    }, [plan]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (["idle", "loading", "success", undefined].indexOf(checkoutUrlStatus) === -1) {
            toast.error(checkoutUrlStatus)
        }
    }, [checkoutUrlStatus])

    useEffect(() => {
        if (checkoutUrl) {
            closePopup()
        }
    }, [checkoutUrl]) // eslint-disable-line react-hooks/exhaustive-deps

    let title = '';
    let subTitle = ''
    let availablePlans = [];
    if (subscription_type === 'free_tier') {
        title = threads_remaining > 0 ? `Safeguard ${user?.unsafeMetadata?.organization_name || "your organization"} with a Premium Plan` : 'Upgrade to a premium plan for more'
        subTitle = threads_remaining > 0 ? '' : `You’ve used ${threads_used}/${threads_total} free legal tasks this cycle`
        availablePlans = plans;
    } else if (subscription_type === 'subscriber_business') {
        title = 'Upgrade to the unlimited plan for more'
        subTitle = `You’ve used ${threads_used}/${threads_total} legal tasks this cycle`
        availablePlans = plans.slice(0, 2)
        plans[1].button.disabled = true;

    }


    return (
        <Modal
            contentAreaStyles={{ paddingLeft: '40px', paddingRight: '40px' }}
            isOpen={isOpen}
            bodyStyle={{ paddingBottom: '40px' }}
            onClose={() => { closePopup() }}
        >
            {subTitle && <p className='text-base font-normal text-center text-[#353535]'>{subTitle}</p>}
            <div className="flex flex-row justify-between w-full mb-[40px] ">
                <h5 className='text-[#011513] text-2xl text-center w-full my-auto font-bold'>{title}</h5>
            </div>
            <div className='flex justify-center'>
                <Switch
                    left={{
                        value: PLAN_TYPES.YEARLY,
                        label: 'Yearly',
                        info: 'Save $480'
                    }}
                    right={{
                        value: PLAN_TYPES.MONTHLY,
                        label: 'Monthly',

                    }}
                    value={planType}
                    onToggle={(value) => setPlanType(value)}
                />
            </div>
            {/* <div className='flex items-center justify-center plan-selection-switch'>
                <p className='text-xs text-[#686868] font-medium'>Monthly</p>
                <Switch className='mx-2 scale-90 bg-gray-200'
                    onClick={() => { setPlanType(planType === 'monthly' ? 'yearly' : 'monthly') }}>
                </Switch>
                <p className='text-xs text-[#686868] font-medium'>Annual</p>
                <p
                    style={{
                        background: "linear-gradient(90deg, rgba(220, 162, 24, 0.79) 0%, rgba(184, 135, 20, 0.79) 100%"
                    }}
                    className='px-2 py-[1px] ml-2 text-white text-xs rounded-md font-medium'>20% OFF
                </p>
            </div> */}

            <PlanCards cards={availablePlans} billCycle={planType} PLAN_TYPES={PLAN_TYPES} setPlan={setPlan} wrapperClassName='mt-[20px]' checkoutUrlStatus={checkoutUrlStatus} />
            {checkoutUrlStatus === 'loading' && <Spin fullscreen tip="Loading..."></Spin>}
        </Modal>
    )
}

export default PlanSelectionPopup