import { createSlice } from "@reduxjs/toolkit";


const modalSlice = createSlice({
    name: "modalSlice",
    initialState: {
        draftDocumentModalOpen: false,
        draftModalDraftType: "",
        showExploreMoreModal: false,
    },
    reducers: {
        setDraftDocumentModalOpen: (state, action) => {
            state.draftDocumentModalOpen = action.payload;
        },
        setonlyDraftType: (state, action) => {
            state.draftModalDraftType = action.payload;
        },
        setDraftModalDraftType: (state, action) => {
            state.draftModalDraftType = action.payload;
            state.draftDocumentModalOpen = true;
        },
        setShowExploreMoreModal: (state, action) => {
            state.showExploreMoreModal = action.payload;
        }
    }
});

export default modalSlice.reducer;
export const {
    setDraftDocumentModalOpen,
    setDraftModalDraftType,
    setShowExploreMoreModal,
    setonlyDraftType
} = modalSlice.actions;
