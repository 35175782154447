import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { Modal } from "antd";
import Fuse from 'fuse.js';

import { useDispatch, useSelector } from 'react-redux';
// import { X, FileArrowUp, ClockCounterClockwise, FileText, ArrowLeft } from "@phosphor-icons/react";

import { setDraftDocumentModalOpen, setDraftModalDraftType, setonlyDraftType } from '../../../../modalSlice.js';
import { createNewParty, getSavedParties, resetCreateNewPartyStatus, setSelectedDocumentType, setSelectedParties } from '../../../Homepage/HomeSlice.js';
import ConcernedPartyScreenComponent from './ConcernedPartyScreen.js';
import ChoosePartyScreenComponent from './ChoosePartyScreen.js';
import AddPartyScreenComponent from './AddPartyScreen.js';
import DocumentDetailsScreenComponent from './DocumentDetailsScreen.js';
import DraftDocumentModalHeader from './DraftDocumentModalHeader.js';
import FinalTouchesScreen from './FinalTouchesScreen.js';
import { useWebSocketContext } from "../../../../WebsocketProvider";
import BrowseAll from './BrowseAll.js';
import { setAskToDraftIntentWSResponse, setMessagingDisabled } from '../../messageSlice.js';
import { trackAnalytics } from 'src/pages/helpers.js';
import { documentTypeSuggestions } from 'src/pages/Homepage/utilities.js';


const DraftDocumentQuestionaireModal = (props, ref) => {
    // console.log(props)
    const {
        email,
    } = props;

    const dispatch = useDispatch();
    // const navigate = useNavigate();
    const { isConnected, messages, sendMessage, createMessage } = useWebSocketContext();

    const [isDocumentDetailsScreen, setIsDocumentDetailsScreen] = useState(true);
    const [concernedPartiesScreen, setConcernedPartiesScreen] = useState(false);
    const [documentCreationMode, setDocumentCreationMode] = useState(true);
    const [choosePartyScreenOpen, setchoosePartyScreenOpen] = useState(false);
    const [addPartyScreenOpen, setAddPartyScreenOpen] = useState(false);
    const [browseAllScreen, setBrowseAllScreen] = useState(false);
    const [parties, setParties] = useState([])
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [finishingTouchesScreen, setFinishingTouchesScreen] = useState(false);
    const [selectedDocumentTypeTile, setSelectedDocumentTypeTile] = useState("");
    const [whichPartyIam, setWhichPartyIam] = useState("Primary Party");
    const [fileSelected, setFileSelected] = useState(null);
    const [finalNotes, setFinalNotes] = useState("");
    const [newParty, setNewParty] = useState(
        {
            "is_primary": false,
            "is_entity": false,
            "has_entity": "no",
            "entity_type": null,
            "full_name": "",
            "state_incorporation": "California",
            "primary_business_state": "California",
            "address": "",
            "description": ""
        }
    );
    const [searchParty, setSearchParty] = useState("");
    const [editOldParty, setEditOldParty] = useState(false)
    const [editPartyId, setEditPartyId] = useState(null)

    const draftDocumentModalOpen = useSelector((state) => state.modalSlice.draftDocumentModalOpen)
    const draftTypeSuggestions = useSelector((state) => state.homeslice.draftTypes);
    const partiesList = useSelector((state) => state.homeslice.partiesList);
    const selectedParties = useSelector((state) => state.homeslice.selectedParties);
    const documentType = useSelector((state) => state.modalSlice.draftModalDraftType);
    const selectedDocumentType = useSelector((state) => state.homeslice.selectedDocumentType);
    const createNewPartyStatus = useSelector((state) => state.homeslice.createNewPartyStatus);
    const askToDraftIntentWSResponse = useSelector((state) => state.messageSlice.askToDraftIntentWSResponse);
    const updatingParty = useSelector((state) => state.homeslice.updatingParty);

    useEffect(() => {
        setIsDocumentDetailsScreen(false);
        setConcernedPartiesScreen(false);
        setDocumentCreationMode(true);
        setchoosePartyScreenOpen(false);
        setAddPartyScreenOpen(false);
        setParties([]);
        setBrowseAllScreen(false);
        setFilteredSuggestions([]);
        setIsDropdownVisible(false);
        setFinishingTouchesScreen(false);
        setSelectedDocumentTypeTile("");
        setWhichPartyIam("Primary Party");
        setFileSelected(null);
        setSearchParty("");
        resetParty();
        dispatch(resetCreateNewPartyStatus());
        dispatch(setSelectedParties([]))
        askToDraftMove()

        if (documentType) {
            var doc = documentType;
            if (typeof (documentType) === "string") {
                doc = {
                    "id": null,
                    "name": documentType,
                    "category": null,
                    "draft_type": "contract",
                    "description": "",
                    "min_parties": 2,
                    "max_parties": 2,
                    "party_tags": [
                        "Party 1",
                        "Party 2"
                    ]
                }
                setDocumentType(doc)
                dispatch(setSelectedDocumentType(doc))
            }
            else {
                dispatch(setSelectedDocumentType(documentType))
            }
            setIsDocumentDetailsScreen(false)
            setConcernedPartiesScreen(true);
        }
        else {
            setIsDocumentDetailsScreen(true);
        }
    }, [draftDocumentModalOpen])

    useEffect(() => {
        setParties(partiesList)
    }, [partiesList])

    useEffect(() => {
        filterParties(searchParty)
    }, [searchParty]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (createNewPartyStatus === "success") {
            setAddPartyScreenOpen(false);
            setchoosePartyScreenOpen(true);
            if (updatingParty) {
                dispatch(getSavedParties())
            }
        }
    }, [createNewPartyStatus, updatingParty]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        askToDraftMove()

    }, [askToDraftIntentWSResponse])

    const askToDraftMove = () => {
        if (askToDraftIntentWSResponse?.instruction_summary) {
            setFinalNotes(askToDraftIntentWSResponse.instruction_summary)
            var doc = draftTypeSuggestions?.find(draft => draft.id === askToDraftIntentWSResponse?.document_metadata_id);
            if (!doc) {
                doc = {
                    "id": askToDraftIntentWSResponse?.document_metadata_id,
                    "name": askToDraftIntentWSResponse?.new_document_name,
                    "category": null,
                    "draft_type": "contract",
                    "description": "",
                    "min_parties": 2,
                    "max_parties": 2,
                    "party_tags": [
                        "Party 1",
                        "Party 2"
                    ]
                }
            }
            const updatedDoc = { ...doc, name: askToDraftIntentWSResponse?.new_document_name };
            dispatch(setSelectedDocumentType(updatedDoc))
            setDocumentType(doc)
            setIsDocumentDetailsScreen(false)
            setConcernedPartiesScreen(true);
        }
    }

    const setDocumentType = (userInput) => {
        dispatch(setonlyDraftType(userInput))
    }

    const handleChange = (e) => {
        const userInput = e.target.value;
        setDocumentType({
            "id": null,
            "name": userInput,
            "category": "",
            "draft_type": "custom",
            "description": "",
            "min_parties": 1,
            "max_parties": 2,
            "party_tags": [
                "Party 1",
                "Party 2"
            ]
        },);

        if (userInput === "") {
            setFilteredSuggestions([]);
            setIsDropdownVisible(false);
            return;
        }
        const fuseOptions = {
            // keys: ['category', 'description', 'name'],
            // Removed description after Ryan Reported suggestions are irrelevant in (Ryan's usability testing file)
            keys: ['name'],
            threshold: 0.1  // Adjust the threshold to control the fuzzy matching sensitivity
        };

        const fuse = new Fuse(draftTypeSuggestions, fuseOptions);
        const searchText = userInput
        const results = fuse.search(searchText);
        const filtered = results.map(result => result.item);

        setFilteredSuggestions(filtered);
        setIsDropdownVisible(userInput.length > 0);
        setSelectedDocumentTypeTile("");
        dispatch(setSelectedDocumentType(documentType));
    };

    const handleSuggestionClick = (suggestion) => {
        setDocumentType(suggestion);
        setIsDropdownVisible(false);
        setSelectedDocumentTypeTile("");
    };

    const onDropDownBlur = () => {
        setTimeout(() => {
            setIsDropdownVisible(false);
        }, 300)
    }
    const defaultNewParty = {
        "is_primary": false,
        "is_entity": true,
        "has_entity": "no",
        "entity_type": null,
        "full_name": "",
        "state_incorporation": "California",
        "primary_business_state": "California",
        "address": "",
        "description": ""
    }
    const resetParty = () => {
        setNewParty(defaultNewParty);
    }

    const documentSuggestionClicked = (data) => {
        trackAnalytics("Document Type Suggestions Clicked", {
            "user_email": email,
            "title": data.suggestion.display_prompt
        })
        // window.analytics.track("Document Type Suggestions Clicked", {
        //     "user_email": email,
        //     "title": data.suggestion.display_prompt
        // })
        setDocumentType(data.suggestion);
        setSelectedDocumentTypeTile(data);
        dispatch(setSelectedDocumentType(data.suggestion));
    }

    const continueToConcernedParties = () => {
        setIsDocumentDetailsScreen(false);
        setConcernedPartiesScreen(true);
        dispatch(setSelectedDocumentType(documentType));
        trackAnalytics("dashboard_home_draft_new_popup_step1continue_clicked", { user_email: email })
        trackAnalytics("Document Type Selected", {
            user_email: email, "next": "Concerned parties screen"
        })
        // window.analytics.track("Document Type Selected", {
        //     user_email: email, "next": "Concerned parties screen"
        // })
    }

    const addNewPartyButtonClicked = () => {
        trackAnalytics("Add Details button --> New party Screen", {
            user_email: email
        })
        // window.analytics.track("Add Details button --> New party Screen", {
        //     user_email: email
        // });
        setConcernedPartiesScreen(false);
        setDocumentCreationMode(false);
        setIsDocumentDetailsScreen(false);
        setSearchParty("");
        setchoosePartyScreenOpen(true);
    }

    const backtoScreen1 = () => {
        trackAnalytics("dashboard_home_draft_new_popup_goback_clicked", { user_email: email })
        trackAnalytics("Back to Document Type Selection screen", { user_email: email })
        // window.analytics.track("Concerned Parties Screen -> Document Type Selection screen", { user_email: email })
        setConcernedPartiesScreen(false);
        setDocumentCreationMode(true);
        setIsDocumentDetailsScreen(true);
    }

    const backToConcernedPartyScreen = () => {
        trackAnalytics("Back to Concerned Parties screen", { user_email: email })
        // window.analytics.track("Select Party Screen -> Concerned Parties Screen", { user_email: email })
        setchoosePartyScreenOpen(false);
        setDocumentCreationMode(true);
        setConcernedPartiesScreen(true);
    }

    const addNewPartyTileClicked = () => {
        trackAnalytics("dashboard_home_draft_new_popup_addparty_clicked", { user_email: email })
        trackAnalytics("Add New Party Tile Clicked", { user_email: email })
        // window.analytics.track("Add New Party Tile Clicked", { user_email: email })
        setchoosePartyScreenOpen(false);
        setSearchParty("");
        resetParty();
        setAddPartyScreenOpen(true);
    }

    const backToSelectPartyScreen = () => {
        setAddPartyScreenOpen(false);
        setchoosePartyScreenOpen(true);
        trackAnalytics("moving back to choose party screen", { user_email: email })
        // window.analytics.track("moving back to choose party screen", { user_email: email })
    }

    const addPartyButtonClicked = () => {
        trackAnalytics("dashboard_home_draft_new_popup_addparty_confirmselection_clicked", { user_email: email })
        addParty();
        trackAnalytics("Add Party Button Clicked", { user_email: email, "party": newParty })
        // window.analytics.track("Add Party Button Clicked", { user_email: email, "party": newParty })
    }


    const createDraftContinueButtonClicked = () => {
        // if (selectedParties.length >= documentType.min_parties && selectedParties.length <= documentType.max_parties) {
        trackAnalytics("dashboard_home_draft_new_popup_step2continue_clicked", { user_email: email })
        trackAnalytics("concerned parties finalised", { user_email: email, "next": "/draft/id page" })
        // window.analytics.track("concerned parties finalised", { user_email: email, "next": "/draft/id page" })
        setConcernedPartiesScreen(false);
        setDocumentCreationMode(false);
        setFinishingTouchesScreen(true);
        // }
    }

    const closeModal = () => {
        trackAnalytics("Draft Document Modal Closed", { user_email: email })
        // window.analytics.track("Draft Document Modal Closed", { user_email: email })
        dispatch(setDraftDocumentModalOpen(false))
        dispatch(setAskToDraftIntentWSResponse({}))
    }

    const backToSelectPartyScreen2 = () => {
        setFinishingTouchesScreen(false);
        setDocumentCreationMode(true);
        setConcernedPartiesScreen(true);
        trackAnalytics("moving back to choose party screen", { user_email: email })
        // window.analytics.track("moving back to choose party screen", { user_email: email })
    }

    const browseAllClicked = () => {
        trackAnalytics("dashboard_home_draft_new_popup_browseall_clicked", { user_email: email })
        setBrowseAllScreen(true);
        setDocumentCreationMode(false);
        setIsDocumentDetailsScreen(false);
    }

    const closeBrowseAll = () => {
        setBrowseAllScreen(false);
        setDocumentCreationMode(true);
        setIsDocumentDetailsScreen(true);
    }

    const draftTypeSelectedFromBrowseAll = (data) => {
        setBrowseAllScreen(false);
        setDocumentCreationMode(true);
        setIsDocumentDetailsScreen(false);
        setConcernedPartiesScreen(true);

        setSelectedDocumentTypeTile(data);
        setDocumentType(data.suggestion);
        setSelectedDocumentTypeTile(data);
        dispatch(setSelectedDocumentType(data.suggestion));
    }

    const filterParties = (searchParty) => {
        setParties(partiesList.filter(party => party.name?.toLowerCase().includes(searchParty?.toLowerCase())));
    }

    const updatePartyType = (yes) => {
        if (yes === "false") {
            setNewParty({ ...newParty, "is_entity": false, "entity_type": "Individual", "has_entity": "no" })
        }
        else {
            setNewParty({ ...newParty, "is_entity": true, "has_entity": "yes" })
        }
    }

    const updateParty = (key, value) => {
        setNewParty({ ...newParty, [key]: value })
    }

    const addParty = () => {
        var entityInfo = null
        var individualInfo = null
        var primaryBusinessState = null;
        debugger;
        if (newParty.primary_business_state !== "") {
            primaryBusinessState = newParty.primary_business_state
        }
        if (newParty.is_entity) {
            entityInfo = {
                "type": newParty.entity_type,
                "state_incorporation": newParty.state_incorporation,
                "primary_business_state": primaryBusinessState,
                "address": newParty.address,
                "business_purpose": newParty.description,
            }
        }
        else {
            individualInfo = {
                "state_residence": newParty.state_incorporation,
                "description": newParty.description,
                "address": newParty.address
            }
        }

        const data = {
            "is_primary": false,
            "full_name": newParty.full_name,
            "entity_info": entityInfo,
            "individual_info": individualInfo
        }

        dispatch(createNewParty(data))
        trackAnalytics("New Party added", { user_email: email, "party": data })
        // window.analytics.track("New Party added", { user_email: email, "party": data })
    }
    const editParty = () => {
        var entityInfo = null
        var individualInfo = null
        var primaryBusinessState = null;
        if (newParty.is_entity) {
            debugger;
            if (newParty.primary_business_state !== "") {
                primaryBusinessState = newParty.primary_business_state
            }
            entityInfo = {
                "type": newParty.entity_type,
                "state_incorporation": newParty.state_incorporation,
                "primary_business_state": primaryBusinessState,
                "address": newParty.address,
                "business_purpose": newParty.description,
            }
        }
        else {
            individualInfo = {
                "state_residence": newParty.state_incorporation,
                "description": newParty.description,
                "address": newParty.address
            }
        }
        const data = {
            "id": editPartyId,
            "is_primary": false,
            "full_name": newParty.full_name,
            "entity_info": entityInfo,
            "individual_info": individualInfo
        }
        // console.log("EditPartyId : " + data.id)
        // console.log(data)
        dispatch(createNewParty(data)).then((res) => {
            if (res.payload.data.isEdit) {
                setEditPartyId(null)
                setNewParty(defaultNewParty)
            }
        })
    }


    const confirmPartySelectionClicked = () => {
        trackAnalytics("Confirm Party Selections", { user_email: email })
        // window.analytics.track("Confirm Party Selections", { user_email: email })
        setchoosePartyScreenOpen(false);
        setDocumentCreationMode(true)
        setConcernedPartiesScreen(true)
    }

    const createDraft = () => {
        trackAnalytics("dashboard_home_draft_new_popup_step3continue_clicked", { user_email: email })
        trackAnalytics("Create Draft Button Clicked", { user_email: email })
        // window.analytics.track("Create Draft Button Clicked", { user_email: email })
        var primaryParty = selectedParties.filter(party => party.is_primary === true);
        var otherParties = selectedParties.filter(party => party.is_primary !== true)
        var parties = {};
        var nonUserPartyTag = selectedDocumentType.party_tags.filter(tag => tag !== whichPartyIam)[0];
        otherParties.forEach((party, index) => {
            parties[party.id] = nonUserPartyTag;
        })
        parties[primaryParty[0].id] = whichPartyIam;
        // var otherParties = selectedParties.filter(party => party.is_primary !== true).map(party => party.id);
        var draftId = null;
        var documentName = null;
        // debugger;
        if (selectedDocumentType.id) {
            draftId = selectedDocumentType.id;
        }
        else {
            documentName = `${selectedDocumentType.name}` // TODO
        }
        if (finalNotes && documentName) {
            documentName = `${documentName}
${finalNotes}`
        }


        var message = createMessage("draft", "create-thread", {
            "draft_type_id": draftId,
            "new_document_name": documentName,
            "description": finalNotes || "",
            "reference_doc_id": null,
            "parties_involved": parties,
            "primary_party_id": primaryParty[0].id,
            "reference_thread_id": null
        })
        sendMessage(message, true)
        dispatch(setMessagingDisabled(true));
    }

    useImperativeHandle(ref, () => ({
        isDocumentDetailsScreen,
        concernedPartiesScreen,
        documentType,
        documentCreationMode,
        choosePartyScreenOpen,
        addPartyScreenOpen,
        addParty,
        parties,
        newParty,
        searchParty,
        draftDocumentModalOpen,
        partiesList,
        selectedParties,
        confirmPartySelectionClicked
    }));

    return (
        <Modal
            open={draftDocumentModalOpen}
            className="draft-document-popup rounded overflow-hidden w-[860px]"
            cancelButtonProps={{
                style: { display: 'none' }
            }}
        >
            <div className="w-[860px] h-full flex flex-col  gap-[24px]">
                {(documentCreationMode || finishingTouchesScreen) && (<>
                    <DraftDocumentModalHeader
                        isDocumentDetailsScreen={isDocumentDetailsScreen}
                        concernedPartiesScreen={concernedPartiesScreen}
                        finishingTouchesScreen={finishingTouchesScreen}
                        closeModal={closeModal}
                    />
                    {/* <p>choosePartyScreenOpen ...+ {JSON.stringify(choosePartyScreenOpen)}</p> */}

                    {isDocumentDetailsScreen && (
                        <DocumentDetailsScreenComponent
                            documentType={documentType}
                            setDocumentType={setDocumentType}
                            handleChange={handleChange}
                            concernedPartiesScreen={concernedPartiesScreen}
                            isDropdownVisible={isDropdownVisible}
                            filteredSuggestions={filteredSuggestions}
                            handleSuggestionClick={handleSuggestionClick}
                            draftTypeSuggestions={draftTypeSuggestions}
                            selectedDocumentTypeTile={selectedDocumentTypeTile}
                            setSelectedDocumentTypeTile={setSelectedDocumentTypeTile}
                            continueToConcernedParties={continueToConcernedParties}
                            documentSuggestionClicked={documentSuggestionClicked}
                            browseAllClicked={browseAllClicked}
                            onDropDownBlur={onDropDownBlur}
                        />
                    )}


                    {concernedPartiesScreen && (
                        <ConcernedPartyScreenComponent
                            backtoScreen1={backtoScreen1}
                            createDraftContinueButtonClicked={createDraftContinueButtonClicked}
                            addNewPartyButtonClicked={addNewPartyButtonClicked}
                            whichPartyIam={whichPartyIam}
                            closeModal={closeModal}
                            setWhichPartyIam={setWhichPartyIam}
                        />
                    )}
                    {finishingTouchesScreen && (<>
                        <FinalTouchesScreen setFinishingTouchesScreen={setFinishingTouchesScreen}
                            backToSelectPartyScreen2={backToSelectPartyScreen2}
                            fileSelected={fileSelected}
                            setFileSelected={setFileSelected}
                            createDraft={createDraft}
                            finalNotes={finalNotes}
                            setFinalNotes={setFinalNotes}
                        />
                    </>)}
                </>)}




                {choosePartyScreenOpen && (
                    <ChoosePartyScreenComponent
                        backToConcernedPartyScreen={backToConcernedPartyScreen}
                        addNewPartyTileClicked={addNewPartyTileClicked}
                        confirmPartySelectionClicked={confirmPartySelectionClicked}
                        parties={parties}
                        searchParty={searchParty}
                        setSearchParty={setSearchParty}
                        closeModal={closeModal}
                        filterParties={filterParties}
                        setEditPartyId={setEditPartyId}
                        newParty={newParty}
                        setNewParty={setNewParty}
                    />
                )}
                {browseAllScreen && (
                    <BrowseAll documentType={documentType}
                        setDocumentType={setDocumentType}
                        handleChange={handleChange}
                        draftTypeSuggestions={draftTypeSuggestions}
                        setBrowseAllScreen={setBrowseAllScreen}
                        closeBrowseAll={closeBrowseAll}
                        draftTypeSelectedFromBrowseAll={draftTypeSelectedFromBrowseAll} />
                )}
                {addPartyScreenOpen && (
                    <AddPartyScreenComponent
                        backToSelectPartyScreen={backToSelectPartyScreen}
                        addPartyButtonClicked={addPartyButtonClicked}
                        newParty={newParty}
                        closeModal={closeModal}
                        updateParty={updateParty}
                        updatePartyType={updatePartyType}
                        editPartyId={editPartyId}
                        editParty={editParty}
                    />
                )}
            </div>
        </Modal >
    )
    // return (
    //     <div className="w-[1200px] h-[600px] overflow-scroll">
    //         <iframe src={iframeUrl} width="100%" height="800" ref={iframeRef} />
    //     </div>
    // )
}

export default forwardRef(DraftDocumentQuestionaireModal);