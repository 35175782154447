import { Bicycle, Buildings, OfficeChair } from "@phosphor-icons/react";

export const stateOptions = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const companyExistance = [
  { label: "Not Started", value: "not-started" },
  { label: "0 - 2 years", value: "0-2" },
  { label: "2 - 10 years", value: "2-10" },
  { label: "10+ years", value: "10+ years" },
];

export const numberOfEmployees = [
  { label: "0", value: "0" },
  { label: "1-10", value: "1-10" },
  { label: "11-50", value: "11-50" },
  { label: "50+", value: "50+" },
  // { label: "201-500", value: "201-500" },
  // { label: "501-1000", value: "501-1000" },
  // { label: "1000+", value: "1000+" },
];

export const legalWorkOption = [
  { label: "Self-help", value: "Self-help" },
  { label: "Online Legal Services", value: "Online Legal Services" },
  { label: "Outside Counsel", value: "Outside Counsel" },
  { label: "Inside Counel", value: "Inside Counel" },
  { label: "Other", value: "Other" },
];

export const legalWorkTypePeriod = [
  { label: "Weekly", value: "Weekly" },
  { label: "Monthly", value: "Monthly" },
  { label: "Quarterly", value: "Quarterly" },
  { label: "Annually", value: "Annually" },
  { label: "Other", value: "Other" },
];

export const legalWorkTypes = [
  { label: "Employment/HR", value: "employment/HR" },
  { label: "Drafting Contracts", value: "drafting contracts" },
  { label: "Reviewing Contracts", value: "reviewing contracts" },
  { label: "Litigation", value: "litigation" },
  // { label: "Intellectual Property", value: "Intellectual Property" },
  // { label: "Licensing/Regulations", value: "Licensing/Regulations" },
  { label: "Other", value: "Other" },
];

export const annualLegalEXpenses = [
  { label: "Self Help", value: "Self Help" },
  { label: "under $5K", value: "under $5K" },
  { label: "$5K - $25K", value: "$5K - $25K" },
  { label: "over $25K", value: "over $25K" },
  // { label: "$500k-$2M", value: "$500k-$2M" },
  // { label: "Over $2M", value: "Over $2M" },
];

export const industryArray = [
  { label: "Technology", value: "Technology" },
  { label: "Retail", value: "Retail" },
  { label: "Agency", value: "Agency" },
  { label: "Consulting", value: "Consulting" },
  { label: "E-Commerce", value: "E-Commerce" },
  { label: "Real Estate", value: "Real Estate" },
  { label: "Manufacturing ", value: "Manufacturing " },
  { label: "Professional Services", value: "Professional Services" },
  { label: "Other", value: "Other" },
];

// export const entityOptions = [
//   { value: "c_corporation", label: "C-corporation" },
//   { value: "llc", label: "LLC" },
//   { value: "s_corporation", label: "S-corporation" },
//   { value: "partnership", label: "Partnership" },
//   { value: "sole_proprietorship", label: "Sole Proprietorship" },
//   { value: "non_profit", label: "Non Profit" },
//   { value: "other", label: "Other" },
// ];
// export const entityOptions = {
//   corporation: "Corporation",
//   llc: "LLC",
//   partnership: "Partnership",
//   sole_proprietorship: "Sole Proprietorship",
//   non_profit: "Non Profit",
//   other: "Other",
// }

export const PLANS = {
  ADHOC: "ad_hoc_legal_review",
  FREE_TIER: 'free_tier',
  SUBSCRIBER_BUSINESS: 'subscriber_business',
  SUBSCRIBER_ENTERPRISE: 'subscriber_enterprise'
}

export const PLAN_TYPES = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly'
}


export const entityOptions = {
  "Corporation": "Corporation",
  "LLC": "LLC",
  "Partnership": "Partnership",
  "Sole Proprietorship": "Sole Proprietorship",
  "Non Profit": "Non Profit",
  "Other": "Other",
}

export const stateOptionsNew = {
  "Alabama": "Alabama",
  "Alaska": "Alaska",
  "Arizona": "Arizona",
  "Arkansas": "Arkansas",
  "California": "California",
  "Colorado": "Colorado",
  "Connecticut": "Connecticut",
  "Delaware": "Delaware",
  "Florida": "Florida",
  "Georgia": "Georgia",
  "Hawaii": "Hawaii",
  "Idaho": "Idaho",
  "Illinois": "Illinois",
  "Indiana": "Indiana",
  "Iowa": "Iowa",
  "Kansas": "Kansas",
  "Kentucky": "Kentucky",
  "Louisiana": "Louisiana",
  "Maine": "Maine",
  "Maryland": "Maryland",
  "Massachusetts": "Massachusetts",
  "Michigan": "Michigan",
  "Minnesota": "Minnesota",
  "Mississippi": "Mississippi",
  "Missouri": "Missouri",
  "Montana": "Montana",
  "Nebraska": "Nebraska",
  "Nevada": "Nevada",
  "New Hampshire": "New Hampshire",
  "New Jersey": "New Jersey",
  "New Mexico": "New Mexico",
  "New York": "New York",
  "North Carolina": "North Carolina",
  "North Dakota": "North Dakota",
  "Ohio": "Ohio",
  "Oklahoma": "Oklahoma",
  "Oregon": "Oregon",
  "Pennsylvania": "Pennsylvania",
  "Rhode Island": "Rhode Island",
  "South Carolina": "South Carolina",
  "South Dakota": "South Dakota",
  "Tennessee": "Tennessee",
  "Texas": "Texas",
  "Utah": "Utah",
  "Vermont": "Vermont",
  "Virginia": "Virginia",
  "Washington": "Washington",
  "West Virginia": "West Virginia",
  "Wisconsin": "Wisconsin",
  "Wyoming": "Wyoming",
}

export const plans = [
  {
    isActive: true,
    title: 'Unlimited Plan',
    titleIcon: <Buildings className='ml-2 text-[#DCA218]' size={14} />,
    billCycle: 'MONTHLY',
    planName: PLANS.SUBSCRIBER_ENTERPRISE,
    price: '189',
    priceYearly: '149',
    button: {
      text: 'Upgrade to Unlimited Plan',
      className: '!font-bold',
      // onClick={() => {setPlan(PLANS.SUBSCRIBER_BUSINESS)}}
    },
    badge: {
      text: 'MOST Popuplar',
      className: '',
      show: true,
    },
    features: [
      { text: 'Unlimited legal tasks ✨', highlight: true },
      { text: 'Dedicated Lawyer' },
      { text: 'Unlimited Legal Reviews ✨', highlight: true }
    ]
  },
  {
    isActive: false,
    title: 'Essential Plan',
    titleIcon: < OfficeChair className='ml-2' size={14} />,
    billCycle: 'MONTHLY',
    price: '59',
    priceYearly: '49',
    planName: PLANS.SUBSCRIBER_BUSINESS,
    button: {
      secondary: true,
      text: 'Upgrade to Essential Plan'
    },
    features: [
      { text: '10 legal tasks/month' },
      { text: 'Dedicated Lawyer' },
      { text: '1 lawyer review/month' },
      { text: 'Additional Review costs  $49 / each' },
    ]
  },

  {
    isActive: false,
    title: 'Starter Plan',
    titleIcon: <Bicycle className='ml-2' size={14} />,
    planName: PLANS.SUBSCRIBER_BUSINESS,
    button: {
      text: 'Your Current Plan',
      disabled: true,

    },
    features: [
      { text: '2 legal tasks' },
      { text: 'Pay $49 per Lawyer Verification' },
    ]
  },
]


export const ReviewStatus = Object.freeze({
  rejected_by_lawyer: "rejected_by_lawyer",
  submitted_legal_review: "submitted_legal_review",
  accepted_by_lawyer: "accepted_by_lawyer",
  accepted_by_inhouse: "accepted_by_inhouse",  // credits available or payment done, has been sent to the lawyer. for pro plans if the lawyer matching is 
  pending_lawyer_matching: "pending_lawyer_matching", // 
  pending_payment: "pending_payment",
  not_requested: "not_requested",
});