import React from 'react';
import { setShowUpgradePlanNudge } from '../onboarding/onboardingSlice';
import { useDispatch } from 'react-redux';
import { useAuth, useUser } from '@clerk/clerk-react';
import OnboardingV2 from '.';

const OnboardingBase = () => {
    const dispatch = useDispatch();
    const { user } = useUser();
    const { getToken } = useAuth();

    const moveNext = (next) => {
        switch (next) {
            case "ask":
                window.location.href = `/ask`;
                break;
            case "draft":
                window.location.href = `/?open_draft=legal review request`;
                break;
            case "review":
                window.location.href = `/`;
                break;
            case "membership":
                dispatch(setShowUpgradePlanNudge(true));
                window.location.href = "/?open_membership=true";
                break;
            default:
                window.location.href = `/`;
        }
    };
    return (
        <OnboardingV2 user={user} getToken={getToken} sidenav={false} moveNext={moveNext} />
    );
};

export default OnboardingBase;