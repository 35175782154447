import React from 'react';
import UpdatePlaceholderComponent from './UpdatePlaceholderComponent';

const PlaceholderComponent = ({ placeholders, focusPlaceholder, updatePlaceholder, activePlaceholder }) => {

    return (
        <div className="w-[506px] mx-auto">
            <ul className="gap-[8px] flex flex-col w-[506px]">
                {placeholders.map((placeholder, index) => (

                    <li key={index} className='pt-[30px] bg-white flex border border-[#D1D1D1] p-[24px] rounded-[8px]' >
                        <UpdatePlaceholderComponent
                            placeholder={placeholder}
                            index={index}
                            updatePlaceholder={updatePlaceholder}
                            focusPlaceholder={focusPlaceholder}
                            isActivePlaceholder={activePlaceholder === index} />
                    </li>

                ))}
            </ul>
        </div>);
};

export default PlaceholderComponent;