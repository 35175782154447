import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./Signin.css";
import AuthenticationBase from ".";
import InHouseLogo from '../../images/inhouse-logo-v2.png'

import { SignIn, SignUp, useUser } from "@clerk/clerk-react";
import { clearTokens, trackAnalytics } from "../helpers";

const Signin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const next_path = queryParams.get('next');

  const { isLoaded } = useUser();

  const [redirectUrl, setRedirectUrl] = useState(null);
  const [signUpRedirectUrl, setSignUpRedirectUrl] = useState(null);
  const [signUpUrl, setSignUpUrl] = useState(null);
  const [loadingTime, setLoadingTime] = useState();

  useEffect(() => {
    window.analytics.page("Signin page viewed");

    if (next_path === "ask") {
      setRedirectUrl(`/post-auth?next=ask`);
      setSignUpRedirectUrl(`/post-auth?next=ask`);
      setSignUpUrl(`/signup?next=ask`);
    }
    else if (next_path === "draft") {
      setRedirectUrl(`/post-auth?next=draft`);
      setSignUpRedirectUrl(`/post-auth?next=draft`);
      setSignUpUrl(`/signup?next=draft`);
    }
    else if (next_path === "review") {
      setRedirectUrl(`/post-auth?next=draft`);
      setSignUpRedirectUrl(`/post-auth?next=draft`);
      setSignUpUrl(`/signup?next=review`);
    }
    else if (next_path === "membership") {
      setRedirectUrl(`/post-auth?next=membership`);
      setSignUpRedirectUrl(`/post-auth?next=membership`);
      setSignUpUrl(`/signup?next=membership`);
    }
    else {
      setRedirectUrl(`/post-auth`);
      setSignUpRedirectUrl(`/post-auth`);
      setSignUpUrl(`/signup`);
    }
  }, [next_path]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    trackAnalytics("Signin page viewed");
    setLoadingTime(new Date());
    clearTokens();
  }, []);

  useEffect(() => {
    if (isLoaded) {
      var time = new Date() - loadingTime;
      trackAnalytics("clerk event laoded on signin page", { "time_taken": time });
      console.log("clerk event laoded on signin page", time);
    }
  }, [isLoaded]);

  return (
    <AuthenticationBase>
      <div className="flex flex-col justify-between h-full md:px-10 md:py-10 px-auto py-auto">
        {isLoaded ?
          <>
            <div>
              <img src={InHouseLogo} className="w-[120px] h-[42px] object-contain" />
              <div className="px-auto mx-auto align-items-center items-center ">
                <SignIn
                  routing="virtual"
                  fallbackRedirectUrl={redirectUrl}
                  signUpFallbackRedirectUrl={signUpRedirectUrl}
                  signUpUrl={signUpUrl}
                />
              </div>
            </div>
            <p className="text-[#666D80] text-sm font-normal text-center">© 2024 Inhouse. All Rights Reserved</p>
          </>
          :
          <div className="flex flex-col justify-between h-full px-10 py-10">
            <div className="w-8 h-8 border-2 border-t-0 border-[#000] rounded-full animate-spin mx-auto my-auto"></div>
          </div>}
      </div>
    </AuthenticationBase>

  );
};

export default Signin;
