import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./Signin.css";
import AuthenticationBase from ".";
import InHouseLogo from '../../images/inhouse-logo-v2.png'


import { SignIn, SignUp, useUser } from "@clerk/clerk-react";
import { clearTokens, trackAnalytics } from "../helpers";

const Signup = () => {
  const { isLoaded } = useUser();

  const queryParams = new URLSearchParams(window.location.search);
  const next_path = queryParams.get('next');


  const [redirectUrl, setRedirectUrl] = useState(null);
  const [signinRedirectUrl, setSigninRedirectUrl] = useState(null);
  const [signinUrl, setSigninUrl] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [loadingTime, setLoadingTime] = useState();

  useEffect(() => {
    window.analytics.page("Signin page viewed");

    if (next_path === "ask") {
      setRedirectUrl(`/post-auth?next=ask`);
      setSigninRedirectUrl(`/post-auth?next=ask`);
      setSigninUrl(`/signin?next=ask`);
    }
    else if (next_path === "draft") {
      setRedirectUrl(`/post-auth?next=draft`);
      setSigninRedirectUrl(`/post-auth?next=draft`);
      setSigninUrl(`/signin?next=draft`);
    }
    else if (next_path === "review") {
      setRedirectUrl(`/post-auth?next=draft`);
      setSigninRedirectUrl(`/post-auth?next=draft`);
      setSigninUrl(`/signin?next=review`);
    }
    else if (next_path === "membership") {
      setRedirectUrl(`/post-auth?next=membership`);
      setSigninRedirectUrl(`/post-auth?next=membership`);
      setSigninUrl(`/signin?next=membership`);
    }
    else {
      setRedirectUrl(`/post-auth`);
      setSigninRedirectUrl(`/post-auth`);
      setSigninUrl(`/signin`);
    }
  }, [next_path]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isLoaded) {
      var time = new Date() - loadingTime;
      trackAnalytics("clerk event laoded on signin page", { "time_taken": time });
      console.log("clerk event laoded on signin page", time);
      setShowMessage(true);
    }
  }, [isLoaded]);


  useEffect(() => {
    clearTokens();
    setLoadingTime(new Date());
  }, []);


  return (
    <AuthenticationBase>
      <div className="flex flex-col justify-between md:h-screen md:overflow-y-scroll md:px-10 md:py-6 px-auto py-auto">
        {isLoaded ?
          <>
            <div>
              <img src={InHouseLogo} className="w-[120px] h-[42px] object-contain" />
              <div className="px-auto mx-auto align-items-center items-center">
                <SignUp routing="virtual"
                  fallbackRedirectUrl={redirectUrl}
                  signInFallbackRedirectUrl={signinRedirectUrl}
                  signInUrl={signinUrl}
                />
                {showMessage && (
                  <p className="text-center text-xs font-normal relative -top-12 text-[#666D80] p-2">
                    By signing up, you agree to our{' '}
                    <a href="https://storage.googleapis.com/inhouse-public-documents/InHouse%20Terms%20of%20Service.pdf" rel="noopener noreferrer" className="underline" target="blank">
                      Terms and Conditions
                    </a> and{' '}
                    <a href="https://storage.googleapis.com/inhouse-public-documents/InHouse%20Privacy%20Policy.pdf" rel="noopener noreferrer" className="underline" target="blank">
                      Privacy Policy
                    </a>.
                  </p>)}
              </div>
            </div>
            <p className="text-[#666D80] text-sm font-normal text-center">© 2024 Inhouse. All Rights Reserved</p>
          </>
          :
          <div className="flex flex-col justify-between h-full px-10 py-10">
            <div className="w-8 h-8 border-2 border-t-0 border-[#000] rounded-full animate-spin mx-auto my-auto"></div>
          </div>}
      </div>
    </AuthenticationBase>

  );
};

export default Signup;
