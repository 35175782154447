import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { ChatsTeardrop, Copy, Link, Sparkle, Spinner, X } from "@phosphor-icons/react";
import { useDispatch, useSelector } from 'react-redux';
import toast from "react-hot-toast";
import { generateSharedId } from '../../../../pages/chatpage/threadsSlice';
import TextArea from 'antd/es/input/TextArea';
import { setShowAskToDraftModal } from '../../../../pages/chatpage/messageSlice';
import DraftingBoard from "../../../../images/DraftingBoard.png"
import { setDraftDocumentModalOpen, setDraftModalDraftType } from '../../../../modalSlice';
import { useWebSocketContext } from 'src/WebsocketProvider';


const AskToDraftModal = () => {

    const dispatch = useDispatch();
    const showAskToDraftModal = useSelector((state) => state.messageSlice.showAskToDraftModal);
    const askToDraftIntent = useSelector((state) => state.messageSlice.askToDraftIntent);
    const draftTypeSuggestions = useSelector((state) => state.homeslice.draftTypeSuggestions);
    const askToDraftIntentWSResponse = useSelector((state) => state.messageSlice.askToDraftIntentWSResponse);
    const { sendMessage, createMessage } = useWebSocketContext();


    useEffect(() => {
        if (showAskToDraftModal && askToDraftIntent) {
            var message = createMessage("draft", "infer-draft-metadata-from-ask", askToDraftIntent);
            sendMessage(message);
        }
    }, [showAskToDraftModal]) // eslint-disable-line


    useEffect(() => {
        if (askToDraftIntentWSResponse?.instruction_summary) {
            var draft = draftTypeSuggestions?.find(draft => draft.id === askToDraftIntentWSResponse?.document_metadata_id);
            dispatch(setDraftModalDraftType(draft))
            dispatch(setShowAskToDraftModal({ "show": false }))
        }
    }, [askToDraftIntentWSResponse]) // eslint-disable-line

    return (
        <Modal
            centered
            open={showAskToDraftModal}
            className="ask-to-draft-modal rounded-[16px] bg-[#fff] overflow-hidden "
            footer={null}
        >
            <div className="flex flex-col p-[24px] w-full h-[683px] rounded-[8px] border-[1px] ">
                <div className='flex justify-end'>
                    <X className="w-[24px] h-[24px] cursor-pointer" onClick={() => dispatch(setShowAskToDraftModal({ "show": false }))} />
                </div>
                <div className="flex flex-col mt-[55px] items-center justify-center w-full">
                    <img src={DraftingBoard} alt="Drafting Board" />
                </div>
                <div className="flex flex-col mt-[55px] items-center justify-center w-full gap-[24px]">
                    <p className="w-[518.96px] text-[32px] leading-[44.8px] font-[600] text-center">Taking you to a new drafting space to keep things organised</p>

                    <p className="flex flex-row gap-[8px]">
                        <Sparkle className="text-[#13866F] my-auto" size={16.5} />
                        <span className="my-auto text-[18px] leading-[25.2px] text-[#686868] font-[500]">This is a specialised space for all your drafting needs</span>
                    </p>
                    <p className="flex flex-row gap-[8px]">
                        <ChatsTeardrop size={13.5} className="my-auto text-[#C61DCA]" />
                        <span className="my-auto text-[18px] leading-[25.2px] text-[#686868] font-[500]">Don’t worry, you can come back to this chat anytime!</span>
                    </p>
                </div>
            </div>
        </Modal>
    )
}

export default AskToDraftModal