import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import Quill from 'quill';
import "react-quill/dist/quill.bubble.css";
// import "react-quill/dist/quill.bubble.css";


// Editor is an uncontrolled React component
const Editor = forwardRef(
    ({ defaultValue, onTextChange, onSelectionChange, setSelectedText, placeholderInfocus }, ref) => {
        const containerRef = useRef(null);
        const defaultValueRef = useRef(defaultValue);
        const onTextChangeRef = useRef(onTextChange);
        const onSelectionChangeRef = useRef(onSelectionChange);

        useLayoutEffect(() => {
            onTextChangeRef.current = onTextChange;
            onSelectionChangeRef.current = onSelectionChange;
        });
        ref.current?.disable()
        const fontSizeArr = ['8px', '9px', '10px', '12px', '14px', '16px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px',
            "7pt", "8pt", "9pt", "10pt", "11pt", "12pt", "13pt", "14pt", "15pt", "16pt", "17pt", "18pt", "19pt", "20pt", "21pt", "22pt", "23pt", "24pt", "25pt", "26pt", "27pt", "28pt", "29pt", "30pt", "31pt", "32pt", "33pt", "34pt", "35pt", "36pt", "37pt", "38pt", "39pt", "40pt"
        ];

        var Size = Quill.import('attributors/style/size');
        Size.whitelist = fontSizeArr;
        Quill.register(Size, true);

        useEffect(() => {
            const container = containerRef.current;
            const editorContainer = container.appendChild(
                container.ownerDocument.createElement('div'),
            );
            const quill = new Quill(editorContainer, {
                theme: 'bubble',
                strict: false,
            });

            ref.current = quill;

            if (defaultValueRef.current) {
                quill.setContents(defaultValueRef.current);
            }

            quill.on(Quill.events.TEXT_CHANGE, (...args) => {
                onTextChangeRef.current?.(...args);
            });

            quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
                onSelectionChangeRef.current?.(...args);
            });
            quill.setSelection(453, 0);
            quill.scrollSelectionIntoView();
            quill.theme.tooltip.show();
            quill.theme.tooltip.position(quill.getBounds(10));

            // Reference to custom tooltip
            var tooltip = document.getElementById('custom-tooltip');

            // Function to display the tooltip
            function showTooltip(range) {
                if (range && range.length > 0) {
                    var bounds = quill.getBounds(range);
                    tooltip.style.top = `${bounds.top + window.scrollY - 10}px`; // Adjust Y offset
                    // tooltip.style.left = `${bounds.left + window.scrollX}px`;    // Adjust X offset
                    tooltip.style.display = 'block';
                }
            }

            // Listen for selection change event
            quill.on('selection-change', function (range) {
                if (!placeholderInfocus) {
                    showTooltip(range);
                }
                if (range && range.length > 0) {
                    setSelectedText(quill.getText(range.index, range.length))
                }
            });

            return () => {
                ref.current = null;
                container.innerHTML = '';
            };
        }, [ref]);

        return <div ref={containerRef}></div>;
    },
);

Editor.displayName = 'Editor';

export default Editor;