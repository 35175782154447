import { Check, SkipForward, Sparkle } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react';
import ForwardIcon from "../../images/forward.svg";
import FollowupInputPrompt from './FollowupInputPrompt';
import { useDispatch, useSelector } from 'react-redux';
import { setUpdatingQuestion } from '../../pages/chatpage/messageSlice';
import AskAvatar from "../../images/AskAvatar.svg";
import ReviewAvatar from "../../images/ReviewAvatar.svg";
import DraftAvatar from "../../images/DraftAvatar.svg";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { useWebSocketContext } from '../../WebsocketProvider';
import PostAnswers from './PostAnswers';
import QnA from './QnA';

const FollowupQuestionsPrompt = ({ message, skipped, isFirstAiMessage, threadType, setSteps }) => {

    const dispatch = useDispatch();

    const [answers, setAnswers] = useState({})
    const [activeIndex, setActiveIndex] = useState(0);
    const [questionsAnswered, setQuestionsAnswered] = useState([]);
    const [questionsSkipped, setQuestionsSkipped] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [viewAll, setViewAll] = useState(false);
    const [classes, setClasses] = useState("")
    const [isDocumentGenerated, setIsDocumentGenerated] = useState(false);


    const { sendMessage, createMessage } = useWebSocketContext();
    const threadsData = useSelector(state => state.messageSlice.threadData);
    const awaitingResponse = useSelector(state => state.messageSlice.awaitingResponse);

    useEffect(() => {
        if (threadsData.questions) {
            setActiveIndex(threadsData.questions.active_index);
            setAnswers(threadsData.questions.answers);
            setQuestionsAnswered(threadsData.questions.answers);
            setQuestions(threadsData.questions.questions);
            setQuestionsAnswered(threadsData.questions.answers);
            setQuestionsSkipped(threadsData.questions.skipped);
        }
        if (threadsData?.document?.google_doc_id || threadsData?.document?.url) {
            setIsDocumentGenerated(true);
        }
    }, [threadsData]);


    // useEffect(() => {
    //     // the questions can come from message, or it can come up from the threadData.questions.questions
    //     setQuestions(message.questions);
    // }, [message]);

    const viewAllQuestions = () => {
        setViewAll(true);
        setClasses("")
        var myElement = document.getElementById('follow-up');
        var topPos = myElement.offsetTop;
        document.getElementById('scrolling_div').scrollTop = topPos;
    }

    useEffect(() => {
        // var answeredIndices = Object.keys(answers).map((key) => parseInt(key));
        // setQuestionsAnswered(answers);
    }, [answers]);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         var followupElement = document.querySelector("#follow-up")
    //         var isvisibleFull = isInViewport(followupElement);
    //         debugger;
    //         var isvisibleSmallView;
    //         try {
    //             var smallView = document.querySelector("#small-view")
    //             isvisibleSmallView = isInViewport(smallView);
    //         }
    //         catch {
    //             isvisibleSmallView = false
    //         }

    //         if (!isvisibleFull) {
    //             setClasses("fixed z-50 top-[65px] w-[736px]")
    //             setViewAll(false)
    //         }
    //         else if (isvisibleFull && followupElement.scrollHeight > 200) {
    //             setViewAll(true)
    //             setClasses("")
    //             var myElement = document.getElementById('follow-up');
    //             var topPos = myElement.offsetTop + 100;
    //             document.getElementById('scrolling_div').scrollTop = topPos;
    //         }
    //     }, 1000);

    //     return () => clearInterval(interval);
    // }, []);

    function isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    const skipQuestion = (question, index) => {
        var data = {
            "id": question.id,
            "activeIndex": index,
            "action": "skip"
        }
        dispatch(setUpdatingQuestion(data))
        data = {
            question_id: question.id,
            action: "skipped",
            text: null
        }
        var message = createMessage("draft", "update-question", data)
        sendMessage(message, false, true)
        // dispatch(skipFollowupQuestions(index))
    }

    const submitAnswer = (question, index, response) => {
        var data = {
            "id": question.id,
            "activeIndex": index,
            "action": "answer",
            "response": response
        }
        dispatch(setUpdatingQuestion(data))
        data = {
            question_id: question.id,
            action: "submitted",
            text: response,
        }
        var message = createMessage("draft", "update-question", data)
        sendMessage(message, false, true)
        setQuestionsAnswered({ ...questionsAnswered, [index]: response })
        setActiveIndex(index + 1)
    }



    const formatText = (text) => {
        text = text.replace(/`/g, "&#96;");
        text = text.replace(/\\n/g, ' \n ');
        text = text.replace(/\n#/g, ' \n \n #');
        text = text.replace(/\n/g, ' \n\n ');
        text = text.replace(/\\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
        text = text.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
        return text;
    }

    const submitFollowUpQuestions = () => {
        var message = createMessage("draft", "submit-and-create-document", { "thread_id": threadsData.id })
        sendMessage(message, true)
        setSteps([
            "Analyzing your request",
            "Conducting legal research",
            "Drafting your document"
        ])
    }
    const answeredIndexes = Object.keys(questionsAnswered)
        .filter((key) => questionsAnswered[key] !== "")
        .map((key) => parseInt(key));

    return (
        <div className={`${classes}`} id="follow-up">
            {/* {(questionsSkipped.length + questionsAnswered.length === questions.length ) && ( */}
            {(true) && (
                <>
                    {/* messageIndex = index of this particular message in the chat 
                totalMessageCount = total number of messages in the chat. 

                This is required to check when the follow up questions should be fully visible, or when it should be collapsed
            */}

                    <div className="flex flex-col w-[100%] mt-[20px]">
                        {message?.message_text &&
                            <div className={`flex rounded-b-[5px] 
                                   ${message?.is_user_message === true ? "text-black font-[500] text-[24px] leading-[33.6px]" : "text-[16px] leading-[25.6px] text-[#333333]"}`
                            }>
                                <div className="chat">
                                    {isFirstAiMessage && threadType === "draft" && (<img src={DraftAvatar} alt="AI" className="w-[32px] h-[32px] mb-[16px]" />)}
                                    <Markdown remarkPlugins={[remarkGfm]}>{`${formatText(message?.message_text)}`}</Markdown>
                                </div>
                            </div>
                        }
                    </div>
                    {isDocumentGenerated ? (
                        <PostAnswers
                            questions={questions}
                            questionsAnswered={questionsAnswered}
                            answers={answers}
                            skipped={skipped}
                            setAnswers={setAnswers}
                            isDocumentGenerated={isDocumentGenerated}
                        />
                    ) : (
                        <>
                            <div className='flex flex-col rounded-[12px]'>
                                {questions.map((question, index) => (
                                    <QnA key={index}
                                        index={index}
                                        activeIndex={activeIndex}
                                        setActiveIndex={setActiveIndex}
                                        message={message}
                                        submitAnswer={submitAnswer}
                                        answers={answers}
                                        setAnswers={setAnswers}
                                        awaitingResponse={awaitingResponse}
                                        questionsAnswered={questionsAnswered}
                                        questionsSkipped={questionsSkipped}
                                        isDocumentGenerated={isDocumentGenerated}
                                        threadsData={threadsData}
                                        question={question}
                                        answeredIndexes={answeredIndexes}
                                        skipQuestion={skipQuestion}
                                        skipped={skipped} />
                                ))}
                            </div >
                            {!awaitingResponse && !(isDocumentGenerated) && !threadsData.isSharedThread && (
                                <div className="w-full flex justify-end mt-[12px]">
                                    {((Object.keys(questionsAnswered).length + questionsSkipped.length) === 0) && (
                                        <button className='rounded-[8px]  text-[#264653] text-[14px] leading-[19.6px] p-[12px] flex gap-[4px] cursor-pointer' onClick={submitFollowUpQuestions}>
                                            <span>Skip all questions</span>
                                            <img src={ForwardIcon} className='my-auto w-[19.5px]' alt="forward-icon" />
                                        </button>
                                    )}
                                    {((Object.keys(questionsAnswered).length + questionsSkipped.length) > 0) && (
                                        <button className='cursor-pointer rounded-[8px] bg-[#264653] text-white text-[14px] leading-[19.6px] p-[12px]' onClick={submitFollowUpQuestions}>Submit & Generate Draft</button>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </>)}
        </div >
    );
};

export default FollowupQuestionsPrompt;