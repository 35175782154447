import React, { useEffect, useState } from 'react'
import { Modal } from 'antd';
import SubscriptionPopup from './SubscriptionPopup'
import UpgradingPlan from './UpgradingPlan'
import BusinessPlanUnlocked from './BusinessPlanUnlocked'
import FindBestLawyer from './FindBestLawyer'
import MeetingConfirmation from './MeetingConfirmation'
import NextSteps from './NextSteps'
import { useDispatch, useSelector } from 'react-redux'
import ScheduleMeeting from './ScheduleMeeting';
import { getLawyerHub, getUserMetadata, setShowBusinessPlanUnlocked, setShowUpgradingPlan } from 'src/pages/onboarding/onboardingSlice';

const UpSellFlowPopup = () => {
    const dispatch = useDispatch()
    const showSubscriptionPopup = useSelector((state) => state.onboarding.showSubscriptionPopup);
    const showUpgradingPlan = useSelector((state) => state.onboarding.showUpgradingPlan);
    const showBusinessPlanUnlocked = useSelector((state) => state.onboarding.showBusinessPlanUnlocked);
    const showFindBestLawyer = useSelector((state) => state.onboarding.showFindBestLawyer);
    const showMeetingConfirmation = useSelector((state) => state.onboarding.showMeetingConfirmation);
    const showNextSteps = useSelector((state) => state.onboarding.showNextSteps);
    const showScheduleMeeting = useSelector((state) => state.onboarding.showScheduleMeeting);
    const paymentStatus = useSelector((state) => state.chat.paymentStatus);

    const [modalClass, setModalClass] = useState('')

    useEffect(() => {
        // debugger;
        if (showSubscriptionPopup) {
            setModalClass("subscription-popup")
        }
        if (showUpgradingPlan) {
            setModalClass("upgrading-plan")
        }
        if (showBusinessPlanUnlocked) {
            setModalClass("business-plan-unlocked")
        }
        if (showFindBestLawyer) {
            setModalClass("find-best-lawyer")
        }
        if (showMeetingConfirmation) {
            setModalClass("meeting-confirmation")
        }
        if (showNextSteps) {
            setModalClass("next-steps")
        }
        if (showScheduleMeeting) {
            setModalClass("show-scheduled-meeting p-0")
        }
        if (showUpgradingPlan && (paymentStatus === 'successful')) {
            dispatch(setShowBusinessPlanUnlocked(true));
            dispatch(setShowUpgradingPlan(false));
            dispatch(getLawyerHub())
            dispatch(getUserMetadata())
        }

    }, [showSubscriptionPopup, showUpgradingPlan, showBusinessPlanUnlocked, showFindBestLawyer, showMeetingConfirmation, showNextSteps, showScheduleMeeting, paymentStatus])
    return (
        <Modal
            open={showSubscriptionPopup || showUpgradingPlan || showBusinessPlanUnlocked || showFindBestLawyer || showMeetingConfirmation || showNextSteps || showScheduleMeeting}
            className={`py-0 ${modalClass} overflow-x-hidden`}
            footer={null}
        >

            {/* The headline should say “You’ve used X tasks this cycle, upgrade to get more credits” ✅
                Apply the other stuff above (logos etc) ✅
            */}
            {/* {freeuserSubscriptionPopup} new popup will be requierd */}

            {/* essential user subscription popup. update the existing one to essential user subscription popup */}
            {showSubscriptionPopup && <SubscriptionPopup />}


            {showUpgradingPlan && (paymentStatus !== 'successful') && <UpgradingPlan />}
            {showBusinessPlanUnlocked && <BusinessPlanUnlocked />}
            {showFindBestLawyer && <FindBestLawyer />}
            {showScheduleMeeting && <div className={`${modalClass} bg-white p-0 flex flex-col`}><ScheduleMeeting /></div>}
            {showMeetingConfirmation && <MeetingConfirmation />}
            {showNextSteps && <NextSteps />}
        </Modal>
    )
}

export default UpSellFlowPopup