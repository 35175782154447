import { Briefcase, ChatsCircle, Check, Clock, Export, Lightning, PenNib, ShareFat } from '@phosphor-icons/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowUpgradePlanNudge, setShowRequestLegalReviewAside } from 'src/pages/onboarding/onboardingSlice';
import { resetPaymentStatus, setCheckoutUrl } from '../chatSlice';
import { trackAnalytics } from 'src/pages/helpers';
import { generateSharedId, setThreadSelectedToShare } from '../threadsSlice';
import ShareThreadModal from 'src/components/chat/Modals/ShareThreadModal';
import { ReviewStatus } from 'src/components/Constants';

const TopNav = ({ page }) => {
    const dispatch = useDispatch();
    const [text, setText] = React.useState('');
    const [duration, setDuration] = React.useState('')
    const [actionOnThread, setActionOnThread] = React.useState(""); // actions that can be done on a thread i.e Rename, Delete, Share
    const [updateActionLoading, setUpdateActionLoading] = React.useState(false);
    const [icon, setIcon] = React.useState(<span><ChatsCircle size={16} className="text-[#C61DCA]" /></span>);

    const currentDate = new Date();
    const options = { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' };
    const formattedDate = currentDate.toLocaleDateString('en-US', options);

    const threads = useSelector((state) => state.threads.threads);
    const userMetadata = useSelector((state) => state.onboarding.userMetadata);
    const threadData = useSelector(state => state.messageSlice.threadData);
    const showUpgradePlanNudge = useSelector(state => state.onboarding.showUpgradePlanNudge);
    const email = useSelector(state => state.onboarding.email);
    const lawyerHubData = useSelector((state) => state.onboarding.lawyerHubData);

    useEffect(() => {
        if (page === "chat" && Object.keys(threads).length > 0) {
            var keyName = "";
            for (var key in threads) {
                var index = threads[key].find(thread => thread.id === threadData.id);
                if (index) {
                    keyName = key;
                    break;
                }
            }
            if (keyName) {
                setDuration(key)
            }
        }
    }, [page, threads]) // eslint-disable-line

    useEffect(() => {
        if (window.location.pathname.search("draft") > -1) {
            setIcon(<span><PenNib size={16} className="text-[#13866F]" /></span>)
        }
        else {
            setIcon(<span><ChatsCircle size={16} className="text-[#C61DCA]" /></span>)
        }

        if (threadData?.title) {
            setText(threadData.title);
        }
        else {
            setText(formattedDate)
        }
    }, [threadData]) // eslint-disable-line

    const openUpgradeModal = () => {
        trackAnalytics("subscription popup triggered", { "position": "topnav", "user_email": email })
        trackAnalytics("dashboard_home_upgrade", { "user_email": email })
        dispatch(setCheckoutUrl(""))
        dispatch(resetPaymentStatus())
        dispatch(setShowUpgradePlanNudge(true))
    }

    const shareThread = () => {
        setActionOnThread('Share');
        dispatch(setThreadSelectedToShare(threadData.id));
        dispatch(generateSharedId({ thread: threadData, "duration": duration }))
    }
    const showBanner = () => {
        switch (userMetadata?.subscription_type) {
            case "":
            case null:
            case undefined:
                return <></>
            case "free_tier":
                var numTotal = userMetadata?.threads_total;
                var numRemaining = userMetadata?.threads_remaining;
                var dark = Math.round((numRemaining / numTotal * 80) / 5) * 5;

                return <div className="flex flex-row gap-[16px]">
                    <div className="flex flex-col gap-[8px] my-auto" title={`Legal tasks will renew again on ${userMetadata?.subscription_renewal_date}`}>
                        <span className="my-auto text-[12px] leading-[18px]">{numRemaining}/{numTotal} tasks left</span>
                        <div className="flex flex-row w-[80px] h-[4px] bg-[#E4E4E4] rounded-[40px]">
                            <span className={`h-[4px] disp-${dark} rounded-[40px]`}></span>
                            {/* <span className={`bg-[#18475ACC] h-[4px] w-[${light}px]`}></span> */}
                        </div>
                    </div>

                    <button className='px-[12px] py-[12px] w-[187px] text-black border rounded bg-white flex flex-row gap-[8px]' onClick={() => openUpgradeModal()}>
                        <span className="my-auto text-[14px] leading-[19.6px]">Upgrade to Premium </span>
                        <span className="my-auto"><Lightning size={16} className="my-auto text-[#DCA218]" /> </span>
                    </button>
                </div>;
            case "subscriber_business":
                var numTotal = userMetadata?.threads_total;
                var numRemaining = userMetadata?.threads_remaining;
                var dark = Math.round((numRemaining / numTotal * 80) / 5) * 5;
                if (numTotal) {
                    return <div className="flex flex-row gap-[16px]">
                        <div className="flex flex-col gap-[8px] my-auto" title={`Legal tasks will renew again on ${userMetadata?.subscription_renewal_date}`}>
                            <span className="my-auto text-[12px] leading-[18px]">{numRemaining}/{numTotal} tasks left</span>
                            <div className="flex flex-row w-[80px] h-[4px] bg-[#E4E4E4] rounded-[40px]">
                                <span className={`h-[4px] disp-${dark} rounded-[40px]`}></span>
                                {/* <span className={`bg-[#18475ACC] h-[4px] w-[${light}px]`}></span> */}
                            </div>
                        </div>
                        {numRemaining < 3 && (
                            <button className='p-[12px] text-black border !border-[#000] rounded-[8px] bg-[#ECF5F3] flex flex-row gap-[12px]' onClick={() => openUpgradeModal()}>
                                <span className="my-auto text-[14px] leading-[19.6px]">Upgrade to Unlimited Plan </span>
                                <span className="my-auto"><Lightning size={16} className="my-auto text-[#18475A]" weight="fill" /> </span>
                            </button>
                        )}
                    </div>
                }
                return <></>
            case "subscriber_enterprise":
                return <div className="flex flex-row gap-[16px]">
                    <div className="flex flex-row gap-[8px] my-auto">
                        <span className="my-auto text-[12px] leading-[18px]">You have unlimited tasks</span>
                        <span className="my-auto"><Lightning size={16} className="my-auto text-[#18475A]" weight="fill" /> </span>
                    </div>
                </div>;

            default:
                return <></>
        }
        // case payment_status = "starter/business/enterprise"
    }

    if (page === "home") {
        return (
            <div className="flex flex-row">
                {/* Your component content goes here */}
                <div className="flex justify-end w-full py-[16px] mx-auto px-[48px] gap-[16px]">
                    {showBanner()}
                </div>
            </div>
        );
    } else if (page === "ask_home" || page === "chat") {
        var message = "";
        var remaining = userMetadata?.legal_review_credits_total - userMetadata?.legal_review_credits_used
        var legalReview = lawyerHubData?.find((item) => item.thread_id === threadData.id);
        if (userMetadata?.subscription_type === "subscriber_business") {
            if (userMetadata?.legal_review_credits_used === 0) {
                message = `${remaining} legal review included`
            }
            else if (remaining > 0) {
                message = `${remaining} legal review remaining`
            }
            else {
                message = `0 legal review remaining`
            }
        }
        return (
            <div className="flex flex-row ">
                {/* Your component content goes here */}
                <div className="flex justify-between w-full py-[12px] border-b border-[#E4E4E4] px-[24px] bg-[#FCFCFA]">
                    <h1 className='flex flex-row gap-[8px] my-auto py-auto'>
                        {icon}
                        <span className='text-[14px] leading-[21px] font-[500] my-auto'>{text}</span>
                    </h1>
                    {page === "chat" &&
                        <div className='flex justify-between gap-[16px]'>
                            <span className="my-auto text-[12px] leading-[18px]">{message}</span>
                            {!legalReview ?
                                <button className="bg-[#18475A] border w-[158x] h-[40px] rounded-[8px] gap-[12px] px-[12px] flex flex-row"
                                    onClick={() => dispatch(setShowRequestLegalReviewAside(true))}>
                                    <span className="my-auto"><Briefcase size={14} className="text-white my-auto" /></span>
                                    <span className="text-white my-auto text-[14px] leading-[19.6px]">Request Review</span>
                                </button>
                                :
                                <>
                                    {legalReview.status !== ReviewStatus.submitted_legal_review ?
                                        <button className="bg-incomplete border-[#DCA218] border-[1px] w-[158x] h-[40px] rounded-[8px] gap-[12px] px-[12px] flex flex-row">
                                            <span className="my-auto"><Briefcase size={14} className="text-[#353535] my-auto" /></span>
                                            <span className="text-[#353535] my-auto text-[14px] leading-[19.6px]">Review Pending</span>
                                            <span className="my-auto"><Clock size={14} className="text-[#DCA218] my-auto" /></span>
                                        </button>
                                        :
                                        <button className="bg-accurate border-[1px] border-[#137513] w-[158x] h-[40px] rounded-[8px] gap-[12px] px-[12px] flex flex-row">
                                            <span className="my-auto"><Briefcase size={14} className="text-white my-auto" /></span>
                                            <span className="text-white my-auto text-[14px] leading-[19.6px]"> Review Completed</span>
                                            <span className="my-auto"><Check size={14} className="text-[#137513] my-auto" /></span>
                                        </button>
                                    }
                                </>

                            }

                            <button className="bg-white border w-[40px] h-[40px] rounded-[8px] " onClick={shareThread}>
                                <Export size={13} className="mx-auto text-[#353535]" />
                            </button>
                        </div>
                    }
                    <ShareThreadModal
                        open={actionOnThread === 'Share'}
                        setActionOnThread={setActionOnThread}
                        actionOnThread="share"
                        threadInfo={{ "thread": threadData }}
                        loading={updateActionLoading}
                        setLoading={setUpdateActionLoading}
                    />

                </div>
            </div>
        );

    }
    else {
        return (
            <div className="flex flex-row w-full h-full py-auto">
            </div>
        )
    }

}

export default TopNav;