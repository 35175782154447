import React from 'react'
import LawyerHubWelcomeImage from '../../images/lawyer-hub-welcome.png'
import { ArrowUpRight } from "@phosphor-icons/react";


const WelcomeBanner = () => {
    return (
        <div className='bg-[#ECF5F3] w-full flex px-10 pt-10 pb-6 border border-[#748A8C1F] rounded-xl'>
            <div className='w-1/2'>
                <h1 className='text-2xl font-bold text-[#18475A]' >Welcome to the Lawyer Hub</h1>
                <p className='mt-4 text-[#353535] text-base pr-12 font-medium '> The Lawyer Hub is your space to track lawyer reviews, connect with your lawyer, and access past feedback on your review requests.</p>
                <button className='flex mt-10  font-semibold rounded-lg bg-white text-[#18475A] px-3 py-2 items-center justify-center'> Learn more about lawyer reviews < ArrowUpRight className='ml-2' size={20} /></button >
            </div>
            <div className='w-1/2 h-full border'>
                <div className='h-60'>
                    <img alt='lawyer hub welcome banner' className='object-contain scale-[110%] w-full h-full' src={LawyerHubWelcomeImage} />
                </div>
            </div>
        </div>
    )
}

export default WelcomeBanner