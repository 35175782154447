import React from 'react'
import TextInput from '../Inputs/TextInput'
import SelectInput from '../Inputs/Select'
import { stateOptionsNew, entityOptions } from "../../../components/Constants";


// interface BusinessFormProps {
//   setCompanyChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
//   setPurposeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
//   setPrimaryStateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
//   setIncorporationStateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
//   setEntityStructureChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
// }

const BusinessForm = ({
        businessPurpose,
        entityStructure,
        businessState,
        incorporationState,
        organizationName,
        setCompanyChange,
        setPurposeChange,
        setPrimaryStateChange,
        setIncorporationStateChange,
        setEntityStructureChange,
        errors
}) => {
        return (
                <div>
                        <TextInput
                                wrapperClassName='mt-3'
                                label={<p>Company Name<sup></sup></p>}
                                value={organizationName}
                                placeholder='Company Name'
                                onChange={setCompanyChange}
                                tooltip="This will be the default name in your contracts to save time"
                                error={(errors?.organizationName && !organizationName) && errors.organizationName}
                        />
                        <TextInput
                                wrapperClassName='mt-3'
                                label={<p>Purpose of Business<sup></sup></p>}
                                value={businessPurpose}
                                placeholder='Eg,. We sell electric toothbrushes for toddlers '
                                onChange={setPurposeChange}
                                tooltip="We use this to apply the correct regulations and to match you with the right lawyer."
                                error={(errors?.businessPurpose && !businessPurpose) && errors.businessPurpose}
                        />
                        <SelectInput
                                wrapperClassName='mt-3'
                                label={<p>Entity Structure <sup></sup></p>}
                                placeholder='Select the entity structure of your company'
                                onChange={setEntityStructureChange}
                                options={entityOptions}
                                value={entityStructure}
                                tooltip="In some situations, the AI advice changes depending on the corporate structure."
                                error={(errors?.entityStructure && !entityStructure) && errors.entityStructure}
                        />
                        <SelectInput
                                wrapperClassName='mt-3'
                                label={<p>Primary State of Business<sup></sup></p>}
                                placeholder='Select the state your company conducts its business operations'
                                onChange={setPrimaryStateChange}
                                options={stateOptionsNew}
                                value={businessState}
                                tooltip="This helps the AI determine what body of law to apply"
                                error={(errors?.businessState && !businessState) && errors.businessState}
                        />
                        <SelectInput
                                wrapperClassName='mt-3'
                                label={<p>State of Incorporation<sup></sup></p>}
                                placeholder='Select the state where the company was legally formed and registered'
                                onChange={setIncorporationStateChange}
                                options={stateOptionsNew}
                                value={incorporationState}
                                tooltip="This helps the AI determine what body of law to apply"
                                error={(errors?.incorporationState && !incorporationState) && errors.incorporationState}
                        />

                </div>
        )
}

export default BusinessForm