import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastHandler = () => {


    return (
        <div className="toast-container">
            <ToastContainer autoClose={2000}
                pauseOnFocusLoss={false}
                closeOnClick={true}
                pauseOnHover={false} />
        </div>
    );
};

export default ToastHandler;