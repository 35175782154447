import React, { useState } from 'react';
import { CaretUp, CaretDown } from '@phosphor-icons/react';
import BracketsSquare from "../../../images/icons/BracketsSquare.svg";
import { useDispatch } from 'react-redux';
import { dismissPlaceholderAPI, setPlaceholderDismissed } from '../../../pages/chatpage/chatSlice';


const UpdatePlaceholderComponent = ({ placeholder, index, updatePlaceholder, focusPlaceholder, isActivePlaceholder }) => {
    const dispatch = useDispatch()
    const [currentValue, setCurrentValue] = useState(placeholder.current_value);

    const dismissPlaceholder = () => {
        dispatch(dismissPlaceholderAPI(placeholder.id));
        dispatch(setPlaceholderDismissed(index));
    }

    return (
        <div className="w-full flex flex-col">
            <div className='top-section-placeholders'>
                <div className='flex justify-between'>
                    <div className="flex text-[18px] gap-[12px] h-[24px]" onClick={() => { focusPlaceholder(placeholder, index) }}>
                        {/* <BracketsSquare className='text-[#538AFF]' /> */}
                        <img src={BracketsSquare} alt="BracketsSquare" className="w-[24px] h-[24px] my-auto" />
                        <span className="text-[12px] leading-[19.2px] text-[#686868] my-auto">Placeholders</span>
                    </div>
                    <div className=''>
                        {isActivePlaceholder ?
                            <CaretUp size={16} className="text-[#7F8C8D]" /> :
                            <CaretDown size={16} className="text-[#7F8C8D]" />
                        }

                    </div>
                </div>
                {isActivePlaceholder ?
                    <hr className="mt-[16px] text-[#D1D1D1] bg-[#D1D1D1]" />
                    : null}
                <p className="text-[16px] leading-[25.6px] text-[#353535] font-[700] mt-[16px]">{placeholder.name_of_variable}</p>
            </div>

            {/* <p>{JSON.stringify(placeholder.indices)}</p> */}
            {isActivePlaceholder &&
                <div className='flex flex-col gap-[16px] mt-[16px]'>
                    <input type="text"
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                        value={currentValue}
                        onChange={(e) => {
                            setCurrentValue(e.target.value);
                        }}
                    />
                    <div className="flex flex-row gap-[24px] justify-end">
                        <button type="button" onClick={() => { dismissPlaceholder() }}
                            className="text-[14px] leading-[19.6px] font-[500] hover:cursor-pointer disabled:cursor-not-allowed  text-[#686868]">
                            Dismiss
                        </button>

                        <button type="button" onClick={() => { updatePlaceholder(index, currentValue) }}
                            className="text-[14px] leading-[19.6px] font-[500] rounded-[8px] hover:cursor-pointer disabled:cursor-not-allowed bg-[#264653] p-[12px] text-white">
                            Replace All
                        </button>
                    </div>
                </div>
            }
            {/* <p>Initial Value: {placeholder.Initial_value}</p>
                                        <p>Action: {placeholder.action}</p>
                                        <p>Indices: {JSON.stringify(placeholder.indices)}</p> */}
        </div>
    );
};

export default UpdatePlaceholderComponent;