/* eslint-disable jsx-a11y/aria-role */
import React, { useEffect } from 'react'
import Modal from '../../components/Modal'
import VerticalProgress from './components/VerticalProgress'
import { ArrowUpRight, Check, Clock, Info, Prohibit } from '@phosphor-icons/react'
import Button from './Button'
import { useDispatch, useSelector } from 'react-redux'
import { ReviewStatus } from 'src/components/Constants'
import { use } from 'marked'
import { setShowLegalReviewRequestStatus } from '../onboarding/onboardingSlice'



const LawyerReviewRequested = () => {
    const dispatch = useDispatch();
    const currentPageLegalReviewStatus = useSelector(state => state.onboarding.currentPageLegalReviewStatus);
    const userMetadata = useSelector((state) => state.onboarding.userMetadata);
    const showLegalReviewRequestStatus = useSelector((state) => state.onboarding.showLegalReviewRequestStatus);

    const [steps, setSteps] = React.useState([])

    const usteps = [
        {
            stepIcon: { icon: Check, color: '#137513' },
            title: { text: 'Review Requested' },
            description: { text: 'on 17th October, 2024 at 9:00 AM' },
        },
        {
            stepIcon: {
                icon: Clock,
                color: '#DCA218'
            },
            title: {
                text: 'Lawyer is analysing your request'
            },
            description: {
                text: <>{userMetadata?.lawyer_info?.name ? userMetadata?.lawyer_info?.name : "Your lawyer"} will understand your specific needs before starting the legal review. <br /> You will be promptly notified on your email ID regarding this </>
            },
        },
        {
            stepIcon: {
                icon: Check,
                color: '#137513'
            },
            title: {
                text: 'Lawyer has accepted to review the task'
            },
            description: {
                text: 'on 18th October, 2024 at 7:21 AM'
            },
        },
        {
            stepIcon: {
                icon: Clock,
                color: '#DCA218'
            },
            title: {
                text: 'Lawyer Review is in progress',
                extraInfo: {
                    text: 'takes upto 2 days',
                    color: '#DCA218'
                }
            },
            description: {
                text: <>1-on-1 with our legal support team on<span className='font-semibold'> 24 Oct @10:00PM</span> </>
            },
            lawyerCard: {
                lawyerImg: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDpWYsLSeY1sLvwgFNwBeJGjszUfEofDpwJw&s',
                name: 'Mr. Andrew Meyer',
                email: 'andrew.meyer@inhouse.so',
                phone: '+1 514 847 4524'
            }
        },
        {
            stepIcon: {
                icon: Prohibit,
                color: '#A20009'
            },
            title: {
                text: 'Lawyer has rejected the task ',
            },
            description: {
                text: 'on 18th October, 2024 at 7:21 AM'
            },
        },
    ]

    const getStep1 = () => {
        return {
            stepIcon: { icon: Check, color: '#137513' },
            title: { text: 'Review Requested' },
            description: {
                text: `on ${new Date(currentPageLegalReviewStatus.lr_requested_at).toLocaleString('en-US', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true
                })}`
            },
        }
    }
    const generateStepsForAcceptedByInhouse = () => {
        var userSteps = [
            getStep1(),
            {
                stepIcon: { icon: Clock, color: '#DCA218' },
                title: { text: 'Lawyer is analysing your request' },
                description: { text: <>{userMetadata?.lawyer_info?.name ? userMetadata?.lawyer_info?.name : "Your lawyer"} will understand your specific needs before starting the legal review. <br /> You will be promptly notified on your email ID regarding this </> },
                lawyerCard: {
                    lawyerImg: `${userMetadata?.lawyer_info?.pre_signed_image_url ? userMetadata?.lawyer_info?.pre_signed_image_url : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDpWYsLSeY1sLvwgFNwBeJGjszUfEofDpwJw&s'}`,
                    name: userMetadata?.lawyer_info?.name ? userMetadata?.lawyer_info?.name : "Your lawyer",
                    phone: userMetadata?.lawyer_info?.phone_number ? userMetadata?.lawyer_info?.phone_number : "-",
                    email: userMetadata?.lawyer_info?.email ? userMetadata?.lawyer_info?.email : "-",
                }
            }
        ]
        return userSteps
    }

    const generateStepsForAcceptedByLawyer = () => {
        var userSteps = [
            getStep1(),
            {
                stepIcon: { icon: Check, color: '#137513' },
                title: {
                    text: 'Lawyer has accepted to review the task ',
                },
                description: {
                    text: `on ${new Date(currentPageLegalReviewStatus.lr_accepted_by_lawyer_at).toLocaleString('en-US', {
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                    })}`
                },
            },
            {
                stepIcon: { icon: Clock, color: '#DCA218' },
                title: { text: 'Lawyer Review is in progress' },
                description: { text: <>{userMetadata?.lawyer_info?.name ? userMetadata?.lawyer_info?.name : "Your lawyer"} will understand your specific needs before starting the legal review. <br /> You will be promptly notified on your email ID regarding this </> },
                lawyerCard: {
                    lawyerImg: `${userMetadata?.lawyer_info?.pre_signed_image_url ? userMetadata?.lawyer_info?.pre_signed_image_url : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDpWYsLSeY1sLvwgFNwBeJGjszUfEofDpwJw&s'}`,
                    name: userMetadata?.lawyer_info?.name ? userMetadata?.lawyer_info?.name : "Your lawyer",
                    phone: userMetadata?.lawyer_info?.phone_number ? userMetadata?.lawyer_info?.phone_number : "-",
                    email: userMetadata?.lawyer_info?.email ? userMetadata?.lawyer_info?.email : "-",
                }
            }
        ]
        return userSteps
    }

    const generateStepsForPendingLawyerMatching = () => {
        var date, textLine1, textLine2;
        if (userMetadata?.lawyer_matching_meeting_at) {
            date = new Date(userMetadata?.lawyer_matching_meeting_at).toLocaleString('en-US', {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            })
            textLine1 = 'Assigning you a dedicated lawyer'
            textLine2 = `Scheduled a call with the legal support team on ${date}`
        }
        var userSteps = [
            getStep1(),
            {
                stepIcon: { icon: Clock, color: '#DCA218' },
                title: {
                    text: textLine1,
                },
                description: {
                    text: textLine2
                },
            },

        ]
        return userSteps
    }

    const generateStepsForSubmittedLegalReview = () => {
        var userSteps = [
            getStep1(),
            {
                stepIcon: { icon: Check, color: '#137513' },
                title: {
                    text: 'Lawyer has accepted to review the task ',
                },
                description: {
                    text: `on ${new Date(currentPageLegalReviewStatus.lr_accepted_by_lawyer_at).toLocaleString('en-US', {
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                    })}`
                },
            },
            {
                stepIcon: { icon: Clock, color: '#DCA218' },
                title: { text: 'Lawyer Review is in progress' },
                description: { text: <>{userMetadata?.lawyer_info?.name ? userMetadata?.lawyer_info?.name : "Your lawyer"} will understand your specific needs before starting the legal review. <br /> You will be promptly notified on your email ID regarding this </> },
                lawyerCard: {
                    lawyerImg: `${userMetadata?.lawyer_info?.pre_signed_image_url ? userMetadata?.lawyer_info?.pre_signed_image_url : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDpWYsLSeY1sLvwgFNwBeJGjszUfEofDpwJw&s'}`,
                    name: userMetadata?.lawyer_info?.name ? userMetadata?.lawyer_info?.name : "Your lawyer",
                    phone: userMetadata?.lawyer_info?.phone_number ? userMetadata?.lawyer_info?.phone_number : "-",
                    email: userMetadata?.lawyer_info?.email ? userMetadata?.lawyer_info?.email : "-",
                }
            }
        ]
        return userSteps
    }

    const generateStepsForRejectedByLawyer = () => {
    }

    const generateSteps = () => {
        switch (currentPageLegalReviewStatus?.status) {
            case ReviewStatus.accepted_by_inhouse:
                return generateStepsForAcceptedByInhouse()

            case ReviewStatus.accepted_by_lawyer:
                return generateStepsForAcceptedByLawyer()

            case ReviewStatus.pending_lawyer_matching:
                return generateStepsForPendingLawyerMatching()
            default:
                break
        }
    }

    const closeModal = () => {
        dispatch(setShowLegalReviewRequestStatus(false))
    }
    useEffect(() => {
        setSteps(generateSteps())
    }, [showLegalReviewRequestStatus])



    return (
        <Modal
            isOpen={showLegalReviewRequestStatus}
            bodyClassName={'py-0'}
            contentAreaStyles={{ paddingLeft: '0px', paddingRight: '0px' }}
            onClose={() => { closeModal() }}
            closeBtnClassName='top-6'
        >
            <div className='py-8 w-[800px]'>
                <section role='header' className='px-10 pb-6 border-b'>
                    <p className='text-[#686868] text-base font-normal'>A lawyer review has been requested for this thread</p>
                    <p className='text-[#011513] text-2xl font-bold mt-2' >Here is what's next </p>
                </section>
                <section className='px-10 mt-6 pb-6  h-[360px] overflow-y-scroll'>
                    <VerticalProgress wrapperClassName='mt-6' steps={steps} />
                    {/* <h6 className='text-[#18475A] mt-6 font-bold text-[10px] uppercase'>Lawyer’s reason for refusal</h6>
                    <p className='px-2 py-2 mt-3 border rounded text-[#686868] text-sm font-medium'>
                        To give this the attention it deserves, I need more context. What are your specific goals? What questions are you hoping to get answered? Please request for another review with these questions answered.
                    </p>
                    <div className='flex rounded-md items-center mt-4 text-sm font-medium bg-[#DCA218]/10 px-2 py-1'><Info className='text-[#DCA218] mr-2' size={16} /> <p>You will receive a full refund of $49 on your legal review since we weren’t able to fulfil this review</p></div> */}
                </section>
                <section className='flex justify-between px-10 pt-6'>
                    <span></span>
                    {/* <button className='text-[#18475A] flex items-center text-sm font-semibold underline'>See your request <ArrowUpRight className='ml-2' /></button> */}
                    <Button
                        onClick={() => { closeModal() }}
                        className={'py-3'}
                        text={'Ok, got it!'}
                    />
                </section>
            </div>
        </Modal>
    )
}

export default LawyerReviewRequested