import React from 'react'
import Button from '../Button'
import { Check } from '@phosphor-icons/react'

const SubscriptionCard = (props) => {
    const { data, billCycle, PLAN_TYPES, setPlan, checkoutUrlStatus } = props;
    const {
        isActive,
        title,
        titleIcon,
        price,
        priceYearly,
        planName,
        priceCycle = 'month',
        badge = { text: 'Recommended', className: '', show: false },
        button,
        features = [],
        wrapperClassName,
        hidePricing
    } = data
    return (
        <div className={`overflow-hidden w-[440px] rounded-xl border ${isActive && 'outline outline-[4px] outline-[#ECF5F380] !border-[#248370]'} relative ${button ? 'pb-20' : 'pb-10'} ${wrapperClassName}`}>
            {badge.show && <div className={`absolute top-0 right-0 text-[10px] font-bold rounded-bl-xl py-2 px-4 bg-[#18475A14] uppercase ${badge.className}`}>{badge.text}</div>}
            <div className={`px-6 pt-6 pb-3 ${isActive ? 'bg-[#ECF5F380]' : 'border-b'}`}>
                {hidePricing ? <>
                    {titleIcon}
                    <h5 className='flex items-center text-2xl text-[#264653] font-bold mt-2'>Business Plan
                    </h5>
                </>
                    :
                    <>
                        <h5 className='flex items-center text-lg text-[#264653] font-semibold'>{title}{titleIcon}
                        </h5>
                        {(price || priceYearly) && <p className='text-[#011513] text-2xl font-medium'>${billCycle === PLAN_TYPES.MONTHLY ? price : priceYearly} <span className='text-sm font-normal'>/{priceCycle}</span></p>}
                        {(price || priceYearly) && <p className='text-[#686868] text-[10px] mt-[2px] uppercase'>BILLED {billCycle}</p>}
                        {(!price && !priceYearly) && <p className='text-[#686868] text-2xl font-normal'>Free</p>}
                    </>}
            </div>
            <div>
                <ul className='px-4 mt-6'>
                    {features.map(feature => (
                        <li className={`text-sm mt-3 font-normal flex items-center text-[#353535] ${feature.highlight && '!font-bold'}`}> <Check size={10} className='mr-2' />{feature.text}</li>
                    ))}

                </ul>

            </div>
            {button && <div className='absolute bottom-4 right-4'>
                <Button
                    disabled={checkoutUrlStatus === 'loading'}
                    onClick={() => { setPlan(planName) }}
                    {...button}
                />
            </div>}

        </div>
    )
}

export default SubscriptionCard