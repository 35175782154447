import React, { useEffect, useState } from 'react';
import Modal from "antd";
import { Briefcase, CalendarDots, CreditCard } from '@phosphor-icons/react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPageLegalReviewStatus, setShowFindBestLawyer, setShowLegalReviewCompletedModal, setShowLegalReviewRequestStatus } from '../onboarding/onboardingSlice';
import { openPopup } from 'src/components/DocumentViewer/helpers';
import { ReviewStatus } from 'src/components/Constants';

const LegalReviewMessagePrompt = (props) => {
    const dispatch = useDispatch();
    const { chatInfo, email, isFirstAiMessage, threadData } = props;
    const [pageData, setPageData] = useState({})
    const [legalReviewRequest, setLegalReviewRequest] = useState({})

    const lawyerHubData = useSelector((state) => state.onboarding.lawyerHubData);
    const userMetadata = useSelector((state) => state.onboarding.userMetadata);


    const completePayment = () => {
        openPopup(dispatch, "legal_review");
    }

    const scheduleCall = () => {
        dispatch(setShowFindBestLawyer(true))
    }

    const showScheduledcall = () => {
        dispatch(setShowLegalReviewRequestStatus(true))
    }

    const showLaywerDetails = () => {
        dispatch(setShowLegalReviewRequestStatus(true))
    }

    const viewReviewDetails = () => {
        dispatch(setShowLegalReviewCompletedModal(true))
    }

    const getPageData = (legalReviewRequest) => {
        // debugger;
        switch (legalReviewRequest?.status) {
            case ReviewStatus.pending_payment:
                setPageData({
                    "icon": <div className="flex items-center justify-center my-auto w-[40px] h-[40px] bg-[#fbf6e8] rounded-full" id="content"><CreditCard size={24} className='text-[#DCA218]' /></div>,
                    "iconColor": "#DCA218",
                    "iconBgColor": "#ECF5F3CC",
                    "textLine1": "Complete the payment to start the lawyer review process",
                    "textLine2": "Your payment of $49.00 for the lawyer review credits is pending",
                    "cta": "Proceed to pay",
                    "cta_action": completePayment
                });
                break;
            case ReviewStatus.rejected_by_lawyer:
                setPageData({
                    "icon": <div className="flex items-center justify-center my-auto w-[40px] h-[40px] bg-[#fbf6e8] rounded-full" id="content"><CreditCard size={24} className='text-[#DCA218]' /></div>,
                    "iconColor": "#DCA218",
                    "iconBgColor": "#ECF5F3CC",
                    "textLine1": "Complete the payment to start the lawyer review process",
                    "textLine2": "Your payment of $49.00 for the lawyer review credits is pending",
                    "cta": "Proceed to pay",
                    "cta_action": completePayment
                });
                break;
            case ReviewStatus.submitted_legal_review:
                // var lawyer_submission_metadata = {
                //     "comments": "some message",
                //     "lr_submitted_by_lawyer_at": "2021-10-12T10:00:00.000Z",
                //     "future_work": "some future work",
                //     "rating": "red/yellow/green"
                // }
                var message = ` on ${new Date(legalReviewRequest?.lawyer_submission_metadata?.lr_submitted_by_lawyer_at).toLocaleString('en-US', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true
                })}`;
                setPageData({
                    "icon": <div className="flex items-center justify-center my-auto w-[40px] h-[40px] bg-[#fbf6e8] rounded-full" id="content"><Briefcase size={24} className='text-[#DCA218]' /></div>,
                    "iconColor": "#DCA218",
                    "iconBgColor": "#fbf6e8",
                    "textLine1": "Review Completed",
                    "textLine2": message,
                    "cta": "View Lawyer Review",
                    "cta_action": viewReviewDetails
                });
                break;
            case ReviewStatus.accepted_by_lawyer:
                var lawyer = userMetadata?.lawyer_info?.name || "Your lawyer";
                var message = `${lawyer} accepted your request on ${new Date(legalReviewRequest.lr_accepted_by_lawyer_at).toLocaleString('US', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true
                })}`;
                setPageData({
                    "icon": <div className="flex items-center justify-center my-auto w-[40px] h-[40px] bg-[#fbf6e8] rounded-full" id="content"><Briefcase size={24} className='text-[#18475A]' /></div>,
                    "iconColor": "#18475A",
                    "iconBgColor": "#ECF5F3CC",
                    "textLine1": "Lawyer has accepted to review the task",
                    "textLine2": message,
                    "cta": "View Review Details",
                    "cta_action": showLaywerDetails
                });
                break;
            case ReviewStatus.accepted_by_inhouse:
                var message = `Scheduled a call with legal support team on ${new Date(legalReviewRequest.lr_accepted_by_inhouse_at).toLocaleString('en-US', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true
                })}`;
                setPageData({
                    "icon": <div className="flex items-center justify-center my-auto w-[40px] h-[40px] bg-[#f0f7f5] rounded-full" id="content"><Briefcase size={24} className='text-[#18475A]' /></div>,
                    "iconColor": "#18475A",
                    "iconBgColor": "#f0f7f5",
                    "textLine1": "Lawyer Review Requested",
                    "textLine2": message,
                    "cta": "View Details",
                    "cta_action": showScheduledcall
                });
                break;
            case ReviewStatus.pending_lawyer_matching:
                var textLine1, textLine2, cta, cta_action;
                if (userMetadata?.lawyer_matching_meeting_at) {
                    // call is scheduled
                    textLine1 = "Lawyer Review Requested"
                    textLine2 = `Scheduled a call with legal support team on ${new Date(userMetadata?.lawyer_matching_meeting_at).toLocaleString('en-US', {
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                    })}`
                    cta = "View Details"
                    cta_action = showScheduledcall
                }
                else {
                    // call is not yet scheudled
                    textLine1 = "Schedule a call to find the lawyer that matches your needs"
                    textLine2 = "Our customer team will help you find the best lawyer for your specific needs"
                    cta = "Schedule Call"
                    cta_action = scheduleCall
                }
                setPageData({
                    "icon": <div className="flex items-center justify-center my-auto w-[40px] h-[40px] bg-[#fbf6e8] rounded-full" id="content"><CalendarDots size={24} className='text-[#DCA218]' /></div>,
                    "iconColor": "#DCA218",
                    "iconBgColor": "#fbf6e8",
                    "textLine1": textLine1,
                    "textLine2": textLine2,
                    "cta": cta,
                    "cta_action": cta_action
                });
                break;
            case ReviewStatus.not_requested:
                setPageData({
                    "icon": Briefcase,
                    "iconColor": "#18475A",
                    "iconBgColor": "#ECF5F3CC",
                    "textLine1": "Legal Review Not Requested",
                    "textLine2": "Expected Date",
                    "cta": "View Review Details",
                    "cta_action": completePayment
                });
                break;
            default:
                setPageData({})
        }
    }

    useEffect(() => {
        if (lawyerHubData) {
            // debugger;
            var legalReview = lawyerHubData?.find((item) => item.thread_id === threadData.id);
            setLegalReviewRequest(legalReview);
            getPageData(legalReview)
            dispatch(setCurrentPageLegalReviewStatus(legalReview))
        }
    }, [lawyerHubData]) // eslint-disable-line


    return (
        <>
            <div className="flex flex-row justify-between bg-white gap-[12px] border p-[24px] rounded-[12px] my-[12px]">
                <div className="flex flex-row gap-[12px] w-[528px] my-auto" id="left-side">
                    {pageData?.icon}
                    <div className="flex flex-col gap-[4px] my-auto" id="icon">
                        <p className="text-[#333333] text-[14px] leading-[21px] font-[700]">{pageData?.textLine1}</p>
                        <p className="text-[#686868] text-[14px] leading-[19.6px] font-[500] ">{pageData?.textLine2}</p>
                    </div>
                </div>
                <div id="right-side" className="my-auto">
                    <p className="my-auto text-[#18475A] font-[700] text-[14px] leading-[19.6px] underline cursor-pointer" onClick={pageData?.cta_action}>{pageData?.cta}</p>
                </div>
            </div>
        </>
    );
}

export default LegalReviewMessagePrompt;