import React from 'react'
import InHouseLogoImg from '../../../images/inhouse-logo-v2.png'

const Footer = () => {
  return (
    <div className='flex flex-col items-center justify-center'>
        <img alt='inhouse logo' className='w-[96px] h-[40px] object-contain' src={InHouseLogoImg}/>
        <div className='flex justify-center gap-6 mt-4'>
          <a className='text-[#18475A] text-[10px] font-medium'>Terms & Coditions</a>
          <a className='text-[#18475A] text-[10px] font-medium'>Privacy Policy</a>
        </div>
        <p className='text-[#686868] text-[10px] font-medium mt-3 mb-6'>© Inhouse AI, Inc. All rights reserved.</p>
    </div>
  )
}

export default Footer