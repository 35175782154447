import React, { useEffect, useRef, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { useUser } from "@clerk/clerk-react"
import { useNavigate } from "react-router-dom";
import BaseLayout from "../layouts/BaseLayout.js";

import RightArrowImage from "../../images/icons/arrow-right.svg";
// import { toast } from 'react-toastify';


import TopNav from "../chatpage/Navs/TopNav.js";

import {
    setDraftModalDraftType,
    setShowExploreMoreModal
} from "../../modalSlice.js";
import { Briefcase, ChatsCircle, City, FileMagnifyingGlass, Handshake, PenNib, Receipt, Wallet } from "@phosphor-icons/react";
import { getEducationMetadata, setEmail } from "./HomeSlice.js";
import ExploreMoreModalComponent from "../../components/chat/Modals/ExploreMoreModal/index.js";
// import { getCheckoutUrl } from "../chatpage/chatSlice.js";
import { setShowUpgradePlanNudge } from "../onboarding/onboardingSlice.js";
import { openPopup } from "src/components/DocumentViewer/helpers.js";
import { trackAnalytics } from "../helpers.js";
import { PLANS } from "src/components/Constants.js";

const Homepage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const open_draft = queryParams.get('open_draft');
    const open_membership = queryParams.get('open_membership');
    const [selectedCategory, setSelectedCategory] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [categories, setCategories] = useState([]);

    const { isLoaded, user } = useUser();

    const getUserDetailsStatus = useSelector((state) => state.auth.getUserDetailsStatus);
    const inhouseUser = useSelector((state) => state.auth.user);
    const email = useSelector((state) => state.homeslice.email);
    const sidenavCollapsed = useSelector((state) => state.homeslice.sidenavCollapsed);
    const allSuggestions = useSelector(state => state.homeslice.allSuggestions);
    const draftTypeSuggestions = useSelector((state) => state.homeslice.draftTypes);
    const userMetadata = useSelector((state) => state.onboarding.userMetadata);
    const educationMetadataFetchStatus = useSelector((state) => state.homeslice.educationMetadataFetchStatus);


    useEffect(() => {
        if (allSuggestions && allSuggestions.categories?.length > 0) {
            var cats = allSuggestions.categories;
            setCategories(cats);
            setSelectedCategory(cats[0]?.id);
        }
    }, [allSuggestions]); // eslint-disable-line

    useEffect(() => {
        if (open_membership) {
            dispatch(setShowUpgradePlanNudge(true));
        }
    }, [open_membership])

    useEffect(() => {
        getSelectedCategorySuggestions();
    }, [selectedCategory]); // eslint-disable-line

    useEffect(() => {
        getSelectedCategorySuggestions();
        trackAnalytics("dashboard_home_viewed", { "user_email": email });
    }, []); // eslint-disable-line

    useEffect(() => {
        if (user) {
            if (!user?.firstName || !user?.unsafeMetadata?.organization_name) {
                navigate('/onboarding')
            }
            dispatch(setEmail(user.primaryEmailAddress.emailAddress));
        }
    }, [inhouseUser, user]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (open_draft) {
            openDraftDocumentModal("homepage");
            navigate("/")
        }
    }, [open_draft]); // eslint-disable-line    


    useEffect(() => {
        if (educationMetadataFetchStatus === "failed") {
            dispatch(getEducationMetadata())
        }
    }, [educationMetadataFetchStatus]); // eslint-disable-line

    const openDraftDocumentModal = (source, draft) => {
        trackAnalytics(`Draft document from ${source}`, { "user_email": email });
        // window.analytics.track(`Draft document from ${source}`, { "user_email": email });
        dispatch(setDraftModalDraftType(draft))
    }

    const getSelectedCategorySuggestions = () => {
        var allExamples = [];
        var category = selectedCategory
        if (!category && allSuggestions.categories && allSuggestions.categories.length > 0) {
            category = allSuggestions.categories?.find(category => category.name === "IP")?.id;
            setSelectedCategory(category);
        }
        if (category && allSuggestions.categories && allSuggestions.categories.length > 0) {
            ["ask", "draft"].forEach(type => {
                var st = allSuggestions[type + "_examples"].map(value => ({ ...value, sort: Math.random(), type: type }))
                // var examples = allSuggestions[type + "_examples"].filter(suggestion => suggestion.type === type);
                allExamples = allExamples.concat(st.filter(suggestion => suggestion.category_id === category));
            });

            var newExamples = allExamples.sort((a, b) => a.sort - b.sort).map(value => value)
            setSuggestions(newExamples.slice(0, 5));
        }
    }

    const exploreAllClicked = () => {
        trackAnalytics("Explore all clicked (homepage)", { "user_email": email });
        // window.analytics.track("Explore all clicked (homepage)", { "user_email": email });
        dispatch(setShowExploreMoreModal(true));
    }


    const getCircle = (type) => {
        switch (type) {
            case "ask":
                return (<div>
                    <ChatsCircle size={16} className="text-[#C61DCA]" />
                </div>)

            case "draft":
                return (<div>
                    <PenNib size={16} className="text-[#13866F]" />
                </div>);
            case "review":
                return (<div>
                    <FileMagnifyingGlass size={16} className="text-[#949494]" />
                </div>);
            default:
                return "#C61DCA";
        }
    }
    const getIcon = (type, bold = false) => {
        var realType = `${type}${bold ? "-bold" : ""}`
        switch (realType) {
            case "ask":
                return (
                    <ChatsCircle size={16} className="text-[#C61DCA]" />
                )

            case "draft":
                return (
                    <PenNib size={16} className="text-[#13866F]" />
                );
            case "review":
                return (
                    <FileMagnifyingGlass size={16} className="text-[#E79571]" />
                );
            case "briefcase":
                return (<Briefcase size={20} className="text-[#686868]" />)

            case "briefcase-bold":
                return <Briefcase size={20} className="text-[#686868]" weight='bold' />

            case "city":
                return <City size={20} className="text-[#686868]" />
            case "city-bold":
                return <City size={20} className="text-[#686868]" weight="bold" />
            case "wallet":
                return <Wallet size={20} className="text-[#686868]" />
            case "wallet-bold":
                return <Wallet size={20} className="text-[#686868]" weight='bold' />
            case "handshake":
                return <Handshake size={20} className="text-[#686868]" />
            case "handshake-bold":
                return (<Handshake size={20} className="text-[#686868]" weight='bold' />)
            case "receipt":
                return (<Receipt size={20} className="text-[#686868]" />)
            case "receipt-bold":
                return (<Receipt size={20} className="text-[#686868]" weight='bold' />)
            default:
                return "#C61DCA";
        }
    }

    const openAskPage = (message) => {
        navigate("/ask", { state: { message: message } });
    }
    const goToSomePlace = (suggestion) => {
        if (userMetadata?.subscription_type !== PLANS.SUBSCRIBER_ENTERPRISE) {
            if (userMetadata?.threads_remaining === 0) {

                openPopup(dispatch, "upgrade_plan");
                return;
            }
        }
        switch (suggestion.type) {
            case "ask":
                openAskPage(suggestion?.actual_prompt);
                trackAnalytics("dashboard_home_get_advice", { "user_email": email });
                break;
            case "draft":
                trackAnalytics("dashboard_home_draft_new", { "user_email": email });
                var draft = draftTypeSuggestions?.find(draft => draft.id === suggestion?.draft_metadata_id);
                if (draft) {
                    openDraftDocumentModal("home page tiles", draft);
                }
                else {
                    openDraftDocumentModal("home page tiles", suggestion?.display_prompt);
                }
                break;
            case "review":
                openDraftDocumentModal();
                break;
            default:
                break;
        }
    }

    const retryEducationMetaFetch = () => {
        trackAnalytics("Retry education meta fetch", { "user_email": email });
        // window.analytics.track("Retry education meta fetch", { "user_email": email });
        dispatch(getEducationMetadata());
    }

    return (
        <>
            {isLoaded ? (
                <BaseLayout>
                    <div className="flex flex-col w-full h-screen overflow-y-scroll">
                        <div className={`flex flex-col w-full h-auto md:h-[60%] mx-auto pb-[32px] ${sidenavCollapsed ? ' max-w-[1376px] ' : 'max-w-[1256px]'}`}>
                            <TopNav page="home"></TopNav>
                            <div className={`w-full mx-auto gap-[24px] flex flex-col ${sidenavCollapsed ? 'px-[60px]' : 'px-[20px]'}`}>
                                <div className={`${sidenavCollapsed ? 'w-[1248px]' : " w-[1134px]"} mx-auto gap-[8px] flex flex-col`}>
                                    <h1 className="text-[#686868] text-[20px] leading-[28px] font-[500]">Hello{user?.fullName ? ` ${user.firstName},` : ","}</h1>
                                    <p className="text-[32px] leading-[44.8px] font-[Lora] italic font-[700] text-[#18475A]">How can I help you today?</p>
                                </div>
                                <div className={`flex flex-row gap-[24px] justify-between mx-auto ${sidenavCollapsed ? 'w-[1248px]' : " w-[1134px]"}`}>
                                    {/* ask */}
                                    <div className={`border-[#E4E4E4] border flex flex-col h-[240px] bg-white  pl-[16px] pr-[20px] py-[20px] rounded-[4px] gap-[20px] cursor-pointer ${sidenavCollapsed ? 'w-[400px]' : 'w-[362px]'}`}
                                        onClick={() => { trackAnalytics("dashboard_home_ask_tab_clicked", { "user_email": email }); goToSomePlace({ "type": "ask" }) }}>
                                        <div className="">
                                            <div className="bg-[#C61DCA14] rounded-[50px] p-[8px] w-[40px] h-[40px] gap-[10px]">
                                                <ChatsCircle size={24} weight="fill" className="text-[#C61DCA]" />
                                            </div>
                                        </div>
                                        <div className="gap-[8px] h-[88px] p-0 flex flex-col">
                                            <p className="text-[24px] leading-[33.6px] text-[#141414] font-[500]">Ask Inhouse AI</p>
                                            <p className="text-[#686868] w-[311px] text-[18px] font-[400] leading-[23.4px] ">Get Instant advice on any legal issues</p>
                                        </div>
                                        <div className="flex justify-end w-full">
                                            <img src={RightArrowImage} alt="->" />
                                        </div>
                                    </div>
                                    {/* draft */}
                                    <div className={`border-[#E4E4E4] border flex flex-col h-[240px] bg-white  pl-[16px] pr-[20px] py-[20px] rounded-[4px] gap-[20px] cursor-pointer ${sidenavCollapsed ? 'w-[400px]' : 'w-[362px]'}`}
                                        onClick={() => { trackAnalytics("dashboard_home_draft_tab_clicked", { "user_email": email }); goToSomePlace({ "type": "draft" }) }}>
                                        <div className="">
                                            <div className="bg-[#13866F14] rounded-[50px] p-[8px] w-[40px] h-[40px] gap-[10px]">
                                                <PenNib size={24} weight="fill" className="text-[#13866F]" />
                                            </div>
                                        </div>
                                        <div className="gap-[8px] h-[88px] p-0 flex flex-col">
                                            <p className="text-[24px] leading-[33.6px] text-[#141414] font-[500]">Draft Legal Document</p>
                                            <p className="text-[#686868] w-[311px] text-[18px] font-[400] leading-[23.4px]">Generate AI-powered legal docs with lawyer-level quality</p>
                                        </div>
                                        <div className="flex justify-end w-full">
                                            <img src={RightArrowImage} alt="->" />
                                        </div>
                                    </div>
                                    {/* review */}
                                    <div className={`border-[#E4E4E4] cursor-not-allowed select-none border flex flex-col h-[240px]  bg-white  pl-[16px] pr-[20px] py-[20px] rounded-[4px] gap-[20px] ${sidenavCollapsed ? 'w-[400px]' : 'w-[362px]'}`}
                                        onClick={() => { trackAnalytics("dashboard_home_review_tab_clicked", { "user_email": email }); }}
                                    >
                                        <div className="flex items-center justify-between">
                                            <div className="bg-[#141414]/10 rounded-[50px] p-[8px] w-[40px] h-[40px] gap-[10px]">
                                                <FileMagnifyingGlass size={24} weight="fill" className="text-[#141414]/50" />
                                            </div>
                                            <p className="text-lg font-medium text-[#141414]/50 text-gray-500">Coming Soon</p>
                                        </div>
                                        <div className="gap-[8px] h-[88px] p-0 flex flex-col">
                                            <p className="text-[24px] leading-[33.6px] text-[#141414]/50 font-[500]">Scan for Red Flags</p>
                                            <p className="text-[#141414]/50 w-[311px] text-[18px] font-[400] leading-[23.4px]">Review legal documents for potential risks and red flags</p>
                                        </div>
                                        <div className="flex justify-end w-full opacity-50">
                                            <img src={RightArrowImage} alt="->" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {categories.length > 0 && (
                            <div className={`${sidenavCollapsed ? 'md:w-[1376px]' : "md:w-[1262px]"} lg:w-full flex flex-col h-full bg-white pb-[64px] pt-[24px] gap-[24px]`}>
                                <div className={`w-full mx-auto flex flex-col gap-[20px] ${sidenavCollapsed ? 'px-[60px]' : 'px-[20px]'}`}>
                                    <div className={`${sidenavCollapsed ? 'w-[1248px]' : " w-[1134px]"} mx-auto`}>
                                        <p className="text-[18px] leading-[28.8px] font-[600] flex flex-row gap-[8px]">
                                            {/* <span className="my-auto"><Lightning size={24} className="text-[#B6B6B6]" /></span> */}
                                            <span className="my-auto text-[#264653]">Explore Prompts tailored for you</span>
                                        </p>
                                    </div>
                                    <div className={`${sidenavCollapsed ? 'w-[1248px]' : " w-[1134px]"} mx-auto flex flex-row gap-[8px] h-[50px]`}>
                                        {categories.length > 0 && (<>
                                            {categories?.map((key) => (
                                                <button className={`flex flex-row border gap-[8px] rounded-[98px] px-[24px] py-[8px] my-auto text-[12px] leading-[14px] ${selectedCategory === key.id ? 'font-[600] !border-[#748A8C] bg-[#FCFCFA]' : "font-[400]"} border-[#E4E4E4]`}
                                                    onClick={() => { trackAnalytics(`dashboard_home_${key?.name?.toLowerCase()}`, { "user_email": email }); setSelectedCategory(key.id) }}>
                                                    <span className={`my-auto ${selectedCategory === key.id ? "text-[#141414]" : "text-[#686868]"}`}>{getIcon(key.icon_name, selectedCategory === key.id ? true : false)}</span>
                                                    <span className={`my-auto text-[14px] leading-[21px] ${selectedCategory === key.id ? 'font-[600] text-[#141414]' : 'text-[#686868] font-[400]'}`}>{key.name}</span>
                                                </button>
                                            ))}
                                        </>)}

                                    </div>
                                </div>
                                <div className={`w-full mx-auto flex flex-col gap-[20px] ${sidenavCollapsed ? 'px-[60px]' : 'px-[20px]'}`}>
                                    <div className={`${sidenavCollapsed ? 'w-[1248px]' : " w-[1134px]"} mx-auto flex  gap-[24px]`}>
                                        {suggestions.length > 0 && (<>
                                            {suggestions.map((suggestion, index) => (
                                                <div className="flex flex-col h-[100%] border px-[16px] py-[24px] cursor-pointer rounded-[8px] w-[236.8px] gap-[10px] bg-[#FCFCFA]"
                                                    key={index} onClick={() => { trackAnalytics("dashboard_home_tailored_suggestions", { "user_email": email }); goToSomePlace(suggestion) }}
                                                >
                                                    {getCircle(suggestion.type)}
                                                    <p className="text-[#353535] text-[14px] leading-[21px] font-[500]">{suggestion.display_prompt} </p>
                                                </div>
                                            ))}
                                        </>)}
                                    </div>
                                    <div className={`${sidenavCollapsed ? 'w-[1248px]' : " w-[1134px]"} mx-auto flex justify-end gap-[24px]`}>
                                        <p className="py-[12px] text-[14px] leading-[17.5px] font-[Helvetica Neue] font-[500] text-[#18475A] rounded cursor-pointer"
                                            onClick={() => { trackAnalytics("dashboard_home_tailored_suggestions_explore", { "user_email": email }); exploreAllClicked() }}>SEE MORE</p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {categories.length === 0 && educationMetadataFetchStatus === "success" && (
                            <div className="flex flex-col w-full h-full bg-white py-[64px] gap-[40px]">
                                <p className="text-center w-full">Failed to load suggestions</p>
                                <p className="text-center w-full">
                                    <button className="rounded-[8px] border bg-[#264653] text-white px-[12px] py-[8px]"
                                        onClick={retryEducationMetaFetch}>
                                        <span>Retry</span>
                                    </button>
                                </p>
                            </div>
                        )}
                    </div>
                    {/* 
                    <DraftDocumentQuestionaireModal
                        email={email}
                        ref={draftDocumentModalRef} /> */}

                    <ExploreMoreModalComponent />
                    {/* <SubscriptionModal
                        user={inhouseUser}
                    // userAction={userAction}
                    /> */}

                    {/* <RetainerModal
                    // retainermodalOpen={retainermodalOpen}
                    // setRetainerModalOpen={setRetainerModalOpen}
                    // chatId={chatId}
                    // subscriptionType={subscriptionType}
                    // getSharedId={getSharedId}
                    // handleCopy={handleCopy}
                    />

                    <SubscriptionStatusRefreshModal
                    // open={showSubscriptionStatusRefreshModal} user={inhouseUser} 
                    /> */}
                </BaseLayout >
            ) : <>
                {
                    (isLoaded && getUserDetailsStatus === "failed") ?
                        <div className="flex items-center justify-center w-full h-full">
                            <p className="text-red-500">Failed to load user details. Please refresh the page.</p>
                        </div> :
                        <div className="w-full h-full flex justify-center items-center mx-auto mt-[200px]">
                            {/* <div className="spinner-grow spinner-grow-sm text-primary me-3" role="status"></div> */}
                            {/* <div class="rotating-cursor !h-[50px]"></div> */}
                            {/* <p className="text-black text-[40px]">{isLoaded ? "true" : "false"} .... first name = {user?.firstName}</p> */}
                        </div>
                }
            </>}
        </>
    );
};

export default Homepage;
