import { ArrowLeft, ArrowRight, FileText, PencilSimpleLine, Plus, Trash } from '@phosphor-icons/react';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const BrowseAll = (props) => {

    const { draftTypeSuggestions, documentType, closeBrowseAll, draftTypeSelectedFromBrowseAll } = props;
    const [categories, setCategories] = useState([]);
    const [selectedType, setSelectedType] = useState("All");
    const [results, setResults] = useState([]);

    useEffect(() => {
        var cats = ["All"];
        draftTypeSuggestions.forEach((suggestion) => {
            if (!cats.includes(suggestion?.category)) {
                cats.push(suggestion?.category);
            }
        });
        setCategories(cats);
        setResults(draftTypeSuggestions)
    }, [draftTypeSuggestions]);

    useEffect(() => {
        if (selectedType === "All") {
            setResults(draftTypeSuggestions);
        }
        else {
            setResults(draftTypeSuggestions.filter(suggestion => suggestion?.category === selectedType));
        }
    }, [selectedType]); // eslint-disable-line react-hooks/exhaustive-deps


    return (

        <>
            <div className="flex flex-col gap-[18px] py-[18px] w-full  mx-auto my-auto">
                <div className="flex w-[860px] flex-row border-b gap-[12px] py-[20px] px-[41px]">
                    <button
                        className=""
                        onClick={() => { closeBrowseAll() }}
                    >
                        <ArrowLeft size={24} className=" my-auto" weight="bold" />
                    </button>
                    <div className='flex flex-col gap-[4px]'>
                        <p className="text-[24px] font-[600] leading-[33.6px] font-[Lora] italic">Explore All Legal Documents</p>
                    </div>
                </div>
                {/* <div className='px-[24px] flex flex-row gap-[8px] h-[50px]'>
                    {categories.map((category, index) => (
                        <button className={`border rounded-[12px] px-[24px] h-[30px] my-auto text-[12px] leading-[14px] ${selectedType === "All" ? 'font-[600] text-black !border-[#000]' : "font-[400]"} text-[#686868]`}
                            onClick={() => setSelectedType(category)}>
                            {category}
                        </button>
                    ))}
                </div> */}

                <div className="grid grid-cols-3 w-[748px] mx-auto gap-[12px]">
                    {results.map((suggestion, index) => (
                        <div className={`flex flex-col border px-[16px] py-[24px] rounded-[4px] w-[241.33px] gap-[10px] cursor-pointer ${documentType?.id === suggestion?.id ? "!border-[#248370] text-[#000] bg-[#ECF5F3]" : "border-[#E4E4E4]"}`}
                            key={index}
                            onClick={() => draftTypeSelectedFromBrowseAll({ "suggestion": suggestion, "index": index })}
                        >
                            <p className="text-[#000] text-[14px] leading-[24px] font-[500]">{suggestion?.name} </p>
                            <p className="text-[#686868] text-[12px] leading-[24px] font-[400]">{suggestion?.description} </p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default BrowseAll;