import { ArrowUp, FileText, Info, Paperclip, Stop, Trash } from "@phosphor-icons/react";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import applicationStore from "../../../persistence/stores/RootStore.js";
import { trackAnalytics } from 'src/pages/helpers.js';

const ChatBoxComponent = ({
    size,
    allowAttachReference = false,
    sentences = null,
    inputMessage = "",
    setInputMessage,
    onsubmit,
    handleFileChange,
    percentage,
    referenceDoc,
    setReferenceDoc,
    fileuploadSuccess,
}) => {
    // size can have two values: half or full depending on whether it's a split screen or full screen

    const dispatch = useDispatch();
    const messagingDisabled = useSelector((state) => state.messageSlice.threadData.messagingDisabled);
    const chatInputMessage = useSelector((state) => state.messageSlice.chatInputMessage);
    const newThreadMessage = useSelector((state) => state.messageSlice.newThreadMessage);
    const email = useSelector(state => state.homeslice.email);

    if (!sentences) {
        if (allowAttachReference) {
            sentences = ["Ask me any legal questions here – feel free to attach a reference if it helps"];
        } else {
            sentences = ["Ask me any follow up questions"];
        }
    }

    useEffect(() => {
        if (chatInputMessage === "") {
            setInputMessage("");
        }
    }, [chatInputMessage]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (newThreadMessage === "") {
            setInputMessage("");
        }
    }, [newThreadMessage]); // eslint-disable-line react-hooks/exhaustive-deps

    const [activeSentenceIndex, setActiveSentenceIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveSentenceIndex((prevIndex) => (prevIndex + 1) % sentences.length);
        }, 3000);

        return () => clearInterval(interval);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [typedText, setTypedText] = useState("");

    useEffect(() => {
        const activeSentence = sentences[activeSentenceIndex];

        let currentText = "";
        let currentIndex = 0;

        const typingInterval = setInterval(() => {
            const messagingDisabled = applicationStore.getState().messageSlice.threadData.messagingDisabled;
            if (messagingDisabled) {
                setTypedText("Messaging is disabled");
            } else {
                if (currentIndex < activeSentence.length) {
                    currentText += activeSentence[currentIndex];
                    setTypedText(currentText);
                    currentIndex++;
                } else {
                    clearInterval(typingInterval);
                }
            }
        }, 70);

        return () => clearInterval(typingInterval);
    }, [activeSentenceIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className={`${size === "half"
                ? `chatbox-half ${allowAttachReference ? "h-[170px]" : "h-[154px]"}`
                : `chatbox-full  ${allowAttachReference ? "h-[150px]" : "h-[140px]"}`
                } 
                group flex flex-col gap-[10px] py-[16px] `}
        >
            <div
                className={`flex ${allowAttachReference ? "flex-col" : ""
                    } items-start space-x-2 input-group-area group w-full gap-[12px] py-[16px] pl-[16px] pr-[24px] border focus-within:!border-black bg-white rounded-[16px] `}
            >
                <TextArea
                    autoSize={{
                        minRows: 1,
                        maxRows: 6,
                    }}
                    //  rows={5}
                    value={inputMessage}
                    disabled={messagingDisabled}
                    onChange={(e) => setInputMessage(e.target.value)}
                    onKeyDown={(e) => {
                        if (
                            e.key === "Enter" &&
                            (e.ctrlKey || e.metaKey) &&
                            !e.shiftKey &&
                            (e.target.tagName !== "TEXTAREA" || document.activeElement === e.target)
                        ) {
                            onsubmit();
                        }
                    }}
                    className="focus:!border-0 focus:!shadow-none form-control border-0 disabled:bg-white disabled:!border-0 disabled:!shadow-none placeholder-[#949494]"
                    placeholder={typedText}
                />
                <div
                    className={`${allowAttachReference ? "mt-[12px] flex-row w-full justify-between" : "self-end"
                        } button-box align-bottom flex  gap-[12px]`}
                >
                    {allowAttachReference && (
                        <>
                            {!referenceDoc && (
                                <div>
                                    <label htmlFor="file-upload" className="cursor-pointer file-upload-button">
                                        <div className="border-[#D1D1D1] border bg-[#FAFAFA] gap-[4px] rounded-[11px] w-[155px] h-[40px] flex py-[8px] pl-[8px] pr-[12px]">
                                            <Paperclip className="text-[#011513] my-auto" size={18} />
                                            <span className="text-[#011513] text-[14px] my-auto">Attach reference</span>
                                        </div>
                                    </label>
                                    <input
                                        id="file-upload"
                                        type="file"
                                        accept=".pdf,.doc,.docx"
                                        onChange={handleFileChange}
                                        style={{ display: "none" }}
                                        disabled={messagingDisabled}
                                    />
                                </div>
                            )}
                            {referenceDoc && (percentage !== 100) && (
                                // {true && (
                                <div>
                                    <div className="border-[#D1D1D1] flex flex-row border bg-[#FAFAFA] rounded-[11px] h-[40px]">
                                        <span className="bg-[#FAFAFA] h-[52px] w-[60px] border flex pl-[8px] pr-[12px] rounded-l-[11px] my-auto mx-auto">
                                            <FileText className="text-[#011513] my-auto mx-auto" size={18} />
                                        </span>
                                        <div className="bg-[#fff] flex flex-row justify-between w-[333px] h-[52px]  pl-[8px] border pr-[12px] rounded-r-[11px]">
                                            <span className="text-[#011513] bg-[#fff] text-[14px] my-auto">uploading reference</span>
                                            <div className="flex flex-row gap-[8px]">
                                                <div className="relative w-6 h-6 my-auto">
                                                    <svg className="absolute inset-0" viewBox="0 0 36 36">
                                                        <path
                                                            className="text-gray-300"
                                                            d="M18 2.0845
                                                            a 15.9155 15.9155 0 0 1 0 31.831
                                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="4"
                                                        />
                                                        <path
                                                            className="text-blue-600"
                                                            d="M18 2.0845
                                                            a 15.9155 15.9155 0 0 1 0 31.831"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="4"
                                                            strokeDasharray={`${percentage}, 100`}
                                                        />
                                                    </svg>
                                                </div>
                                                <span className="my-auto"> {percentage}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {referenceDoc && (percentage === 100) && (
                                <div>
                                    <div className="border-[#D1D1D1] flex flex-row border bg-[#FAFAFA] rounded-[11px] h-[40px]">
                                        <span className="bg-[#FAFAFA] h-[52px] w-[60px] border flex pl-[8px] pr-[12px] rounded-l-[11px] my-auto mx-auto">
                                            <FileText className="text-[#011513] my-auto mx-auto" size={18} />
                                        </span>
                                        <div className="bg-[#fff] flex flex-row justify-between gap-[24px] h-[52px]  pl-[8px] border pr-[12px] rounded-r-[11px]">
                                            <span className="w-[200px] overflow-x-hidden text-[#011513] bg-[#fff] text-[14px] my-auto cursor-default">
                                           {referenceDoc.name.length>25?"..."+referenceDoc.name.slice(-25):referenceDoc.name} 
                                            </span>
                                            <span className="my-auto">
                                                <Trash
                                                    size={24}
                                                    className="text-[#A20009] my-auto cursor-pointer"
                                                    onClick={() => {
                                                        setReferenceDoc(null);
                                                    }}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    <button
                        className={`bg-[#000] disabled:bg-[#949494]  rounded-[8px] w-[40px] ${referenceDoc ? "h-[52px]" : "h-[40px]"
                            }`}
                        disabled={messagingDisabled || inputMessage?.length < 3 || (referenceDoc && percentage !== 100)}
                        onClick={onsubmit}
                    >
                        {messagingDisabled ? (
                            <Stop size={18} className="bg-white text-white mx-auto" />
                        ) : (
                            <ArrowUp size={24} className="text-white mx-auto" />
                        )}
                    </button>
                </div>
            </div>
            {size === "half" && (
                <p className="flex flex-row text-center text-[14px] leading-[19.6px] font-[500] text-[#7F8C8D] gap-[10px] justify-center">
                    <Info className="text-[#7F8C8D] my-[2px]" size={16} />
                    <span>
                    AI is not a substitute for a lawyer and may make mistakes. Always consult your InHouse lawyer before acting on its suggestions. See our{" "}
                        <span onClick={() => { trackAnalytics("dashboard_privacy_policy_button_clicked", { "user_email": email }) }}>
                            <a
                                href="https://storage.googleapis.com/inhouse-public-documents/InHouse%20Privacy%20Policy.pdf"
                                target="blank"
                                className="underline"
                            >
                                {" "}
                                Privacy Policy
                            </a>{" "}
                        </span>
                        for details.
                    </span>
                </p>
            )}
            {size === "full" && (
                <p className="flex flex-row text-center text-[14px] leading-[19.6px] font-[500] text-[#7F8C8D] gap-[10px] justify-center">
                    <Info className="text-[#7F8C8D] my-auto" size={16} />
                    <span>
                    AI is not a substitute for a lawyer and may make mistakes. Always consult your InHouse lawyer before acting on its suggestions. See our{" "}
                        <span onClick={() => { trackAnalytics("dashboard_privacy_policy_button_clicked", { "user_email": email }) }}>
                            <a
                                href="https://storage.googleapis.com/inhouse-public-documents/InHouse%20Privacy%20Policy.pdf"
                                target="blank"
                                className="underline"
                            >
                                {" "}
                                Privacy Policy
                            </a>{" "}
                        </span>
                        for details.
                    </span>
                </p>
            )}
        </div>
    );
};

export default ChatBoxComponent;
