import { Briefcase, CalendarBlank, Check, Clock, EnvelopeSimple } from '@phosphor-icons/react'
import React, { useEffect } from 'react'
import Button from '../Button'
import { getLawyerHub, getUserMetadata, setShowNextSteps } from 'src/pages/onboarding/onboardingSlice'
import { useDispatch, useSelector } from 'react-redux'


const NextSteps = () => {
    const dispatch = useDispatch();
    const [steps, setSteps] = React.useState([])

    const scheduledMeetingDetails = useSelector((state) => state.onboarding.meetingDetails);
    const fetchMeetingDetailsStatus = useSelector((state) => state.onboarding.fetchMeetingDetailsStatus);
    const metadata = useSelector((state) => state.onboarding.userMetadata);

    const getsteps = () => {
        const date = `${new Date(metadata?.lawyer_matching_meeting_at).toLocaleString('en-US', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        })}`
        setSteps([
            {
                icon: <CalendarBlank className='text-[#18475A]' size={24} />,
                title: 'Scheduled chat with our legal support team',
                description: `on ${date}`
            },
            {
                icon: <EnvelopeSimple className='text-[#18475A]' size={24} />,
                title: 'Lawyer Match Confirmation',
                description: <>You'll receive an email as soon as we match you with the <br />best lawyer for your needs.</>
            },
            {
                icon: <Briefcase className='text-[#18475A]' size={24} />,
                title: 'Lawyer assignment complete ',
                description: <>Once you accept the lawyer, we assign them to you. <br /> You can start requesting legal reviews, schedule time with them</>
            }
        ])
    }

    useEffect(() => {
        if (fetchMeetingDetailsStatus === 'success' && scheduledMeetingDetails) {
            dispatch(getLawyerHub())
            dispatch(getUserMetadata())
        }
    }, [fetchMeetingDetailsStatus]) // eslint-disable-line

    useEffect(() => {
        getsteps();
    }, [metadata]) // eslint-disable-line
    return (
        <div className="gap-[20px]">
            <div className='px-10 py-10 bg-[#E4E4E44D]/30'>
                <p className='text-[#686868] text-base font-normal'>You’re all set! </p>
                <p className='text-[#18475A] text-2xl font-bold'>Here is what happens next</p>
            </div>
            <ul className='relative pl-10 pr-40'>

                {/* Items */}
                {
                    steps.map(step => (
                        <li className='flex gap-3 mt-8'>
                            <div className='bg-white border-4 border-white w-fit h-fit z-[100] rounded-full'>
                                <div className='z-[100] bg-[#13866F0A]/10 flex-none flex justify-center items-center !w-12 !h-12 rounded-full'>
                                    {step.icon}
                                </div>
                            </div>
                            <div className='flex flex-col'>
                                <h6 className='text-[#353535] font-bold text-lg'>{step.title}</h6>
                                <p className='text-base mt-1 font-normal text-[#686868]'>{step.description}</p>
                            </div>
                        </li>
                    ))
                }




            </ul>
            <div className='flex justify-end px-6 pt-6 pb-6 mt-12 border-t'>
                <Button className={'py-2'} text={'Ok, got it!'}
                    onClick={() => { dispatch(setShowNextSteps(false)) }} />
            </div>
        </div>
    )
}

export default NextSteps