import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { extractErrors } from "../helpers";
import axios from "axios";
// import { attemptAuthenticationRefresh } from "../UserAuthentication/AuthenticationSlice";

const BASE_ENDPOINT = process.env.REACT_APP_BACKEND_BASE_ENDPOINT;

export const createUserAPI = createAsyncThunk("onboarding/createUserAPI", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const response = await axios.post(`${BASE_ENDPOINT}/api/user/create`, {}, { headers: headers });
    return {
      data: data,
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const requestLegalReview = createAsyncThunk("onboarding/requestLegalReview", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const response = await axios.post(`${BASE_ENDPOINT}/api/thread/${data.threadId}/request-legal-review`, {
      "description": data.description
    }, { headers: headers });
    return {
      data: data,
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});


export const getMessageType = createAsyncThunk("onboarding/getMessageType", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const response = await axios.post(
      `${BASE_ENDPOINT}/api/v0/message/get-type/${data.message}`,
      {},
      { headers: headers }
    );
    return {
      data: data,
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const getUserMetadata = createAsyncThunk("onboarding/getUserMetadata", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const response = await axios.get(
      `${BASE_ENDPOINT}/api/user/get-metadata`,
      { headers: headers }
    );
    return {
      data: data,
      response: response.data,
    };
  } catch (error) {
    const status = error.response ? error.response.status : null;
    return thunkAPI.rejectWithValue({ error: error, status: status });
  }
});


export const getLawyerHub = createAsyncThunk("onboarding/getLawyerHub", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const response = await axios.get(
      `${BASE_ENDPOINT}/api/user/get-lawyer-hub-data`,
      { headers: headers }
    );
    return {
      data: data,
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const fetchMeetingDetails = createAsyncThunk("onboarding/fetchMeetingDetails", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const response = await axios.get(
      `${BASE_ENDPOINT}/api/user/submit-lawyer-matching-request?event_id=${data}`,
      { headers: headers }
    );
    return {
      data: data,
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});



const onboardingSlice = createSlice({
  name: "onboarding",
  initialState: {
    status: "idle",
    error: "",
    createUserAPIStatus: "idle",
    meetingDetails: {},
    userMetadata: {},
    userMetadataFetchStatus: "idle",
    userAlreadyExist: false,
    showUpgradePlanNudge: false,
    showRequestLegalReviewAside: false,
    showSubscriptionPopup: false,
    showUpgradingPlan: false,
    showBusinessPlanUnlocked: false,
    showFindBestLawyer: false,
    showMeetingConfirmation: false,
    showNextSteps: false,
    showScheduleMeeting: false,
    showReviewRequestedModal: false,
    requestLegalReviewStatus: "idle",
    requestLegalReviewFailureReason: "",
    showCheckoutModal: false,
    currentPageLegalReviewStatus: {},
    showLearnMoreModal: false,
    showLegalReviewRequestStatus: false,
    showLegalReviewCompletedModal: false,
  },
  reducers: {
    resetOnboardingStates: (state) => {
      state.status = "idle";
      state.error = "";
    },
    setShowLegalReviewRequestStatus: (state, action) => {
      state.showLegalReviewRequestStatus = action.payload;
    },
    setShowLegalReviewCompletedModal: (state, action) => {
      state.showLegalReviewCompletedModal = action.payload;
    },
    resetRequestLegalReviewFailureReason: (state) => {
      state.requestLegalReviewFailureReason = "";
      state.requestLegalReviewStatus = "idle"
    },
    setCurrentPageLegalReviewStatus: (state, action) => {
      state.currentPageLegalReviewStatus = action.payload;
    },
    setShowScheduleMeeting: (state, action) => {
      state.showScheduleMeeting = action.payload;
    },
    setShowCheckoutModal: (state, action) => {
      state.showCheckoutModal = action.payload;
    },
    setShowReviewRequestedModal: (state, action) => {
      state.showReviewRequestedModal = action.payload;
    },
    setShowUpgradePlanNudge: (state, action) => {
      state.showUpgradePlanNudge = action.payload;
    },
    setShowRequestLegalReviewAside: (state, action) => {
      state.showRequestLegalReviewAside = action.payload
    },
    setShowSubscriptionPopup: (state, action) => {
      state.showSubscriptionPopup = action.payload
    },
    setShowUpgradingPlan: (state, action) => {
      state.showUpgradingPlan = action.payload
    },
    setShowBusinessPlanUnlocked: (state, action) => {
      state.showBusinessPlanUnlocked = action.payload
    },
    setShowFindBestLawyer: (state, action) => {
      state.showFindBestLawyer = action.payload
    },
    setShowMeetingConfirmation: (state, action) => {
      state.showMeetingConfirmation = action.payload
    },
    setShowNextSteps: (state, action) => {
      state.showNextSteps = action.payload
    },
    setShowLearnMoreModal: (state, action) => {
      state.showLearnMoreModal = action.payload;
    },
    decreaseThreadsRemaining: (state) => {
      state.userMetadata = { ...state.userMetadata, threads_remaining: state.userMetadata.threads_remaining - 1 };
    },
    decreaseReviewRemaining: (state) => {
      state.userMetadata = { ...state.userMetadata, legal_review_credits_used: state.userMetadata.legal_review_credits_used + 1 };
    }
  },
  extraReducers: (builder) => {
    builder

      // create user api : this creates new user on the backend
      .addCase(createUserAPI.pending, (state) => {
        state.createUserAPIStatus = "loading";
      })
      .addCase(createUserAPI.fulfilled, (state, action) => {
        state.createUserAPIStatus = "success";
      })
      .addCase(createUserAPI.rejected, (state, action) => {
        state.createUserAPIStatus = "failed";
        var response = action.payload?.error?.request?.response;
        if (action?.payload?.error?.response?.status === 409) {
          state.userAlreadyExist = true;
        }
        else if (response && response.indexOf("User already exists with email") > -1) {
          state.userAlreadyExist = true;
        }
      })
      // getUsermetadata api : this creates new user on the backend
      .addCase(getUserMetadata.pending, (state) => {
        state.userMetadataFetchStatus = "loading";
      })
      .addCase(getUserMetadata.fulfilled, (state, action) => {
        state.userMetadataFetchStatus = "success";
        state.userMetadata = action.payload.response;
      })
      .addCase(getUserMetadata.rejected, (state, action) => {
        if (action?.payload?.status === 401) {
          state.userMetadataFetchStatus = "token-failure"
        }
        else if (action?.payload?.status === 404) {
          if (action?.payload?.error?.response?.data?.detail?.search("UserNotFoundException") > -1) {
            state.userMetadataFetchStatus = "profile-not-created"
          }
          else if (action?.payload?.error?.response?.data?.detail?.search("OnboardingIncompleteException") > -1) {
            state.userMetadataFetchStatus = "profile-not-completed"
          }
          else {
            state.userMetadataFetchStatus = "failed";
          }
        }
        else {
          state.userMetadataFetchStatus = "failed";
        }
      })

      .addCase(getLawyerHub.pending, (state) => {
        state.lawyerHubFetchStatus = "loading";
      })
      .addCase(getLawyerHub.fulfilled, (state, action) => {
        state.lawyerHubData = action.payload.response;
        state.lawyerHubFetchStatus = "success";
      })
      .addCase(getLawyerHub.rejected, (state, action) => {
        state.lawyerHubFetchStatus = "failed";
      })

      .addCase(getMessageType.pending, (state) => {
        state.getMessageTypeStatus = "loading";
        state.getMessageTypeError = "";
      })
      .addCase(getMessageType.fulfilled, (state, action) => {
        state.getMessageTypeStatus = "success";
        state.getMessageTypeError = "";
        state.messageType = action.payload.response.message_type;
        localStorage.setItem("messageType", action.payload.response.message_type);
      })
      .addCase(getMessageType.rejected, (state, action) => {
        state.getMessageTypeStatus = "failed";
        state.getMessageTypeError = action.payload?.error;
        localStorage.setItem("messageType", "ask");
      })

      .addCase(requestLegalReview.pending, (state) => {
        state.requestLegalReviewStatus = "loading";
        state.requestLegalReviewFailureReason = ""
      })
      .addCase(requestLegalReview.fulfilled, (state, action) => {
        // debugger;
        // if (action.payload.response.is_accepted) {
        var legalCreditsAvailable = state.userMetadata.legal_review_credits_total - state.userMetadata.legal_review_credits_used - 1;
        state.requestLegalReviewStatus = "success";
        state.userMetadata = {
          ...state.userMetadata,
          legal_review_credits_used: state.userMetadata.legal_review_credits_used + 1
        }
        if (state.userMetadata.lawyer_info && legalCreditsAvailable) {
          state.showReviewRequestedModal = true;
        }
        state.showRequestLegalReviewAside = false;
        // }
        // else {
        //   state.requestLegalReviewStatus = "failed";
        // }
      })
      .addCase(requestLegalReview.rejected, (state, action) => {
        if (action?.payload?.error?.request?.response) {
          state.requestLegalReviewFailureReason = JSON.parse(action?.payload?.error?.request?.response || "{}")
        }
        state.requestLegalReviewStatus = "failed";
        // if (action.payload)
      })

      .addCase(fetchMeetingDetails.pending, (state) => {
        state.fetchMeetingDetailsStatus = "loading";
      })
      .addCase(fetchMeetingDetails.fulfilled, (state, action) => {
        state.fetchMeetingDetailsStatus = "success";
        state.meetingDetails = action.payload.response;
      })
      .addCase(fetchMeetingDetails.rejected, (state, action) => {
        state.fetchMeetingDetailsStatus = "failed";
      })


  },
});

export default onboardingSlice.reducer;

export const { resetOnboardingStates, setShowUpgradePlanNudge, setShowRequestLegalReviewAside,
  setShowSubscriptionPopup,
  setShowUpgradingPlan,
  setShowBusinessPlanUnlocked,
  setShowFindBestLawyer,
  setShowMeetingConfirmation,
  setShowNextSteps,
  setShowReviewRequestedModal,
  setShowCheckoutModal,
  setCurrentPageLegalReviewStatus,
  setShowScheduleMeeting,
  decreaseThreadsRemaining,
  decreaseReviewRemaining,
  setShowLearnMoreModal,
  resetRequestLegalReviewFailureReason,
  setShowLegalReviewRequestStatus,
  setShowLegalReviewCompletedModal
} = onboardingSlice.actions;
