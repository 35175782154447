/* eslint-disable jsx-a11y/aria-role */
import React, { useEffect } from 'react'
import Modal from '../../components/Modal'
import VerticalProgress from './components/VerticalProgress'
import { ArrowUpRight, Barricade, Check, Clock, Info, Prohibit, SealCheck, XCircle } from '@phosphor-icons/react'
import Button from './Button'
import { useDispatch, useSelector } from 'react-redux'
import { ReviewStatus } from 'src/components/Constants'
import { use } from 'marked'
import { setShowLegalReviewCompletedModal, setShowLegalReviewRequestStatus } from '../onboarding/onboardingSlice'



const LegalReviewComplete = () => {
    const dispatch = useDispatch();
    const currentPageLegalReviewStatus = useSelector(state => state.onboarding.currentPageLegalReviewStatus);
    const userMetadata = useSelector((state) => state.onboarding.userMetadata);
    const showLegalReviewCompletedModal = useSelector((state) => state.onboarding.showLegalReviewCompletedModal);

    const [steps, setSteps] = React.useState([])


    const getStep1 = () => {
        return {
            stepIcon: { icon: Check, color: '#137513' },
            title: { text: 'Review Requested' },
            description: {
                text: `on ${new Date(currentPageLegalReviewStatus.lr_requested_at).toLocaleString('en-US', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true
                })}`
            },
        }
    }

    const generateStepsForSubmittedLegalReview = () => {
        var userSteps = [
            getStep1(),
            {
                stepIcon: { icon: Check, color: '#137513' },
                title: {
                    text: 'Lawyer has accepted to review the task ',
                },
                description: {
                    text: `on ${new Date(currentPageLegalReviewStatus.lr_accepted_by_lawyer_at).toLocaleString('en-US', {
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                    })}`
                },
            },
            {
                stepIcon: { icon: Clock, color: '#DCA218' },
                title: { text: 'Lawyer Review is in progress' },
                description: { text: <>{userMetadata?.lawyer_info?.name ? userMetadata?.lawyer_info?.name : "Your lawyer"} will understand your specific needs before starting the legal review. <br /> You will be promptly notified on your email ID regarding this </> },
                lawyerCard: {
                    lawyerImg: `${userMetadata?.lawyer_info?.pre_signed_image_url ? userMetadata?.lawyer_info?.pre_signed_image_url : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDpWYsLSeY1sLvwgFNwBeJGjszUfEofDpwJw&s'}`,
                    name: userMetadata?.lawyer_info?.name ? userMetadata?.lawyer_info?.name : "Your lawyer",
                    phone: userMetadata?.lawyer_info?.phone_number ? userMetadata?.lawyer_info?.phone_number : "-",
                    email: userMetadata?.lawyer_info?.email ? userMetadata?.lawyer_info?.email : "-",
                }
            }
        ]
        return userSteps
    }

    const getLeadingText = () => {
        if (currentPageLegalReviewStatus?.lawyer_submission_metadata?.rating === 'green') {
            return <p className='text-[16px] font-[600] leading-[25.6px] text-[#137513]'>AI's response is accurate and addresses the core issue well.</p>
        }
        if (currentPageLegalReviewStatus?.lawyer_submission_metadata?.rating === 'yellow') {
            return <p className='text-[16px] font-[600] leading-[25.6px] text-[#906E1C]'>AI is accurate in the information it’s provided, but some key information is missing. </p>
        }
        if (currentPageLegalReviewStatus?.lawyer_submission_metadata?.rating === 'red') {
            return <p className='text-[16px] font-[600] leading-[25.6px] text-[#A20009]'>The AI's response is inaccurate as it has missed some important context</p>
        }
    }

    const getTag = () => {
        if (currentPageLegalReviewStatus?.lawyer_submission_metadata?.rating === 'green') {
            return <div className="text-[#137513] rounded-[50px] gap-[4px] px-[12px] py-[3px] text-[14px] font-[600] leading-[21px] flex flex-row bg-accurate">
                <SealCheck size={16} className='text-[#137513] my-auto' />
                <span className='my-auto'> Accurate</span>
            </div>
        }
        if (currentPageLegalReviewStatus?.lawyer_submission_metadata?.rating === 'yellow') {
            return <div className="text-[DCA218] rounded-[50px] gap-[4px] px-[12px] py-[3px] text-[14px] font-[600] leading-[21px]  flex flex-row bg-incomplete">
                <Barricade size={16} className='text-[#DCA218] my-auto' />
                <span className="my-auto"> Incomplete</span>
            </div>
        }
        if (currentPageLegalReviewStatus?.lawyer_submission_metadata?.rating === 'red') {
            return <div className="text-[#A20009] rounded-[50px] gap-[4px] px-[12px] py-[3px] text-[14px] font-[600] leading-[21px]  flex flex-row bg-incorrect">
                <XCircle size={16} className='text-[#A20009] my-auto' />
                <span className="my-auto"> Inaccurate</span>
            </div>
        }
    }
    const closeModal = () => {
        dispatch(setShowLegalReviewCompletedModal(false))
    }


    useEffect(() => {
        // setSteps(generateSteps())
    }, [showLegalReviewCompletedModal])



    return (
        <Modal
            isOpen={showLegalReviewCompletedModal}
            bodyClassName={'py-0'}
            contentAreaStyles={{ paddingLeft: '0px', paddingRight: '0px' }}
            onClose={() => { closeModal() }}
            closeBtnClassName='top-6'
        >
            <div className='py-8 w-[800px] gap-[24px]'>

                <section role='header' className='px-10 pb-6 border-b'>
                    <p className='text-[#011513] text-base font-[700] font-[Lora] italic text-[24px] leading-[33.6px]'>Your review is complete</p>
                </section>

                <section className='px-10 pb-6 mt-[24px]'>
                    <p className='text-[#011513] text-base font-[700] font-[Lora] italic text-[24px] leading-[33.6px] flex flex-row gap-[12px]'>
                        {userMetadata?.lawyer_info?.name} rated this as {getTag()}
                    </p>
                </section>
                <section className='px-10 pb-6 overflow-y-scroll gap-[24px] flex flex-col'>

                    <section className='px-3 py-3  overflow-y-scroll gap-[24px] flex flex-col border rounded'>
                        {getLeadingText()}
                        {currentPageLegalReviewStatus?.lawyer_submission_metadata?.comments}
                    </section>

                </section>
                <section className='flex justify-between px-10 pt-6'>
                    <span></span>
                    {/* <button className='text-[#18475A] flex items-center text-sm font-semibold underline'>See your request <ArrowUpRight className='ml-2' /></button> */}
                    <Button
                        onClick={closeModal}
                        className={'py-3'}
                        text={'Ok, got it!'}
                    />
                </section>
            </div>
        </Modal>
    )
}


export default LegalReviewComplete