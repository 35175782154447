import React from 'react';
import { PenNib } from '@phosphor-icons/react';
import { useDispatch } from 'react-redux';
import { setSavedDataInformation, setShowSavedDataModal } from '../../pages/chatpage/messageSlice';
import { trackAnalytics } from 'src/pages/helpers';

const SavedDataPrompt = ({ threadType, chatInfo, threadId, email }) => {
    const dispatch = useDispatch();
    const handleViewData = () => {
        dispatch(setSavedDataInformation(chatInfo));
        // window.analytics.track("View draft Data clicked", { "user_email": email })
        trackAnalytics("View draft Data clicked", { "user_email": email });
        dispatch(setShowSavedDataModal(true));
    }
    return (
        <div>
            <div className={`flex justify-between border ${threadType}-information-gathered p-[16px] rounded-[12px]`}>
                <div className="left-section gap-[10px] flex flex-row">
                    <div className="flex flex-col gap-[10px]">
                        <PenNib size={20} className="my-auto" />
                    </div>
                    <p className="my-auto text-[14px] leading-[19.6px] font-[500] text-[#333333]" >{chatInfo?.text}</p>

                </div>
                <div className="right-section">
                    <p className={`text-[${threadType === 'draft' ? '#13866F' : '#000'}] cursor-pointer text-[14px] leading-[19.6px] font-[700] underline`}
                        onClick={handleViewData}>View Data</p>
                </div>
            </div>
        </div>
    );
};

export default SavedDataPrompt;