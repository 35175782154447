import React from 'react'
import Modal from '../../components/Modal'
import CheckCircle from '../../images/check-circle.png'
import { Clock } from '@phosphor-icons/react'
import Button from './Button'
import { useDispatch, useSelector } from 'react-redux'
import { setShowRequestLegalReviewAside, setShowReviewRequestedModal } from '../onboarding/onboardingSlice'

const ReviewRequested = () => {
    const dispatch = useDispatch();
    const showReviewRequestedModal = useSelector((state) => state.onboarding.showReviewRequestedModal);
    const closeModal = () => {
        dispatch(setShowReviewRequestedModal(false));
        dispatch(setShowRequestLegalReviewAside(false));
    }
    return (
        <Modal
            isOpen={showReviewRequestedModal}
            bodyClassName={'py-0'}
            contentAreaStyles={{ paddingLeft: '0px', paddingRight: '0px' }}
        >
            <div className='w-[664px] py-10 flex flex-col justify-center items-center'>
                <img alt='check' src={CheckCircle} />
                <h6 className='mt-4 text-[#353535] text-2xl font-semibold'>Review requested</h6>
                <p className='text-[#686868] text-center text-base font-normal mt-2'> You’ll receive a confirmation email with your lawyer CC'ed in it for <br />
                    all the updates regarding your review request   </p>

                <div className='text-xs bg-[#DCA218]/10 px-2 py-1 rounded-lg mt-6 text-[#686868] flex items-center'><Clock size={14} className='text-[#DCA218] mr-2' />takes up to 2 business days for completetion</div>

                <Button
                    onClick={closeModal}
                    className={'mt-10 py-3'}
                    text={'Back to chat'}
                />
            </div>
        </Modal>
    )
}

export default ReviewRequested