import { ArrowLeft, ArrowRight, FileText, PencilSimpleLine, Plus, Trash } from '@phosphor-icons/react';
import TextArea from 'antd/es/input/TextArea';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const FinalTouchesScreen = (props) => {
    const { backToSelectPartyScreen2, fileSelected, setFileSelected, createDraft, finalNotes, setFinalNotes } = props;
    const selectedDocumentType = useSelector((state) => state.homeslice.selectedDocumentType);


    const handleFileChange = (event) => {
        setFileSelected(event.target.files[0]);
    };
    function formatFileSize(bytes) {
        if (bytes === 0) return "0 Bytes";
        const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + " " + sizes[i];
    }


    const awaitingResponse = useSelector((state) => state.messageSlice.awaitingResponse);

    //     const placeholderText = `Describe anything specific you need me to include in your legal document. 
    // Your notes will help the AI gather more context and enhance it's knowledge base.`;

    const placeholderText = `Examples:
1. We have the upperhand, so make the terms aggressive
2. Set jurisdiction to NY
3. Add arbitration clause
`


    return (

        <>
            <div className="flex flex-col gap-[18px] py-[18px] w-full  mx-auto">
                <div className="flex flex-col w-[860px] h-[277px] mx-auto gap-[20px] px-[56px]">

                    <div className="flex flex-col gap-[6px]">
                        <h3><span className="text-[18px] leading-[23.44px] font-[600] text-[#302F2F]">Provide the Background </span>
                            <span className="text-[#686868] text-[14px] leading-[21px] font-[400]">(optional)</span><br />
                        </h3>
                        <p className="text-[14px] leading-[19.8px] text-[#686868] font-[400]">Personalize your {selectedDocumentType?.name} and ensure maximum protection.</p>
                    </div>

                    <TextArea autoSize={{ minRows: 4, maxRows: 10 }}
                        value={finalNotes}
                        onChange={(e) => setFinalNotes(e.target.value)}
                        className="border focus-within:!border-black rounded-[8px] p-[16px]" placeholder={placeholderText} />
                </div>
            </div>
            <div className="flex flex-row justify-between border-[#E4E4E4] border !border-b-0 mt-[70px] mb-[-45px] h-[96px] rounded-[8px] px-[40px] py-[24px]">
                <button className="flex flex-row gap-[8px] items-center justify-center  h-[30px] text-[#302F2F] font-[600] text-[14px] border-r-[#E4E4E4] my-auto py-[12px] px-[24px]"
                    onClick={() => { backToSelectPartyScreen2() }}>
                    <ArrowLeft size={24} className="text-[#302F2F]" />
                    <span>Go Back</span>
                </button>
                <div className="flex flex-row justify-between">
                    {/* <button className="flex flex-row gap-[8px] items-center justify-center h-[30px] text-[#302F2F] border-0 bg-transparent font-[600] text-[14px] py-[12px] px-[24px] my-auto"
                        onClick={createDraft}>
                        <span>Skip and Proceed</span>
                    </button> */}

                    <button className="cursor-pointer rounded-[8px] flex flex-row gap-[8px] items-center justify-center  text-white bg-[#302F2F] font-[600] text-[14px] py-[12px] px-[24px] my-auto"
                        onClick={createDraft}
                        disabled={awaitingResponse}
                    >
                        {awaitingResponse ? <div className="w-6 h-6 border-2 border-t-0 border-[#fff] rounded-full animate-spin"></div> :
                            <>
                                <span>Continue</span>
                                <ArrowRight size={24} className="text-[#fff]" />
                            </>
                        }
                    </button>
                </div>
            </div>
        </>
    );
};

export default FinalTouchesScreen;