import React, { useEffect } from 'react'
import Modal from '../../components/Modal'
import { ArrowSquareOut, ArrowUpRight, Copy, ShieldCheck } from '@phosphor-icons/react'
import Button from './Button'
import BookCallBtnImg from '../../images/book-call-btn.png'
import { useSelector } from 'react-redux'

const LawyerCard = () => {
    const [lawyer, setLawyer] = React.useState({})
    const [specialties, setSpecialties] = React.useState([])
    const metadata = useSelector(state => state.onboarding.userMetadata);

    useEffect(() => {
        if (metadata && metadata?.lawyer_info) {
            setLawyer(metadata.lawyer_info)
            setSpecialties(metadata.lawyer_info.specialties.split(','))
        }
    }, [metadata]) // eslint-disable-line
    return (
        <Modal
            isOpen={true}
            bodyClassName={'py-0'}
            contentAreaStyles={{ paddingLeft: '0px', paddingRight: '0px' }}
        >
            <div className='px-8 py-10 w-[500px]'>
                <p className='text-2xl text-center text-[#011513] font-bold'>Way to go!</p>
                <p className='text-[#18475A] mt-2 text-center text-base font-normal'>You have been matched with a dedicated lawyer</p>
                {/* {
            name: "Mr. Andrew Meyers",
            email: "andrew.meyer@inhouse.so",
            phone_number: "+91 8130425877",
            state_bar: "State of Ohio",
            specialties: "Government Contracts, Civil Law, Litigation, Commercial Law, Corporate Law",
            education: "L.L.B, Harvard Law School, US Post Graduate Diploma in Law, New York University",
            experience: "13+ years @BakerHostetler",
            hourly_rate: "75",
            calendly_link: "",
            pre_signed_image_url: "https://plus.unsplash.com/premium_photo-1678197937465-bdbc4ed95815?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cGVyc29ufGVufDB8fDB8fHww",
          } */}
                {/* Card */}
                <div className='border border-[#E4E4E4] rounded-xl  mt-4'>

                    <div className='flex items-center bg-[#748A8C0A] px-6 py-[20px]'>
                        <img className='border border-[#E4E4E4] object-cover w-16 h-16 mr-4 rounded-full' alt='lawyer pic' src='https://img.freepik.com/free-photo/close-up-young-person-barbeque_23-2149271990.jpg' />
                        <div >
                            <p className='text-[#18475A] font-semibold text-normal'>{lawyer?.name}</p>
                            <div className='flex items-center text-[#949494] text-xs font-medium'><ShieldCheck size={16} className='mr-1' />Verified InHouse Counsel</div>
                        </div>
                    </div>

                    <div className='px-6 mt-4 up'>
                        <p className='text-[#18475A] font-bold text-[10px] uppercase'>CHARGES FOR ADDITIONAL TASKS</p>
                        <p className='text-[#137513] font-semibold text-xs px-2 mt-2 py-1 bg-[#137513]/10 w-fit rounded-md'>${lawyer?.hourly_rate}/hr </p>
                    </div>

                    <div className='px-6 mt-4'>
                        <p className='text-[#18475A] font-bold text-[10px] uppercase'>Education</p>
                        <p className='text-[#686868] text-xs font-normal mt-2'>{lawyer?.education}</p>
                    </div>

                    <div className='px-6 mt-4'>
                        <p className='text-[#18475A] font-bold text-[10px] uppercase'>experience</p>
                        <p className='text-[#686868] text-xs font-normal mt-2'>{lawyer?.experience}</p>
                    </div>

                    <div className='px-6 mt-4'>
                        <p className='text-[#18475A] font-bold text-[10px] uppercase'>jurisdiction of practice</p>
                        <p className='text-[#686868] text-xs font-normal mt-2'>{lawyer?.state_bar}</p>
                    </div>


                    <div className='px-6 mt-4 mb-3'>
                        <p className='text-[#18475A] font-bold text-[10px] uppercase'>Specialises in</p>
                        <ul className='flex flex-wrap'>
                            {specialties.map((specialty, index) => (
                                <li className='text-[#686868] rounded-xl bg-[#FAFAFA] whitespace-nowrap text-xs font-medium px-2 py-1 mt-2' key={index}>{specialty}</li>
                            ))}
                            {/* <li className='text-[#686868] rounded-xl bg-[#FAFAFA] whitespace-nowrap text-xs font-medium px-2 py-1 mt-2'>civil law</li>
                            <li className='text-[#686868] rounded-xl bg-[#FAFAFA] whitespace-nowrap text-xs font-medium px-2 py-1 mt-2'>litigation and disputes</li>
                            <li className='text-[#686868] rounded-xl bg-[#FAFAFA] whitespace-nowrap text-xs font-medium px-2 py-1 mt-2'>commercial law</li>
                            <li className='text-[#686868] rounded-xl bg-[#FAFAFA] whitespace-nowrap text-xs font-medium px-2 py-1 mt-2'>corporate law</li> */}
                        </ul>
                    </div>
                </div>

                <h6 className='text-[#18475A] mt-6 font-semibold text-base border-b border-b-[#F2F2F2] pb-2'>Contact Information</h6>

                <ul className='flex mt-4'>
                    <li className='w-1/2'>
                        <h6 className='text-[#18475A] text-[10px] font-bold'>EMAIL ID </h6>
                        <a className='text-[#353535] mt-1 text-xs font-normal flex items-center' href="mailto:{lawyer?.email}" >{lawyer?.email} <ArrowSquareOut className='ml-1' size={16} /></a>
                    </li>
                    <li className='w-1/2'>
                        <h6 className='text-[#18475A] text-[10px] font-bold'>Phone Number </h6>
                        <a className='text-[#353535] text-xs mt-1 font-normal flex items-center' href="tel:{lawyer.phone_number}" > {lawyer?.phone_number} <Copy className='ml-1' size={16} /></a>
                    </li>
                </ul>


                <Button
                    secondary
                    className={'w-full mt-8 flex justify-center !py-[12px]'}
                    text={<> <img alt='btn' className='mr-1' src={BookCallBtnImg} />Book a Call <ArrowUpRight className='ml-2' /></>}
                />

            </div>
        </Modal>
    )
}

export default LawyerCard