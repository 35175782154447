import React from 'react'
import DesktopOnlyImg from '../../images/desktop-only.png'
import InHouseLogoImg from '../../images/inhouse-logo-v2.png'

const MobileHome = () => {
    return (
        <div className='flex flex-col items-center justify-between h-screen'>
            <div>
                <div className='flex items-center justify-start h-20 px-6 py-3 border-b shadow-sm'>
                    <img alt='inhouse logo' className='object-contain h-full' src={InHouseLogoImg} />
                </div>
                <div className='flex flex-col items-center justify-center px-6'>
                    <img className='w-full mt-20' src={DesktopOnlyImg} alt='desktop' />
                    <h1 className='text-[#011513] text-xl font-bold mt-10'>You’re all set to use Inhouse! </h1>
                    <p className='text-center text-[#353535]   mt-3'>We’re crafting a mobile version that will be worth the wait, but you can</p>
                    <p className='mt-2 text-[#141414] text-base font-semibold'>get started on desktop right now.</p>
                </div>
            </div>
            <p className='text-[#686868] text-[10px] font-medium mt-3 mb-6'>© Inhouse AI, Inc. All rights reserved.</p>

        </div>
    )
}

export default MobileHome