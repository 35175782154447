import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowRight, ArrowUpRight, CaretDoubleRight, Check, Clock, X } from '@phosphor-icons/react';
import Button from "../LawyerHub/Button";
import SignedDocumentImg from '../../images/signed-document.png'
import { getLawyerHub, requestLegalReview, resetRequestLegalReviewFailureReason, setShowLearnMoreModal, setShowRequestLegalReviewAside, setShowReviewRequestedModal, setShowSubscriptionPopup } from '../onboarding/onboardingSlice';
import { openPopup } from 'src/components/DocumentViewer/helpers';
import ReliabilityGuaranteedPopup from './ReliabilityGuaranteedPopup';
import { trackAnalytics } from '../helpers';
import { PLANS } from 'src/components/Constants';

const LegalReviewRequestAside = () => {
    const dispatch = useDispatch();
    const metadata = useSelector((state) => state.onboarding.userMetadata);
    // var metadata = {
    //     "subscription_type": "subscriber_business",
    //     "lawyer_status": "not_matched",
    //     "lawyer_info": {
    //         "name": "John Doe", "email": ""
    //     },
    //     "show_new_lawyer_assigned_notification": false,
    //     "legal_review_credits_total": 5,
    //     "legal_review_credits_used": 1,
    //     "threads_total": 2,
    //     "threads_remaining": 5
    // }

    const showRequestLegalReviewAside = useSelector((state) => state.onboarding.showRequestLegalReviewAside);
    const showReviewRequestedModal = useSelector((state) => state.onboarding.showReviewRequestedModal);
    const requestLegalReviewStatus = useSelector((state) => state.onboarding.requestLegalReviewStatus);
    const threadData = useSelector((state) => state.messageSlice.threadData);
    const requestLegalReviewFailureReason = useSelector((state) => state.onboarding.requestLegalReviewFailureReason);
    const showLearnMoreModal = useSelector((state) => state.onboarding.showLearnMoreModal);
    const email = useSelector((state) => state.onboarding.email);

    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);
    const [notice, setNotice] = useState('');
    const [showBanner, setShowBanner] = useState(true);

    useEffect(() => {
        setLoading(false);
        setNotice("")
        setText("");
        dispatch(resetRequestLegalReviewFailureReason())
    }, [showRequestLegalReviewAside]) // eslint-disable-line

    const submitRequest = () => {
        setLoading(true);
        setNotice("")
        dispatch(requestLegalReview({ "threadId": threadData?.id, "description": text }));
        if (metadata.subscription_type === "subscriber_business") {
            if (metadata.legal_review_credits_used >= metadata.legal_review_credits_total) {
                trackAnalytics("request legal review clicked", {
                    "user_email": email, "subscription_type": metadata.subscription_type,
                    "legal_review_credits_total": metadata.legal_review_credits_total,
                    "legal_review_credits_used": metadata.legal_review_credits_used
                });
            }
            else if (metadata.legal_review_credits_total > metadata.legal_review_credits_used) {
                // set text that one legal credit will be used
                trackAnalytics("request legal review clicked", {
                    "user_email": email, "subscription_type": metadata.subscription_type,
                });
            }
        }
        else if (metadata.lawyer_info === null) {
            // show find lawyer popup
            trackAnalytics("request legal review clicked", {
                "user_email": email, "subscription_type": metadata.subscription_type,
                "lawyer_assigned": "not yet"
            });
        }
        else if (metadata.lawyer_info) {
            if (metadata.legal_review_credits_used >= metadata.legal_review_credits_total) {
                // show popup to upgrade plan. user is out of credits 
                // also text should change
                trackAnalytics("request legal review clicked", {
                    "user_email": email, "subscription_type": metadata.subscription_type,
                    "legal_review_credits_total": metadata.legal_review_credits_total,
                    "legal_review_credits_used": metadata.legal_review_credits_used
                });
            }
            else if (metadata.legal_review_credits_total > metadata.legal_review_credits_used) {
                // set text that one legal credit will be used
                trackAnalytics("request legal review clicked", {
                    "user_email": email, "subscription_type": metadata.subscription_type,
                    "lawyer_assigned": "yes",
                });
            }
        }
    }

    useEffect(() => {
        setNotice("");
    }, []);

    useEffect(() => {
        if (requestLegalReviewStatus === "failed") {
            setLoading(false);
            if (!requestLegalReviewFailureReason) {
                setNotice("Failed to request review. Please try again later.");
            }
            else {
                setNotice(requestLegalReviewFailureReason.detail)
            }
        }
    }, [requestLegalReviewStatus, requestLegalReviewFailureReason])

    return (
        <> {showRequestLegalReviewAside && (
            <aside className="h-screen max-w-[550px] flex flex-col overflow-y-scroll bg-white border-l border-l-[#E4E4E4] shadow-xl absolute z-1000 right-0">
                <section className="flex justify-between px-6 h-[64px] py-[18px] border-b  border-b-[#E4E4E4]">
                    <div className="flex my-auto">
                        <p className="text-lg font-semibold whitespace-nowrap mr-3 text-[#011513]">Request a Lawyer Review</p>
                        <div className="flex items-center pl-2 rounded-full whitespace-nowrap text-[#353535] text-xs font-semibold bg-[#DCA21814] px-3" ><Clock className="text-[#DCA218] mr-2" size={16} />Receive it under 2 Days</div>
                    </div>
                    <button className="flex items-center my-auto text-sm font-semibold text-[#353535]" onClick={() => { dispatch(setShowRequestLegalReviewAside(false)) }}>
                        Hide<CaretDoubleRight className="ml-2" size={16} />
                    </button>
                </section>

                <div className="flex flex-col justify-between flex-1 ">
                    <section className="px-8 mt-6">
                        {showBanner && (
                            <div role="card" className='border rounded-lg relative flex  px-6 py-6 border-[#18475A29] bg-[#F5FAF9]'>
                                <div role="left-section-text" className="w-3/4">
                                    <h6 className="text-[#011513] text-lg font-semibold">Eliminate any uncertainty</h6>
                                    <p className="text-[#353535] text-sm font-normal mt-2">Our lawyers review your AI-generated answers and documents, ensuring accuracy and compliance. </p>
                                    <Button
                                        className={'mt-3'}
                                        secondary
                                        onClick={() => { trackAnalytics("learn more clicked from Request a Lawyer Review aside", { "user_email": email }); dispatch(setShowLearnMoreModal(true)) }}
                                        text={<>Learn more <ArrowUpRight size={16} /></>}
                                    />
                                </div>
                                <div role='right-section-img' className="w-1/4">
                                    <img alt="signed document" src={SignedDocumentImg} />
                                </div>
                                {/* <X className="absolute right-4 top-4 text-[#686868] cursor-pointer" size={18} onClick={() => { setShowBanner(false) }} /> */}
                            </div>
                        )}
                        <p className="mt-8 text-base font-semibold">Provide any context for that your lawyer should know <span className="text-[#18475A] text-sm font-normal ml-1">{`(optional)`}</span></p>
                        <p className="text-[#686868] mt-2">Your chat and documents will be shared with the lawyer for context.</p>

                        <textarea
                            placeholder="Include any details the lawyer should know before reviewing"
                            className="border px-3 py-3 h-[200px] border-[#D1D1D1] w-full mt-6 rounded-lg"
                            disabled={loading}
                            value={text} onChange={(e) => setText(e.target.value)}
                        />
                        {metadata?.subscription_type !== PLANS.SUBSCRIBER_ENTERPRISE && (
                            <>
                                {metadata?.legal_review_credits_total > metadata?.legal_review_credits_used && (
                                    <div className="flex font-[500] text-[14px] leading-[21px]">
                                        Your &nbsp; <span className="!font-[600]">one free legal credit</span> &nbsp; will be used for this review.
                                    </div>
                                )}
                            </>
                        )}


                    </section>
                    <section role="footer-section">
                        {/* <div className="flex items-center justify-center text-center text-[#686868] text-sm font-medium">Your data was saved at 1:05 pm <Check size={16} className="ml-2" /></div> */}
                        {notice && <div className="flex items-center justify-center text-center text-[#FF0000] text-sm font-medium">{notice}</div>}
                        <div className="flex items-center justify-between border-t border-t-[#E4E4E4] mt-4 py-3 px-6">
                            <p className="text-[#353535] text-sm font-semibold">See {" "}
                                <a href="https://storage.googleapis.com/inhouse-public-documents/InHouse%20Terms%20of%20Service.pdf" rel="noopener noreferrer" className="underline" target="blank">
                                    Terms & Conditions
                                </a> for more info</p>
                            <Button
                                className={'h-[48px] gap-[12px] py-[12px] px-[24px] bg-[#18475A]'}
                                onClick={submitRequest}
                                disabled={loading}
                                text={!loading ? <><span className='my-auto'>Submit Request</span> <ArrowRight size={16} className="my-auto text-white" /></> : <div className="flex items-center justify-center"><div className="w-4 h-4 border-2 border-t-[#fff] rounded-full animate-spin"></div></div>}
                            />
                        </div>
                    </section>
                </div >
            </aside >)

        }
            {showLearnMoreModal && (
                <ReliabilityGuaranteedPopup />
            )
            }
        </>

    );
};

export default LegalReviewRequestAside;