import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { Copy, Link, Spinner } from "@phosphor-icons/react";
import { useDispatch, useSelector } from 'react-redux';
import { generateSharedId } from '../../../../pages/chatpage/threadsSlice';
import { toast } from "react-toastify";
import { X } from '@phosphor-icons/react/dist/ssr';
import { trackAnalytics } from 'src/pages/helpers';


const notify = () => toast.success("Copied shared conversation URL to clipboard!");


const ShareThreadModal = (props) => {

    const {
        open,
        setActionOnThread,
        setLoading,
        threadInfo,
        mode
    } = props;

    const BASE_URL = process.env.REACT_APP_URL;

    const dispatch = useDispatch();
    const [screen, setScreen] = useState(0)
    const [userEmail, setUserEmail] = useState("")
    const [thread, setThread] = useState({})
    const [duration, setDuration] = useState("")
    const shareThreadStatus = useSelector((state) => state.threads.shareThreadStatus);
    const threadSelectedToShare = useSelector((state) => state.threads.threadSelectedToShare);
    const shareThreadId = useSelector((state) => state.threads.shareThreadId);
    const email = useSelector((state) => state.homeslice.email);

    const handleCopy = (url) => {
        navigator.clipboard
            .writeText(url)
            .then(() => {
                // setShareLinkModalOpen(false);
                notify();
            })
            .catch((err) => console.error("Error copying text: ", err));
    };

    const getSharedId = (threadId) => {
        dispatch(generateSharedId({ threadId: threadId, mode: mode.toLowerCase() }));
    };

    const copyLinkButtonClicked = () => {
        trackAnalytics("dashboard_history_tab_clicked_share_copy_link_clicked", { thread_id: threadInfo?.thread?.id, "user_email": email });
        handleCopy(`${window.location.origin}/${threadInfo?.thread?.type || threadInfo?.thread?.thread_type}/shared/${shareThreadId}`);
        setActionOnThread("")
    }

    useEffect(() => {
        if (threadInfo) {
            setThread(threadInfo.thread);
            setDuration(threadInfo.duration)
        }
    }, [threadInfo]);

    useEffect(() => {
        if (shareThreadStatus === "success") {
            if (threadSelectedToShare === shareThreadId) {
                setScreen(1)
            }
        } else if (shareThreadStatus === "failed") {
            // setLoading(false);
            setScreen(-1)
        }
    }, [shareThreadStatus]);

    useEffect(() => {
        setScreen(0)
    }, [open]);


    return (
        <Modal
            centered
            open={open}
            onOk={() => setActionOnThread("")}
            onCancel={() => setActionOnThread("")}
            className="review-pop-up h-full rounded-[16px] bg-[#fff] overflow-hidden "
            footer={null}
        >
            <div className="flex justify-end w-full pt-[12px] px-[12px]">
                <X size={24} className="cursor-pointer" onClick={() => setActionOnThread("")} />
            </div>
            {screen === 0 && (
                <div className="w-[800px] h-[245px] flex flex-col justify-between p-[32px] gap-[32px] ">
                    <div className="flex items-center justify-center w-[93px] border mx-auto border-[#248370] rounded-[50%] h-[93px]">
                        <div className="flex items-center justify-center w-[65px] border mx-auto bg-[#e9f3f1] rounded-[50%] h-[65px]">
                            <Link size={28} className="text-[#248370] mx-auto my-auto" />
                        </div>
                    </div>
                    <div className="text-[#353535] text-[24px] leading-[33.6px] font-[600] text-center w-full mx-auto">Generating private link for the thread ...</div>
                </div>
            )}

            {/* thread shared */}

            {screen === 1 && (
                <div className="w-[800px] flex flex-col justify-between p-[32px] gap-[32px] ">
                    <div className="text-[#353535] text-[24px] leading-[33.6px] font-[600] w-full mx-auto">Share private link for - {thread?.title}</div>
                    <hr />
                    <p className='text-[18px] font-[500] leading-[28.8px] text-[#686868]'>
                        Your conversation and files will remain private. Only the people you give access to will be able to view this thread.
                    </p>
                    <div className="flex justify-betwen w-full mx-auto gap-[16px]">
                        {/* <div className="flex pl-[20px] border border-[#e9f3f1] rounded-[8px]">
                            <TextArea
                                autoSize={{
                                    minRows: 1,
                                    maxRows: 1,
                                }}
                                type="text"
                                className="w-[453px] py-[12px] h-[48px] rounded-r-0 px-[16px] text-[#353535] text-[14px] leading-[19.6px] font-[400] focus:!border-0 focus:!shadow-none border-0 focus-visible:!border-0 focus-visible:!shadow-none"
                                value={userEmail}
                                onChange={(e) => setUserEmail(e.target.value)}
                                placeholder="Invite with email ID"
                                onKeyDown={(e) => {
                                    if (
                                        e.key === "Enter" &&
                                        (e.ctrlKey || e.metaKey) &&
                                        !e.shiftKey &&
                                        (e.target.tagName !== "TEXTAREA" || document.activeElement === e.target)
                                    ) { }
                                }}
                            />
                            <button
                                className="w-[116px] h-[48px] bg-[#e9f3f1] rounded-l-0 rounded-r-[8px] text-[#353535] text-[16px] leading-[22.4px] font-[600] "
                                onClick={() => handleCopy(`${window.location.origin}/${threadInfo?.thread?.type}/shared/${shareThreadId}`)}
                            >
                                Share
                            </button>
                        </div> */}
                        <button className="bg-[#18475A] text-[white] h-[48px] w-[152px] rounded-[8px] text-[16px] leading-[22.4px] font-[600] flex flex-row justify-center gap-[10px] cursor-pointer"
                            onClick={() => copyLinkButtonClicked()}
                        >
                            <span className="my-auto"> Copy Link</span>
                            <Copy size={16} className=" my-auto text-white" weight="bold" />
                        </button>
                    </div>
                </div>
            )}
            {/* error  */}
            {screen === -1 && (
                <div className="w-[800px] h-[245px] flex flex-col justify-between p-[32px] gap-[32px] ">
                    <div className="text-[#353535] text-[24px] leading-[33.6px] font-[600] text-center w-full mx-auto">Failed to generate private link for the thread</div>
                </div>

            )}
        </Modal>
    )
}

export default ShareThreadModal