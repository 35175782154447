import { Check, OfficeChair, SealCheck } from '@phosphor-icons/react'
import React, { useEffect } from 'react'
import Button from '../Button'
import CheckCircle from '../../../images/check-circle.png'
import SubscriptionCard from '../components/SubscriptionCard'
import { PLANS, PLAN_TYPES, plans } from 'src/components/Constants'
import { useDispatch, useSelector } from 'react-redux'
import { setShowBusinessPlanUnlocked, setShowFindBestLawyer } from 'src/pages/onboarding/onboardingSlice'

const BusinessPlanUnlocked = () => {
    const dispatch = useDispatch()
    const userSelectedPlan = useSelector(state => state.chat.userSelectedPlan)
    const [plan, setPlan] = React.useState(null);

    const showBusinessPlanUnlocked = useSelector(state => state.onboarding.showBusinessPlanUnlocked)
    const skip = () => {
        dispatch(setShowBusinessPlanUnlocked(false))
    }
    const matchWithLawyer = () => {
        dispatch(setShowFindBestLawyer(true))
        dispatch(setShowBusinessPlanUnlocked(false))
    }

    useEffect(() => {
        // debugger;
        if (showBusinessPlanUnlocked) {
            var name = userSelectedPlan
            var p = (plans?.find(plan => plan.planName === name))
            setPlan(p)
        }
    }, [showBusinessPlanUnlocked]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='flex flex-col items-center justify-center py-8'>
            <div>
                {/* <SealCheck fill='#137513' className='' size={56} /> */}
                <img alt='check' src={CheckCircle} />
            </div>
            <p className='text-lg font-normal text-[#686868] mt-6'>Congratulations, you are now on {plan?.title}!</p>
            <p className='text-[#011513] text-2xl font-semibold mt-0'>You’ve unlocked these benefits</p>
            {plan &&

                <div>
                    <ul className='px-4 mt-6'>
                        {plan.features.map(feature => (
                            <li className={`text-sm mt-3 font-normal flex items-center text-[#353535] ${feature.highlight && '!font-bold'}`}> <Check size={10} className='mr-2' />{feature.text}</li>
                        ))}

                    </ul>

                </div>}

            <div className='mt-10  px-8 flex justify-end gap-4 border-t pt-4 w-full border-[#E4E4E4]'>
                <Button
                    secondary
                    className={'px-3'}
                    text={'Skip for now'}
                    onClick={skip}
                />
                <Button
                    className={'py-2 px-3'}
                    text={'Match with a lawyer'}
                    onClick={matchWithLawyer}
                />
            </div>
        </div>
    )
}

export default BusinessPlanUnlocked