import axios from 'axios';

import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "@clerk/clerk-react"
import BaseLayout from '../layouts/BaseLayout';
import useValidatePageAccess from "../../hooks/useValidatePageAccess";
import TopNav from "./Navs/TopNav";
import ChatBoxComponent from "./components/ChatBoxComponent";
import ReviewShimmer from "../../components/Shimmers/ReviewShimmer";
import DraftShimmer from "../../components/Shimmers/DraftShimmer";
import AskShimmer from "../../components/Shimmers/AskShimmer";
import { useDispatch, useSelector } from "react-redux";
import { openPopup, renderMessage } from "../../components/DocumentViewer/helpers"
import SavedDataModalComponent from "../../components/Prompts/SavedDataModalComponent";
import { setEmail } from "../Homepage/HomeSlice";
import { addMessage, getAskThreadMessages, getDraftThreadMessages, resetThreadData, setAwaitingDraftQuestionsResponse, setAwaitingResponse, setChatInputMessage, setLegalReviewRequested, setMessagingDisabled, setThreadTitle } from "./messageSlice";
import { resetChatPage } from "./chatSlice";
import { useWebSocketContext } from "../../WebsocketProvider";
import { markThreadRead, resetData, resetReferenceDocDownloadUrl } from "./threadsSlice";
import AskToDraftModal from "../../components/chat/Modals/AskToDraftModal";
import DraftDocumentQuestionaireModal from "./Modals/DraftDocumentQuestionaireModal";
import LegalReviewRequestAside from '../LawyerHub/LegalReviewRequestAside';
import UpSellFlowPopup from '../LawyerHub/UpsellFlowPopup';
import { ArrowDown, Briefcase, ShareFat } from '@phosphor-icons/react';
import { trackAnalytics } from '../helpers';
import { getLawyerHub, resetRequestLegalReviewFailureReason, setCurrentPageLegalReviewStatus, setShowRequestLegalReviewAside } from '../onboarding/onboardingSlice';


const ChatPageComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoaded, isSignedIn, user } = useUser();
    let { threadType, chatId } = useParams();
    const [inputMessage, setInputMessage] = useState('');
    const [steps, setSteps] = useState([])
    const [legalReviewRequest, setLegalReviewRequest] = useState({})
    const [isScrollToBottomButtonVisible, setIsScrollToBottomButtonVisible] = useState(false);


    const { isConnected, messages, sendMessage, createMessage } = useWebSocketContext();

    const chatpageRef = useRef(null);
    const messageListRef = useRef(null);
    const bottomRef = useRef(null);


    const getThreadStatus = useSelector(state => state.messageSlice.getThreadStatus);
    const awaitingResponse = useSelector(state => state.messageSlice.awaitingResponse);
    const threads = useSelector((state) => state.threads.threads);
    const threadData = useSelector(state => state.messageSlice.threadData);
    const email = useSelector(state => state.homeslice.email);
    const showAskToDraftModal = useSelector((state) => state.messageSlice.showAskToDraftModal);
    const draftDocumentModalOpen = useSelector((state) => state.modalSlice.draftDocumentModalOpen)
    const showRequestLegalReviewAside = useSelector((state) => state.onboarding.showRequestLegalReviewAside);

    const showSubscriptionPopup = useSelector((state) => state.onboarding.showSubscriptionPopup);
    const showUpgradingPlan = useSelector((state) => state.onboarding.showUpgradingPlan);
    const showBusinessPlanUnlocked = useSelector((state) => state.onboarding.showBusinessPlanUnlocked);
    const showFindBestLawyer = useSelector((state) => state.onboarding.showFindBestLawyer);
    const showMeetingConfirmation = useSelector((state) => state.onboarding.showMeetingConfirmation);
    const showNextSteps = useSelector((state) => state.onboarding.showNextSteps);
    const showScheduleMeeting = useSelector((state) => state.onboarding.showScheduleMeeting);
    const scroll = useSelector((state) => state.messageSlice.scroll);
    const lawyerHubData = useSelector((state) => state.onboarding.lawyerHubData);
    const requestLegalReviewStatus = useSelector((state) => state.onboarding.requestLegalReviewStatus);
    const currentPageLegalReviewStatus = useSelector(state => state.onboarding.currentPageLegalReviewStatus);
    const metadata = useSelector((state) => state.onboarding.userMetadata);


    useEffect(() => {
        dispatch(resetChatPage());
        dispatch(resetData());
        dispatch(resetThreadData())
        dispatch(resetRequestLegalReviewFailureReason())
        if (threadType === "ask") {
            dispatch(getAskThreadMessages(chatId));
        }
        if (threadType === "draft") {
            dispatch(getDraftThreadMessages(chatId));
        }
        dispatch(setAwaitingResponse(false));
        dispatch(setMessagingDisabled(false));
        dispatch(setAwaitingDraftQuestionsResponse(false))
        dispatch(resetReferenceDocDownloadUrl())

        setSteps([
            "Analyzing your request",
            `Reviewing account info on ${user?.unsafeMetadata?.organization_name}`,
            "Preparing response"
        ])

        getCurrentLegalReviewStatus()

        // dispatch(getThread(chatId));
    }, [chatId]); // eslint-disable-line

    useEffect(() => {
        // scrollContainerToBottom(messageListRef);
        if (threadData.messages?.length > 1) {
            if (threadData?.document?.url || threadData?.document?.google_doc_id) {
                setSteps([
                    "Analyzing your request",
                    "Conducting legal research",
                    "Updating your document"
                ])
            } else {
                setSteps([
                    "Analyzing your request",
                    "Conducting legal research ",
                    "Preparing response"
                ])
            }
        }
    }, [scroll, threadData.messages]) // eslint-disable-line

    useEffect(() => {
        getCurrentLegalReviewStatus();
    }, [lawyerHubData]); // eslint-disable-line

    useEffect(() => {
        if (getThreadStatus === "success") {
            var results = []
            Object.keys(threads).forEach((key) => {
                const filtered = threads[key].filter(item => {
                    return item.id === chatId
                });
                if (filtered.length > 0) {
                    results = results.concat(filtered)
                }
            });
            if (results.length > 0) {
                dispatch(setThreadTitle(results[0].title));
            }
        }
    }, [getThreadStatus, threads]); // eslint-disable-line

    useEffect(() => {
        if (user && isLoaded && isSignedIn) {
            if (!user?.firstName || !user?.unsafeMetadata?.organization_name) {
                navigate('/onboarding')
            }
            dispatch(setEmail(user.primaryEmailAddress.emailAddress));
        }
    }, [user, isLoaded, isSignedIn]); // eslint-disable-line

    useEffect(() => {
        if (requestLegalReviewStatus === "success") {
            dispatch(getLawyerHub())
            dispatch(addMessage({
                message_type: "legal_review_message",
            }))
            if (metadata.subscription_type === "free_tier") {
                openPopup(dispatch, "legal_review");
            }
            else if (metadata.subscription_type === "subscriber_business") {
                if (metadata.legal_review_credits_used >= metadata.legal_review_credits_total) {
                    openPopup(dispatch, "legal_review");
                }
            }
            else if (metadata.lawyer_info) {
                if (metadata.legal_review_credits_used >= metadata.legal_review_credits_total) {
                    openPopup(dispatch, "legal_review");
                }
                else if (metadata.legal_review_credits_total > metadata.legal_review_credits_used) {
                }
            }
        }
    }, [requestLegalReviewStatus]) // eslint-disable-line


    useValidatePageAccess();

    const onsubmit = () => {
        trackAnalytics(`${threadData.thread_type} thread: additional message`, {
            "user_email": email, "thread_id": chatId, "message": inputMessage
        })
        // window.analytics.track(`${threadData.thread_type} thread: additional message`, {
        //     "user_email": email, "thread_id": chatId, "message": inputMessage
        // })
        dispatch(setChatInputMessage(inputMessage))
        var message = createMessage(threadType, "add-message", { "message": inputMessage, "thread_id": chatId });
        sendMessage(message, true)
        dispatch(setMessagingDisabled(true));
    }
    const scrollContainerToBottom = (messageListRef) => {
        if (messageListRef.current) {
            messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
    };

    const getCurrentLegalReviewStatus = () => {
        if (lawyerHubData) {
            // debugger;
            var legalReview = lawyerHubData?.find((item) => item.thread_id === threadData.id);
            setLegalReviewRequest(legalReview);
            if (legalReview && threadData.messages.find(item => item.message_type === "legal_review_message") === undefined) {
                dispatch(setLegalReviewRequested(true));
                dispatch(addMessage({
                    message_type: "legal_review_message",
                }))

            }
            dispatch(setCurrentPageLegalReviewStatus(legalReview))
        }

    }

    useEffect(() => {
        // console.log(messageListRef.current, bottomRef.current, getThreadStatus)
        if (!messageListRef.current || !bottomRef.current || getThreadStatus !== 'success') return;
        console.log("Setting observer")
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Update visibility state
                setIsScrollToBottomButtonVisible(!entry.isIntersecting);
            },
            {
                root: messageListRef.current, // The scrollable container
                rootMargin: '0px 0px 100px 0px', // 100px from the bottom
                threshold: 0, // Trigger if even 1px is visible
            }
        );

        observer.observe(bottomRef.current);

        // Cleanup on component unmount
        return () => observer.disconnect();
    }, [getThreadStatus, messageListRef.current, bottomRef.current]);

    return (
        <>
            {isLoaded ? (
                <BaseLayout>
                    <div className="flex flex-col w-[100%]">
                        <div className="flex flex-col justify-between h-screen">
                            <TopNav page="chat"></TopNav>
                            {getThreadStatus === "success" && (
                                <>
                                    <div className="py-[24px] mx-auto overflow-y-auto gap-[12px] justify-end flex-1  w-full" id="scrolling_div"
                                        ref={messageListRef}>
                                        {threadData.messages.map((message, index) => (
                                            <div className="w-[736px] mx-auto">
                                                {renderMessage(message, index, threadData, "full", email, threadType, false, setSteps)}
                                            </div>
                                        )
                                        )}
                                        <p role='bottom of chat' ref={bottomRef} className=''></p>
                                        {
                                            threadData?.document?.url && (
                                                <div className="flex flex-col w-[736px] mx-auto">
                                                    <a href={threadData?.document?.url} target="_blank" rel="noreferrer"
                                                        className="cursor-pointer flex flex-row w-fit p-[12px] my-[12px] text-[#FFF] rounded-[8px] border border-[#E0E0E0] bg-[#18475A] text-[14px] leading-[19.6px] font-[500] gap-[8px]"
                                                    >
                                                        <ShareFat size={16} className="my-auto text-white" />
                                                        <span className="my-auto">View Document</span>
                                                    </a>
                                                    {!currentPageLegalReviewStatus &&
                                                        <button onClick={() => { dispatch(setShowRequestLegalReviewAside(true)) }}
                                                            className="cursor-pointer flex flex-row w-fit p-[12px] my-[12px] text-[#FFF] rounded-[8px] border border-[#E0E0E0] bg-[#18475A] text-[14px] leading-[19.6px] font-[500] gap-[8px]"
                                                        >
                                                            <Briefcase size={16} className="text-white my-auto" />
                                                            <span className="my-auto">Request Legal Review</span>
                                                        </button>
                                                    }
                                                </div>
                                            )
                                        }
                                        {
                                            !threadData?.document?.url && threadData?.document?.google_doc_id && !awaitingResponse && (
                                                <div className="flex flex-col w-[736px] mx-auto">
                                                    <a href={`https://docs.google.com/document/d/${threadData?.document?.google_doc_id}`} target="_blank" rel="noreferrer"
                                                        className="cursor-pointer flex flex-row w-fit p-[12px] my-[12px] text-[#FFF] rounded-[8px] border border-[#E0E0E0] bg-[#18475A] text-[14px] leading-[19.6px] font-[500] gap-[8px]"
                                                    >
                                                        <ShareFat size={16} className="my-auto text-white" />
                                                        <span className="my-auto">View Documents</span>
                                                    </a>
                                                    {!currentPageLegalReviewStatus &&
                                                        <button onClick={() => { dispatch(setShowRequestLegalReviewAside(true)) }}
                                                            className="cursor-pointer flex flex-row w-fit p-[12px] my-[12px] text-[#FFF] rounded-[8px] border border-[#E0E0E0] bg-[#18475A] text-[14px] leading-[19.6px] font-[500] gap-[8px]"
                                                        >
                                                            <Briefcase size={16} className="text-white my-auto" />
                                                            <span className="my-auto">Request Legal Review</span>
                                                        </button>}
                                                </div>
                                            )
                                        }

                                        {awaitingResponse && (

                                            <div className="mt-[12px] w-[736px] mx-auto">

                                                <>{threadType === "review" && <ReviewShimmer steps={steps} />}</>
                                                <>{threadType === "ask" && <AskShimmer steps={steps} />}</>
                                                <>{threadType === "draft" && <DraftShimmer steps={steps} />}</>
                                            </div>
                                        )}
                                    </div>
                                    <div className="relative flex flex-col w-full ">
                                        {isScrollToBottomButtonVisible && <div onClick={() => bottomRef.current.scrollIntoView({ behavior: 'smooth' })} className='absolute px-1 py-1 text-gray-500 -translate-x-1/2 border !border-gray-400 rounded-full cursor-pointer -top-4 bg-white left-1/2'><ArrowDown size={16} /></div>}
                                        <ChatBoxComponent
                                            size="full"
                                            inputMessage={inputMessage}
                                            setInputMessage={setInputMessage}
                                            onsubmit={onsubmit}
                                        />
                                    </div>
                                </>
                            )}
                            {getThreadStatus === "loading" && (
                                <div className="w-full h-full flex flex-col justify-center items-center gap-[8px]">
                                    <div className="w-8 h-8 border-2 border-t-0 border-[#000] rounded-full animate-spin"></div>
                                    <p className="text-black">Fetching thread data...</p>
                                </div>
                            )}
                            {getThreadStatus === "failed" && (
                                <div className="w-full h-full flex flex-col justify-center items-center gap-[8px]">
                                    {/* <div className="w-8 h-8 border-2 border-t-0 border-[#000] rounded-full animate-spin"></div> */}
                                    <p className="text-center text-black">Failed to fetch thread data. <br /> Please refresh the page and try again</p>
                                </div>
                            )}
                        </div>

                    </div>
                    {showAskToDraftModal && (
                        <AskToDraftModal />
                    )}

                    {draftDocumentModalOpen && (
                        <DraftDocumentQuestionaireModal
                            email={email}
                            ref={chatpageRef} />
                    )}
                    {showRequestLegalReviewAside &&
                        <LegalReviewRequestAside />
                    }
                    {(showSubscriptionPopup || showUpgradingPlan || showBusinessPlanUnlocked || showFindBestLawyer || showMeetingConfirmation || showNextSteps || showScheduleMeeting) &&
                        <UpSellFlowPopup />
                    }

                </BaseLayout >
            )
                : <>
                    {
                        (isLoaded) ?
                            <div className="flex items-center justify-center w-full h-full">
                                <p className="text-red-500">Failed to load user details. Please refresh the page.</p>
                            </div> :
                            <div className="w-full h-full flex justify-center items-center mx-auto mt-[200px]">
                                {/* <div className="spinner-grow spinner-grow-sm text-primary me-3" role="status"></div> */}
                                {/* <div class="rotating-cursor !h-[50px]"></div> */}
                                <p className="text-black text-[40px]">{isLoaded}</p>
                            </div>
                    }
                </>
            }
            <SavedDataModalComponent />
        </>
    );
};

export default ChatPageComponent;