import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Pencil, FloppyDisk } from "@phosphor-icons/react";
import { isDisabled } from '@testing-library/user-event/dist/utils';
const FollowupInputPrompt = ({ index, question, answers, setAnswers, submitAnswer, button, activeIndex, google_doc_id, disabled, isDocumentGenerated, skipQuestion }) => {
    const [inputValue, setInputValue] = React.useState(answers[index]);
    const [edit, setEdit] = useState(true)

    const threadData = useSelector(state => state.messageSlice.threadData);

    useEffect(() => {
        if (inputValue) {
            setAnswers({ ...answers, [index]: inputValue });
        }
    }, [inputValue]); // eslint-disable-line
    // const questionsanswered = useSelector(state => state.messageSlice.threadData.questions.answers);
    // const skipped = useSelector(state => state.messageSlice.threadData.questions.skipped);

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey && inputValue) {
            submitAnswer(question, index, inputValue);
            setEdit(true)
        }
    };

    const handelEdit = () => {
        setEdit(false)
    }
    const handelSave = () => {
        submitAnswer(question, index, inputValue);
        setEdit(true)
    }
    return (
        <div className='flex justify-center flex-col items-center gap-[8px]'>
            <div className='flex justify-center items-center m-0 w-full px-[10px]'>
                <div className='flex flex-row justify-between w-full items-center border border-collapse border-[#E0E0E0] rounded-[8px]'>
                    <div className="flex w-full ">
                        <input type="text"
                            placeholder="Type your answer"
                            className="w-full cursor-pointer disabled:cursor-auto rounded-[8px] px-[14px] py-[12px]"
                            value={inputValue}
                            disabled={(edit && disabled) || threadData?.isSharedThread}
                            onChange={(e) => { setInputValue(e.target.value) }}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                    {!threadData?.isSharedThread ? <>

                        {disabled && <div> {edit ? !isDocumentGenerated && <Pencil size={20} className='mx-2 cursor-pointer' onClick={handelEdit} /> :
                            !isDocumentGenerated && <FloppyDisk size={20} className='mx-2 cursor-pointer' onClick={handelSave} />} </div>}
                    </> : null}
                </div>
            </div>
            {(!disabled || (disabled && !edit)) && (
                <div className='flex justify-between gap-[8px] w-full px-[14px]'>
                    <p className='flex flex-row text-[#13866F] gap-[8px]'>
                        {/* <Sparkle size={14.5} className='my-auto' weight='bold' />
                                                    <span className='text-[14px] my-auto leading-[22.5px] font-[600]'>Explain this</span> */}
                    </p>
                    <div className='flex flex-row gap-[8px]'>
                        <button className='border-0 text-[#001613] text-[14px] leading-[22.4px] font-[600] rounded-[8px] p-[8px] cursor-pointer'
                            onClick={() => skipQuestion(question, index)}>Skip</button>
                        <button className='bg-[#13866F] text-[#fff] rounded-[4px] px-[16px] h-[30px] text-[14px] cursor-pointer disabled:cursor-default leading-[22.4px] my-auto align-items-center'
                            disabled={answers[index] === undefined || answers[index] === null || answers[index] === ""}
                            onClick={() => submitAnswer(question, index, answers[index])}
                        >Next</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FollowupInputPrompt;