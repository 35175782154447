import React from 'react';

interface ButtonProps {
    text: string | React.ReactElement;
    onClick?: () => void;
    className?: string;
    secondary?: boolean;
    disabled?: boolean
}

function Button({ text, onClick, className, secondary, disabled }: ButtonProps) {
    return (
        <button
            className={`bg-[#18475A] text-white font-medium py-2 px-6 rounded-lg flex items-center ${secondary ? 'bg-white !text-[#264653] border border-[#D1D1D1]': ''} 
            ${disabled ? '!text-gray-400 border !cursor-not-allowed !border-gray-300 !bg-white': ''} ${className ? className : ''}`}
            onClick={disabled ? () => {} : onClick}
        >
            {text}
        </button>
    );
}

export default Button;
