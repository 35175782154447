import { Sparkle } from "@phosphor-icons/react";
import React, { useEffect } from "react";
import WhiteRightArrowImage from "../../../../images/icons/arrow-right-white.svg";
import { SealCheck } from "@phosphor-icons/react/dist/ssr";

const DocumentDetailsScreenComponent = (props) => {
  const {
    documentType,
    // setDocumentType,
    draftTypeSuggestions,
    continueToConcernedParties,
    handleChange,
    isDropdownVisible,
    filteredSuggestions,
    handleSuggestionClick,
    selectedDocumentTypeTile,
    // setSelectedDocumentTypeTile,
    documentSuggestionClicked,
    browseAllClicked,
    onDropDownBlur,
  } = props;

  const [randomName, setRandomName] = React.useState(Math.random().toString(36).substring(2, 15));

  return (
    // <></>
    <>
      <div className="flex flex-col gap-[32px] py-[20px] w-[748px] mx-auto">
        <div className="flex flex-col gap-[16px]">
          <div className="flex flex-col ">
            <h3 className="text-[18px] text-[#1414114] leading-[28.8px] font-[600]">What would you like to draft?</h3>
            <p className="text-[14px] font-[400] leading-[21px] text-[#686868]">
              If you're unsure of the document name, just describe what you need in plain English
            </p>
          </div>
          <div className="relative flex flex-col">
            <input
              type="text"
              id="document-type-input"
              className="border border-[#E0E0E0] focus:border-[#18475A] rounded w-full h-[56px] px-[16px] text-[14px] leading-[24px]"
              placeholder="e.g. Non-Disclosure Agreement"
              value={documentType?.name}
              name={randomName}
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={onDropDownBlur}
            />
            {filteredSuggestions.length === 0 && documentType?.name?.length > 0 && !(documentType?.id) && (
              <p className="text-[#137513] font-[500] mt-[10px] text-[12px] leading-[18px] flex gap-[6px]"><SealCheck className="text-[#137513] my-auto" size={16} /><span className="my-auto">Got it! Our AI will create a draft according to these details.{" "}</span></p>
            )}

            {isDropdownVisible && filteredSuggestions.length > 0 && (
              <div className="absolute w-full bg-white border border-gray-300 rounded-md shadow-lg mt-[55px] z-10 pb-[10px]">
                <div className="px-4 py-2 mt-[8px] flex flex-row gap-[12px]">
                  <Sparkle size={16} className="my-auto text-[#18475A]" />
                  <span className="text-[#748A8C] text-[14px] font-[600] my-auto">Suggestions</span>
                </div>
                <ul className="max-h-[250px] overflow-y-scroll">
                  {filteredSuggestions.slice(0, 7).map((suggestion, index) => (
                    <li
                      key={index}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-[20px]">
          <div className="flex flex-col w-full bg-white gap-[24px]">
            <div className="flex">
              <p className="text-[16px] leading-[25.6px] font-[600] flex flex-row gap-[8px]">
                <span className="my-auto">
                  <Sparkle size={24} className="text-[#248370]" />
                </span>
                <span className="my-auto text-[#248370] italic font-[Lora]">Suggested for you</span>
              </p>
            </div>
            <div className="grid grid-cols-3 w-[748px] mx-auto gap-[12px]">
              {draftTypeSuggestions.slice(0, 5).map((suggestion, index) => (
                <div
                  className={`flex flex-col border px-[16px] py-[24px] rounded-[4px] w-[241.33px] gap-[10px] cursor-pointer ${documentType?.id === suggestion.id
                    ? "!border-[#248370] text-[#000] bg-[#ECF5F3]"
                    : "border-[#E4E4E4]"
                    }`}
                  key={index}
                  onClick={() => documentSuggestionClicked({ suggestion: suggestion, index: index })}
                >
                  <p className="text-[#000] text-[14px] leading-[24px] font-[500]">{suggestion.name} </p>
                  <p className="text-[#686868] text-[12px] leading-[24px] font-[400]">{suggestion.description} </p>
                </div>
              ))}
              <div
                className="flex flex-col border px-[16px] py-[24px] rounded-[4px] w-[241.33px] gap-[10px] cursor-pointer"
                onClick={() => {
                  browseAllClicked();
                }}
              >
                <p className="text-[#686868] text-[14px] leading-[24px] font-[400]"> More Suggestions </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className={`bg-[#18475A] disabled:bg-[#949494] text-white py-2 px-4 rounded-lg self-end mr-[56px] flex flex-row gap-[10px]`}
        onClick={() => {
          continueToConcernedParties();
        }}
        disabled={!documentType?.name || documentType?.name?.length < 3}
        id="document-draft-continue-1"
      >
        Continue <img src={WhiteRightArrowImage} className="pt-[4px]" alt="->" />
      </button>
    </>
  );
};

export default DocumentDetailsScreenComponent;
