import React from 'react'
import Modal from 'src/components/Modal'
import Button from './Button'
import { useDispatch, useSelector } from 'react-redux'
import { setShowLearnMoreModal } from '../onboarding/onboardingSlice'

const items = [
    {
        img: '/images/reliability-1.png',
        text: 'Get matched with a lawyer experienced in this specific area. '
    },
    {
        img: '/images/reliability-2.png',
        text: 'Your lawyer will spend up to 30 minutes reviewing your information and the AI-work '
    },
    {
        img: '/images/reliability-3.png',
        text: 'Receive a professional legal assessment with actionable next steps within two business days'
    },
]

const ReliabilityGuaranteedPopup = () => {
    const dispatch = useDispatch();
    const showLearnMoreModal = useSelector((state) => state.onboarding.showLearnMoreModal);

    const closePopup = () => {
        dispatch(setShowLearnMoreModal(false));
    }
    return (
        <Modal
            contentAreaStyles={{ paddingLeft: '0px', paddingRight: '0px' }}
            isOpen={showLearnMoreModal}
            bodyStyle={{ paddingBottom: '0px', paddingTop: '40px' }}
            onClose={() => { closePopup() }}
        >
            <div className='px-10'>
                <div>
                    <h2 className='text-[#141414] text-2xl font-semibold'>Peace of Mind Is One Click Away</h2>
                    <p className='text-[#353535] text-lg font-medium '>Let a seasoned lawyer finalize your task with confidence</p>
                    <img src='/images/realiability.png' className='w-[145px] h-[145px] absolute right-10 top-10' />
                </div>
                <ul className='mt-10'>
                    {items.map((item, index) => (
                        <li className={`flex items-center ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}>
                            <img src={item.img} className='w-[416px] h-[190px]' />
                            <p className='w-[500px] h-[190px] flex items-center  px-8 text-[#141414] font-medium text-lg' >{item.text}</p>
                        </li>
                    ))}
                </ul>
            </div>
            <hr className='mt-6 text-[#E4E4E4]' />
            <div className='flex items-center justify-between px-10 my-4'>
                <p className='text-sm font-medium text-[#353535]'>See <span className='font-semibold underline text-[#18475A]'>Terms & Conditions</span> for more info</p>
                <Button text={'Ok, got it'}
                    onClick={() => { dispatch(setShowLearnMoreModal(false)) }} />
            </div>
        </Modal>
    )
}

export default ReliabilityGuaranteedPopup