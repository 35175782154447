import React from 'react'
import { Info } from "@phosphor-icons/react";

// interface TextInputProps {
//     label: string
//     value?: string
//     onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
//     placeholder?: string;
//     wrapperClassName?: string;
// }

const TextInput = (props) => {

    const {
        label = 'Text Input',
        value,
        onChange,
        placeholder,
        wrapperClassName,
        tooltip,
        error
    } = props;

    return (
        <div className={`flex flex-col ${wrapperClassName}`}>
            <div className='flex justify-start gap-2 items-center'><label className='text-[#302F2F] text-xs md:text-base font-semibold'>{label}</label><div className='relative group'>
                {tooltip ? <Info size={17} className='cursor-pointer' /> : ""}
                {/* Tooltip */}
                <div className='absolute w-80 bg-[#686868] hidden group-hover:block text-white text-xs p-1.5 rounded-md ml-6 bottom-1  '>
                    {tooltip}
                </div>
            </div>  </div>

            <input
                className='border w-full outline-none border-[#E4E4E4] rounded-full h-[48px] mt-2 text-sm px-4 text-[#302F2F] font-semibold placeholder:font-normal  focus:!border-[#18475A]'
                value={value}
                onChange={onChange}
                placeholder={placeholder}
            />
            {error && <p className='text-red-500 text-xs mt-1'>{error}</p>}

        </div>
    )
}

export default TextInput