import React from 'react'
import SubscriptionCard from './SubscriptionCard';
import { ShieldCheck } from '@phosphor-icons/react';

const PlanCards = (props) => {

    const {
        wrapperClassName,
        PLAN_TYPES,
        setPlan,
        checkoutUrlStatus,
        cards = [],
        billCycle = 'MONTHLY',
    } = props;

    return (
        <>
            <div className={`flex gap-6 ${wrapperClassName}`}>

                {
                    cards.map(cardProps => (
                        <SubscriptionCard data={cardProps} billCycle={billCycle} PLAN_TYPES={PLAN_TYPES} setPlan={setPlan} checkoutUrlStatus={checkoutUrlStatus} />
                    ))
                }

            </div>

            {/* <p className='mt-6 text-xs text-center'>Subscribing provides a dedicated lawyer tailored to your needs. All legal tasks reviewed by our lawyers are guaranteed to be 100% accurate and reliable</p> */}
            <ul className='flex justify-center gap-6 mt-10'>
                <li className='flex items-center bg-[#137513]/10 rounded-full px-3 py-1 text-xs font-semibold'><ShieldCheck weight='fill' size={20} className='text-[#137513]/60 mr-2' />100% Money Back Guarantee </li>
                <li className='flex items-center bg-[#137513]/10 rounded-full px-3 py-1 text-xs font-semibold'><ShieldCheck weight='fill' size={20} className='text-[#137513]/60 mr-2' />100% Reliability Guaranteed </li>
            </ul>

        </>
    )
}

export default PlanCards