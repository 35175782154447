import React from "react";
import { Calendar, Clock, User, Globe, VideoCamera, CheckCircle, ArrowUpRight } from "@phosphor-icons/react";
import Button from "../Button";
import { useDispatch } from "react-redux";
import { setShowMeetingConfirmation, setShowNextSteps } from "src/pages/onboarding/onboardingSlice";

const MeetingConfirmation = () => {

    const dispatch = useDispatch();
    const handleNext = () => {
        dispatch(setShowMeetingConfirmation(false));
        dispatch(setShowNextSteps(true));
    }

    return (
        <div className="flex flex-col items-center justify-center py-8 ">
            {/* Profile Image and Status */}
            <div className="flex flex-col items-center space-y-2">
                <img
                    src="https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg"
                    alt="Profile"
                    className="object-cover w-16 h-16 rounded-full"
                />
                <div className="flex items-center space-x-2">
                    <CheckCircle size={24} color="#3b82f6" weight="bold" />
                    <h2 className="text-xl font-bold text-[#1A1A1A]">You are scheduled</h2>
                </div>
                <p className="text-sm px-32  font-normal text-[#737373]">
                    A calendar invitation has been sent to your email address.
                </p>
            </div>

            {/* Meeting Details Card */}
            <div className="w-full max-w-md p-4 mt-6 bg-white border border-gray-200 rounded-lg ">
                <h3 className="text-lg font-semibold text-[#1A1A1A]">John {'<>'} InHouse Lawyer Requirements</h3>
                <div className="mt-4 space-y-2 text-[#1A1A1A99]/60 text-base font-semibold">

                    <div className="flex items-center space-x-2">
                        <User size={20} color="#6b7280" />
                        <p>Justin Doe</p>
                    </div>

                    <div className="flex items-center space-x-2">
                        <Calendar size={20} color="#6b7280" />
                        <p>19:00 – 19:45, Monday, October 19, 2024</p>
                    </div>

                    <div className="flex items-center space-x-2">
                        <Globe size={20} color="#6b7280" />
                        <p>Asia/Yerevan</p>
                    </div>

                    <div className="flex items-center space-x-2">
                        <VideoCamera size={20} color="#6b7280" />
                        <p>Web conferencing details to follow.</p>
                    </div>
                </div>
            </div>

            {/* View Meeting Link */}
            <div className="flex items-center justify-between w-full px-10 mt-20">
                <a
                    href="#"
                    className=" text-sm text-[#18475A] font-medium flex items-center "
                >
                    View Meeting Link <ArrowUpRight className="ml-2" size={16} />
                </a>

                {/* Next Button */}
                <Button
                    className={'py-2 text-sm'}
                    text={
                        <>Next &rarr;</>
                    }
                    onClick={handleNext}
                ></Button>
            </div>
        </div>
    );
}

export default MeetingConfirmation;
