import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from "@phosphor-icons/react";
import { deleteThread } from '../../../../pages/chatpage/threadsSlice';
import { toast } from 'react-toastify';

const DeleteThreadModal = (props) => {

    const {
        open,
        setActionOnThread,
        threadInfo,
    } = props;

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [text, setText] = useState("")
    const thread = threadInfo.thread;
    const duration = threadInfo.duration;
    const deleteThreadStatus = useSelector((state) => state.threads.deletedThread);

    const legalReviewRequested = thread?.legal_review_requested;
    const legalReviewSubmitted = thread?.legal_review_submitted;

    useEffect(() => {
        if (deleteThreadStatus?.status === "success" || deleteThreadStatus?.status === "failed") {
            setActionOnThread("");
            setLoading(false);
        }
        switch (deleteThreadStatus?.status) {
            case "success":
                setText(`Deleted ${threadInfo?.title}`)
                // dispatch(addToast({ type: "success", message: `Deleted ${threadInfo?.thread?.title}` }))
                toast.success(`Deleted ${threadInfo?.thread?.title}`)
                break;
            case "failed":
                setText(`Failed to delete ${threadInfo?.thread?.title}. Retry again`)
                break;
            case "loading":
                setText(`Deleting ${threadInfo?.thread?.title}`)
                break;
            default:
                setText(`This will delete ${threadInfo?.thread?.title}`)
        }

    }, [deleteThreadStatus, threadInfo]);

    return (
        <Modal
            centered
            open={open}
            onOk={() => setActionOnThread("")}
            onCancel={() => setActionOnThread("")}
            className="review-pop-up h-full rounded-[30px] bg-[#fff] overflow-hidden "
            footer={null}
        >
            <div className="w-full md:w-[600px] h-full flex flex-col items-start px-[30px] py-[40px] gap-[24px]">
                <span className="text-black text-[30px] font-[800] leading-[1]">Delete chat?</span>
                {legalReviewRequested && !legalReviewSubmitted ? (
                    <span className="text-black text-[16px] font-[500] leading-[1]">
                        This thread cannot be deleted as it has a legal review pending.
                    </span>
                ) : (
                    <span className="text-black text-[16px] font-[500] leading-[1]">
                        {text}
                    </span>
                )}

                {legalReviewRequested && !legalReviewSubmitted ? (
                    ""
                ) : (
                    <div className="flex items-center justify-start w-full gap-4 h-full ">
                        <button
                            className="text-[16px] font-[700] text-white leading-[1] bg-[#5E59FF] py-[12px] px-[50px] rounded-[10px] w-[150px] flex items-center justify-center "
                            onClick={() => {
                                dispatch(deleteThread({ id: thread?.id, duration: duration }));
                            }}
                            disabled={deleteThreadStatus?.status === "loadng"}
                            id="delete-chat-button"
                        >
                            {deleteThreadStatus?.status === "loading" ? <Spinner size={16} className="animate-spin" /> : "Delete"}
                        </button>
                        <button
                            className="text-[16px] font-[700] text-[#626262] leading-[1] bg-none py-2 px-4 rounded-[4px]"
                            onClick={() => setActionOnThread("")}
                            disabled={deleteThreadStatus?.status === "loadng"}
                            id="cancel-delete-chat-button"
                        >
                            Cancel
                        </button>
                    </div>
                )}
            </div>
        </Modal >
    )
}

export default DeleteThreadModal