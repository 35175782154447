/* eslint-disable jsx-a11y/aria-role */
import React, { useEffect, useState } from "react";
import AccountType from "./AccountType";
import { Buildings, User } from "@phosphor-icons/react";
import IndividualForm from "./IndividualForm";
import Button from "../LawyerHub/Button";
import BusinessForm from "./BusinessForm";
import Footer from "./Footer";
import { SignOutButton, useAuth, useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { createUserAPI, getUserMetadata, setShowUpgradePlanNudge } from "../onboarding/onboardingSlice";
import { createNewParty, getSavedParties } from "../../pages/Homepage/HomeSlice";
import InhouseLogo from "../../images/inhouse-logo-v2.png";
import OnboardingLeftImg from "../../images/onboarding-left.svg";
import { trackAnalytics } from "../helpers";

const OnboardingV2 = ({ user, getToken, sidenav, moveNext }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const utm_source = queryParams.get("utm_source");
  const next = queryParams.get("next");

  const [firstName, setFirstName] = useState("");
  const [fullName, setFullName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  // const [industry, setIndustry] = useState("");
  const [businessPurpose, setBusinessPurpose] = useState("");
  const [entityStructure, setEntityStructure] = useState("");
  const [businessState, setBusinessState] = useState("");
  const [incorporationState, setIncorporationState] = useState("");
  // const [profileUpdated, setProfileUpdated] = useState(false);
  // const [profileUpdationError, setProfileUpdationError] = useState(false);
  const [isEntity, setIsEntity] = useState(true);
  const [createUserAttemptCount, setCreateUserAttemptCount] = useState(0);
  const [notice, setNotice] = useState("");
  const [errors, setErrors] = useState({});

  const [showLoading, setShowLoading] = useState(false);
  const [address, setAddress] = useState("");

  const dispatch = useDispatch();
  const createNewPartyStatus = useSelector((state) => state.homeslice.createNewPartyStatus);
  // const error = useSelector((state) => state.onboarding.error);
  const createUserAPIStatus = useSelector((state) => state.onboarding.createUserAPIStatus);
  const userAlreadyExist = useSelector((state) => state.onboarding.userAlreadyExist);
  const partiesList = useSelector((state) => state.homeslice.partiesList);
  const userMetadataFetchStatus = useSelector((state) => state.onboarding.userMetadataFetchStatus);

  // const { user } = useUser();
  // const { getToken } = useAuth();

  const getMyToken = async () => {
    const token = await getToken({ template: "Backend" });
    return token;
  };
  const getTokenValue = async () => {
    const token = await getMyToken();
    if (token && typeof token === "string") {
      localStorage.setItem("accessToken2", token);
    }
  };

  useEffect(() => {
    setNotice("");
    getTokenValue();
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.analytics.page("Onboarding page viewed", { user_email: user?.primaryEmailAddress?.emailAddress });
    if (utm_source === "signup") {
      setTimeout(() => {
        dispatch(createUserAPI());
      }, 3000);
      // if (!sidenav) {
      //   trackAnalytics("User signed up: redirected to onboarding page", {
      //     user_email: user?.primaryEmailAddress?.emailAddress,
      //   });
      // }
      // window.analytics.track("User signed up: redirected to onboarding page", { "user_email": user?.primaryEmailAddress?.emailAddress, })
    } else {
      dispatch(getUserMetadata());
      dispatch(getSavedParties());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (createUserAPIStatus === "success") {
      dispatch(getUserMetadata());
      // trackAnalytics("User created backend", { user_email: user?.primaryEmailAddress?.emailAddress });
      // window.analytics.track("User created backend", { "user_email": user?.primaryEmailAddress?.emailAddress, })
    } else if (createUserAPIStatus === "failed" && userAlreadyExist) {
      dispatch(getUserMetadata());
      dispatch(getSavedParties());
    }
  }, [createUserAPIStatus, userAlreadyExist]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userMetadataFetchStatus === "failed") {
      if (createUserAttemptCount < 2) {
        setCreateUserAttemptCount(createUserAttemptCount + 1);
        dispatch(createUserAPI());
      }
      dispatch(getSavedParties());
    }
  }, [userMetadataFetchStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user) {
      var entity = user?.unsafeMetadata?.entity_type || "";
      if (entity.toLowerCase() === "individual") {
        entity = "Corporation";
      }
      var is_not_personal = user?.unsafeMetadata?.is_entity;
      if (is_not_personal === null || is_not_personal === undefined) {
        is_not_personal = true;
      }
      setFullName(user.fullName);
      setOrganizationName(user?.unsafeMetadata?.organization_name);
      setBusinessState(user?.unsafeMetadata?.state ?? "");
      setIncorporationState(user?.unsafeMetadata?.state_incorporation ?? null);
      setEntityStructure(entity);
      setBusinessPurpose(user?.unsafeMetadata?.business_purpose);
      setAddress(user?.unsafeMetadata?.address);
      setIsEntity(is_not_personal);
    }
  }, [user]);

  useEffect(() => {
    if (createNewPartyStatus === "success") {
      setShowLoading(false);
      dispatch(getUserMetadata());
      dispatch(getSavedParties());
      setTimeout(() => {
        trackAnalytics("profile_updated", { user_email: user?.primaryEmailAddress?.emailAddress, "source": sidenav ? "sidenav" : "onboarding" });
        moveNext(next);
      }, 500);
    } else if (createNewPartyStatus === "failed") {
      setShowLoading(false);
      setNotice("Error saving data. Please try again.");
    }
  }, [createNewPartyStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const spaceIndex = fullName?.indexOf(" ");
    if (spaceIndex && spaceIndex !== -1) {
      const firstName = fullName.slice(0, spaceIndex);
      const lastName = fullName.slice(spaceIndex + 1);
      setFirstName(firstName);
      setLastName(lastName);
    } else {
      console.log("setting last name as blank");
      setFirstName(fullName || "");
      setLastName(""); // Reset last name
    }
  }, [fullName]);


  const clearTokens = () => {
    localStorage.removeItem("accessToken2");
    localStorage.removeItem("refreshToken2");
    localStorage.removeItem("user");
    localStorage.removeItem("userType");
    localStorage.removeItem("client_details");
    localStorage.removeItem("lawyer_info");
    localStorage.removeItem("showForm");
  };

  const submit = async () => {
    setShowLoading(true);
    setNotice("");
    // setProfileUpdated(false)
    // setProfileUpdationError(false);
    var isFormValid = true;

    // const requiredFields = [
    //   "full_name",
    //   organizationName,
    //   "business_purpose",
    //   "state",
    //   "state_incorporation",
    //   "entity_type",
    // ];
    // const isFormValid = requiredFields.every((field) => field !== "" && field !== null);
    var formErrors = {}
    if (isEntity) {
      if (!organizationName) {
        isFormValid = false;
        formErrors.organizationName = "Please enter your Company Name";
      }
      if (!businessState) {
        isFormValid = false;
        formErrors.businessState = "Please enter your Primary State of Business";
      }
      if (!incorporationState) {
        isFormValid = false;
        formErrors.incorporationState = "Please enter your State of Incorporation";
      }
      if (!entityStructure) {
        isFormValid = false;
        formErrors.entityStructure = "Please enter your Entity Structure";
      }
      if (!businessPurpose) {
        isFormValid = false;
        formErrors.businessPurpose = "Please enter your Business Purpose";
      }
    }
    else {
      if (!organizationName) {
        isFormValid = false;
        formErrors.organizationName = "Please enter your Full Name";
      }
      if (!incorporationState) {
        isFormValid = false;
        formErrors.incorporationState = "Please enter your State of Residence";
      }
    }
    if (!isFormValid) {
      setErrors(formErrors);
      setShowLoading(false);
      return;
    }

    user.update({
      unsafeMetadata: {
        organization_name: organizationName,
        state: businessState,
        phone_number: user?.primaryPhoneNumber?.phoneNumber ? user?.primaryPhoneNumber?.phoneNumber : "",
        email: user?.primaryEmailAddress?.emailAddress,
        phone: user?.primaryPhoneNumber?.phoneNumber ? user?.primaryPhoneNumber?.phoneNumber : "",
        age_of_business: user?.unsafeMetadata?.age_of_business,
        num_employees: user?.unsafeMetadata?.num_employees,
        current_legal_source: user?.unsafeMetadata?.current_legal_source,
        legal_work_frequency: user?.unsafeMetadata?.legal_work_frequency,
        legal_work_type: user?.unsafeMetadata?.legal_work_type,
        annual_legal_spend: user?.unsafeMetadata?.annual_legal_spend,
        business_purpose: businessPurpose,
        entity_type: entityStructure,
        state_incorporation: incorporationState,
        address: address,
        is_entity: isEntity,
      },
      firstName: firstName,
      lastName: lastName,
    });

    var entityInfo = null;
    var individualInfo = null;
    if (isEntity) {
      entityInfo = {
        type: entityStructure,
        state_incorporation: incorporationState,
        primary_business_state: businessState,
        address: address,
        business_purpose: businessPurpose,
      };
    } else {
      individualInfo = {
        state_residence: incorporationState,
        description: businessPurpose,
        address: address,
      };
    }
    var primaryId = null;
    if (partiesList.length > 0) {
      var primaryParty = partiesList.filter((party) => party.is_primary === true);
      if (primaryParty.length > 0) {
        primaryId = primaryParty[0].id;
      }
    }

    const data = {
      id: primaryId,
      is_primary: true,
      full_name: organizationName,
      entity_info: entityInfo,
      individual_info: individualInfo,
    };
    dispatch(createNewParty(data));
  };

  return (
    <>
      <div className={`flex ${sidenav ? "flex-col" : "flex-col md:flex-row"} h-screen max-h-screen md:overflow-hidden`}>
        <img
          className="bg-transparent my-2 self-start h-[50px] object-contain bg-white px-10 block md:hidden"
          src={InhouseLogo} alt="Inhouse"
        />
        {!sidenav &&
          <div
            role="left side"
            className=" bg-[#F7EEE5] w-full md:w-1/3 lg:w-1/2 h-auto md:h-screen px-4 md:px-10 py-4 pb-0 md:py-10 md:flex flex-col justify-between"
          >
            <img className="w-[199] self-start h-[50px] object-contain hidden md:block" src={InhouseLogo} alt="Inhouse" />
            <div className="flex  justify-center items-center md:flex-col">
              <h1 className="text-[#141414] md:hidden text-base md:text-xl lg:text-[32px] font-bold font-[Lora] italic">
                Let’s tailor the Inhouse AI for you
              </h1>
              <img className="h-[400px] md:h-[600px] 2xl:h-[600px]  object-cover" src={OnboardingLeftImg} alt="Inhouse" />
            </div>
            <p className="font-medium text-center hidden md:block md:text-base lg:text-xl pb-10">
              {/* Get personalised AI responses that <br />
              are tailored just for your needs */}Take a minute to tailor the AI to your specific needs.
            </p>
          </div>
        }
        <div
          role="right side"
          className={`flex flex-col w-full md:h-screen px-4 md:overflow-y-scroll  bg-[#F7EEE5] ${!sidenav ? "md:bg-white md:w-2/3 lg:w-1/2" : "w-full py-5"} `}
        >
          <div className="w-full shadow-md md:shadow-none bg-white px-4 mb-6 rounded-3xl">
            {!sidenav &&
              <p className="flex flex-row justify-end w-full my-3">
                <span className="cursor-pointer hidden md:block" onClick={() => { window.analytics.reset(); clearTokens() }}>
                  <SignOutButton redirectUrl={process.env.REACT_APP_POST_SIGNOUT_URL} />
                </span>
              </p>
            }
            <h1 className="text-[#141414] hidden text-base md:text-xl lg:text-[32px] font-bold font-[Lora] italic">
              Let’s tailor the Inhouse AI for you
            </h1>
            <p className="text-[#686868] text-xs mt-2 lg:text-base font-medium border-b pb-6">
              This information will help InHouse generate results tailored to your needs
            </p>
            {!sidenav && (<>
              <h2 className="text-[#141414] text-xs lg:text-base font-semibold mt-6">Choose Account Type</h2>

              <section role="account type selection" className="flex gap-6 mt-3 ">
                <AccountType
                  icon={Buildings}
                  type="Business"
                  onClick={() => {
                    setIsEntity(true);
                    setNotice("");
                  }}
                  selected={isEntity}
                />
                <AccountType
                  icon={User}
                  type="Personal"
                  onClick={() => {
                    setIsEntity(false);
                    setNotice("");
                  }}
                  selected={!isEntity}
                />
              </section>
            </>)
            }

            <section role="form" className="h-auto pb-4 mt-3 ">
              {!isEntity ? (
                <IndividualForm
                  organizationName={organizationName}
                  incorporationState={incorporationState}
                  businessPurpose={businessPurpose}
                  setOrganizationName={(e) => setOrganizationName(e.target.value)}
                  setPurposeChange={(e) => setBusinessPurpose(e.target.value)}
                  setIncorporationState={(e) => setIncorporationState(e.target.value)}
                  errors={errors}
                />
              ) : (
                <BusinessForm
                  organizationName={organizationName}
                  businessPurpose={businessPurpose}
                  entityStructure={entityStructure}
                  businessState={businessState}
                  incorporationState={incorporationState}
                  setCompanyChange={(e) => setOrganizationName(e.target.value)}
                  setPurposeChange={(e) => setBusinessPurpose(e.target.value)}
                  setPrimaryStateChange={(e) => setBusinessState(e.target.value)}
                  setIncorporationStateChange={(e) => setIncorporationState(e.target.value)}
                  setEntityStructureChange={(e) => setEntityStructure(e.target.value)}
                  errors={errors}
                />
              )}
              {notice && <p className="w-full py-3 text-center text-red-500">{notice}</p>}
            </section>

            <div className="flex justify-end py-6">
              <Button
                className="!rounded-full bg-[#1B2B48] text-base"
                text={
                  !showLoading ? (
                    "Update"
                  ) : (
                    <div className="w-6 h-6 border-2 border-t-0 border-[#fff] rounded-full animate-spin"></div>
                  )
                }
                onClick={submit}
              ></Button>
            </div>
            <section className="md:hidden">
              <Footer />
            </section>
          </div>
        </div>
      </div>
      {/* {isLoaded && !isSignedIn && navigate("/signin")} */}
    </>
  );
};

export default OnboardingV2;
