import { User } from '@phosphor-icons/react'
import React from 'react'

interface AccountTypeProps {
    icon: any;
    type: string;
    selected?: boolean;
    onClick: () => void;
}

const AccountType = (props: AccountTypeProps) => {

    const {
        icon = User,
        type = 'Individual',
        selected,
        onClick
    } = props;

    const Icon = icon

    return (
        <div className={`cursor-pointer border border-[#E4E4E4] w-[155px] h-[64px] lg:w-[151px] lg:h-[44px] rounded-2xl flex  text-[#353535] text-xs md:text-sm font-medium justify-center items-center ${selected && '!border-[#1B2B48] !border-[1.5px] text-[#1B2B48] font-semibold '}`} onClick={onClick}>
            <Icon size={18} weight={selected ? 'bold' : 'regular'} />
            <p className='ml-3'>{type}</p>
        </div>
    )
}

export default AccountType