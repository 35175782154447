import React from 'react';

const IframeTest = () => {
    return (
        <div>
            <h1>Iframe Test</h1>
            <iframe
                src="https://dev.inhouse.app"
                width="600"
                height="400"
                title="Iframe Example"
            ></iframe>
        </div>
    );
};

export default IframeTest;