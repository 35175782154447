import React from 'react'
import LawyerMatching from './LawyerMatching'
import DedicatedLawyer from './DedicatedLawyer'

const LawyerInfo = ({ userMetadata }) => {
    return (
        <>
            {userMetadata?.lawyer_status === "assigned" ? <DedicatedLawyer userMetadata={userMetadata} /> : <LawyerMatching userMetadata={userMetadata} />}
        </>
    )
}

export default LawyerInfo