import { FileText } from '@phosphor-icons/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateReferenceDocDownloadUrl } from 'src/pages/chatpage/threadsSlice';

const FileAttachmentPrompt = ({ referenceDoc }) => {
    const dispatch = useDispatch()

    const referenceDocDownloadUrl = useSelector(state => state.threads.referenceDocDownloadUrl)

    const downloadFile = () => {
        dispatch(generateReferenceDocDownloadUrl(referenceDoc.reference_doc_storage_id))
    }

    useEffect(() => {
        if (referenceDocDownloadUrl) {
            window.open(referenceDocDownloadUrl, "_blank")
        }
    }, [referenceDocDownloadUrl])
    return (
        <div className="w-full p-0 gap-0 pb-4">
            <div className="flex flex-row p-0  gap-0 bg-[#FAFAFA] rounded-[11px] h-[65px] w-[353px]"
                onClick={() => { downloadFile() }}>
                <span className="bg-[#FAFAFA] h-[65px] w-[60px] border flex px-[8px] rounded-l-[11px] my-auto mx-auto items-center justify-center">
                    <FileText className="text-[#011513] my-auto mx-auto" size={20} />
                </span>
                <div className="bg-[#fff] flex flex-row w-full justify-between gap-[24px] h-[65px]  pl-[8px] border pr-[12px] rounded-r-[11px] !border-l-0">
                    <span className="w-full overflow-x-hidden text-[#011513] bg-[#fff] text-[14px] my-auto cursor-default">
                        {referenceDoc?.file_name?.length > 35 ? referenceDoc?.file_name?.slice(0, 35) + "..." : referenceDoc?.file_name}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default FileAttachmentPrompt;