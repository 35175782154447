import React from 'react'

const Switch = (props) => {

    const {
        left,
        right,
        onToggle,
        value
    } = props;

    return (
        <div role='container' className='bg-[#F2F2F2] flex rounded-full px-1 py-1 transition-all'>
            <div
                className={`px-6 py-2 rounded-full cursor-pointer border text-sm font-semibold ${value === left.value ? 'bg-white  !border-[#18475A]' : '!border-transparent'}`}
                onClick={() => onToggle(left?.value)}
            >
                {left?.label}
                {left?.info && <span className='ml-2 text-[#248370] text-sm font-medium'>{left?.info}</span>}
            </div>

            <div
                className={`px-6 py-2 rounded-full cursor-pointer border text-sm font-semibold ${value === right.value ? 'bg-white  !border-[#18475A]' : '!border-transparent'}`}
                onClick={() => onToggle(right?.value)}
            >

                {right?.label}
                {right?.info && <span className='ml-2 text-[#248370] text-sm font-medium'>{right?.info}</span>}
            </div>
        </div>
    )
}

export default Switch