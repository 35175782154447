import React, { useEffect, useState } from "react";
// import copy from "../../images/copy.svg";
import { ArrowBendDoubleUpRight, Briefcase, PenNib, ThumbsDown, ThumbsUp, X } from "@phosphor-icons/react";
import { useDispatch, useSelector } from "react-redux";
// import { legalReviewFeaturesNew } from "./ChatHeader";
import AskAvatar from "../../images/AskAvatar.svg";
import DraftAvatar from "../../images/DraftAvatar.svg";
// import { setRetainerModalOpen, setShowSubscriptionModal } from "../../pages/UserAuthentication/AuthenticationSlice";
import { setAskToDraftIntentWSResponse, setShowAskToDraftModal } from "../../pages/chatpage/messageSlice";
import { setShowRequestLegalReviewAside } from "src/pages/onboarding/onboardingSlice";
import { trackAnalytics } from "src/pages/helpers";
import MessageRender from "./MessageRender";
import { all } from "axios";
import FileAttachmentPrompt from "../Prompts/FileAttachmentPrompt";


const ChatMessage = ({
    index,
    isAIReply,
    chatInfo,
    message_type,
    isLastReply,
    chatId,
    selectedThread,
    legalReviewRequested,
    subscriptionType,
    setUserAction,
    isFirstAiMessage,
    sharedpage,
    threadData
}) => {
    const currentDate = new Date(chatInfo?.created_at);
    const options = {
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZoneName: "short",
    };

    const [animate, setAnimate] = useState(false);
    const [buttonsToShow, setButtonsToShow] = useState([]);
    const [showLegalReviewRequestButton, setShowLegalReviewRequestButton] = useState(true);
    const [showTemplateButton, setShowTemplateButton] = useState(true);
    const [showHyperLinkButton, setShowHyperLinkButton] = useState(true);
    const [threadType, setThreadType] = useState(null);
    const [avatar, setAvatar] = useState(null);
    const [message, setMessage] = useState(chatInfo?.text || chatInfo?.message_text);

    const dispatch = useDispatch();

    const getThreadStatus = useSelector((state) => state.chat.getThreadStatus);
    const templateSaveStatus = useSelector((state) => state.chat.templateSaveStatus);
    const email = useSelector((state) => state.homeslice.email);
    const changesAccepted = useSelector((state) => state.chat.changesAccepted);
    const lastConversationalMessage = useSelector((state) => state.chat.lastConversationalMessage);
    const saveAsTemplateChats = useSelector((state) => state.chat.saveAsTemplateChats);
    const legalReviewRequestedChats = useSelector((state) => state.chat.legalReviewRequestedChats);
    const viewChatLinks = useSelector((state) => state.chat.viewChatLinks);
    const lawyerHubData = useSelector((state) => state.onboarding.lawyerHubData);
    const draftTypeSuggestions = useSelector((state) => state.homeslice.draftTypes);

    const getFirstMessage = () => {
        console.log(threadData)
        var categoryName;
        if (threadData?.metadata?.document_category_id) {
            categoryName = getCategoryName(threadData?.metadata?.document_category_id) || "";
        }
        else {
            categoryName = threadData?.metadata?.document_name || "";
        }

        setMessage(`${categoryName}\n ${threadData?.metadata?.description}`);

    }

    useEffect(() => {
        if (index === 0 && message_type === "draft_message") {
            getFirstMessage();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        if (index === 0 && message_type === "draft_message") {
            getFirstMessage();
        }
    }, [draftTypeSuggestions]); // eslint-disable-line


    useEffect(() => {
        if (message_type === "ask_message") {
            setThreadType("ask");
            setAvatar(AskAvatar);
        }
        else if (message_type === "draft_message") {
            setThreadType("draft");
            setAvatar(DraftAvatar);
        }
    }, [message_type]);

    useEffect(() => {
        setShowHyperLinkButton(viewChatLinks[viewChatLinks.length - 1] === chatInfo.id)
        updatebuttonsToShow();
    }, [viewChatLinks]); // eslint-disable-line

    useEffect(() => {
        setShowTemplateButton(saveAsTemplateChats[saveAsTemplateChats.length - 1] === chatInfo.id)
        updatebuttonsToShow();
    }, [saveAsTemplateChats]); // eslint-disable-line

    useEffect(() => {
        var show = legalReviewRequestedChats[legalReviewRequestedChats.length - 1] === chatInfo.id;
        setShowLegalReviewRequestButton(show)
        updatebuttonsToShow();
    }, [legalReviewRequestedChats]); // eslint-disable-line


    useEffect(() => {
        const newAiMessage = localStorage.getItem("newAiMessage");
        if (newAiMessage && newAiMessage === chatInfo.id && isAIReply) {
            setAnimate(true);
            localStorage.removeItem("newAiMessage");
        }
    }, [getThreadStatus]); // eslint-disable-line


    const legalReviewAction = () => {
        trackAnalytics("dashboard_ask_consult_lawyer_clicked", { "action": "legal review request", "chatId": chatId, user_email: email });
        trackAnalytics("legal review request", { "action": "legal review request", "chatId": chatId, user_email: email });
        dispatch(setShowRequestLegalReviewAside(true))
    }

    const getCategoryName = (categoryId) => {
        var category = draftTypeSuggestions?.find(draft => draft.id === categoryId);
        return category?.name || category?.category;
    }


    const hasAcceptEditSuggestionsButton = () => {
        if (chatInfo?.quick_action_buttons?.length > 0) {
            return chatInfo?.quick_action_buttons.some((btn) => btn.type === "accept_edit_suggestions");
        }
        return false;
    }
    const showQuickActionButton = (btn) => {
        if (btn.type === "accept_edit_suggestions" || btn.type === "quick_response") {
            if (isLastReply) {
                return true;
            }
            return false;
        }
        if (btn.type === "legal_review_request") {

            var legalReview = lawyerHubData?.find((item) => item.thread_id === chatId);
            if (legalReview) {
                return false;
            }
            if (showLegalReviewRequestButton) {
                if (legalReviewRequested) {
                    return false;
                }
                // if this is the last conversational message, then show the legal review button
                if (showLegalReviewRequestButton) {
                    return true;
                }
                return false
            }
            else {
                return false;
            }
        }
        if (btn.type === "hyperlink" && showHyperLinkButton) {
            return true
        }
        if (btn.type === "save_as_template") {
            if (selectedThread?.template) {
                return false
            }
            if (showTemplateButton) {
                if (templateSaveStatus !== "success") {
                    return true;
                }
            }
        }

        if (isLastReply || lastConversationalMessage === chatInfo.id) {
            if (changesAccepted) {
                return true;
            }
            if (hasAcceptEditSuggestionsButton()) {
                return false;
            }
            return true;
        }
    }

    const showLegalButton = () => {
        var legalReview = lawyerHubData?.find((item) => item.thread_id === chatId);
        if (legalReview) {
            return false;
        }
        return true
    }
    const updatebuttonsToShow = () => {
        // debugger;
        if (chatInfo?.quick_action_buttons?.length > 0) {
            var buttons = [];
            chatInfo?.quick_action_buttons.map((btn) => {
                if (showQuickActionButton(btn)) {
                    buttons.push(btn)
                }
            })
            setButtonsToShow(buttons)
            return [];
        }
    }

    const openDraftIntent = (btn) => {
        dispatch(setAskToDraftIntentWSResponse({}))
        trackAnalytics("dashboard_ask_quick_action_button_clicked", { "user_email": email, "action": "draft", "chatId": chatId, "text": btn.text });
        dispatch(setShowAskToDraftModal({ "show": true, "intent": { "draft_prompt": btn.text, "ask_thread_id": threadData.id } }))
    }

    useEffect(() => {
        updatebuttonsToShow()
    }, []);




    return (
        <div className="flex flex-col w-full px-0">
            <div className="flex flex-col w-[100%]">
                {index === 1 && threadData?.reference_doc && (
                    <FileAttachmentPrompt referenceDoc={threadData.reference_doc}></FileAttachmentPrompt>
                )}
                <MessageRender message={message}
                    index={index}
                    userType={chatInfo?.is_user_message ? "user" : "ai"}
                    avatar={avatar} isFirstAiMessage={isFirstAiMessage} />
            </div>
            {!sharedpage && (
                <>
                    {chatInfo?.quick_action_buttons?.length > 0 && (
                        <div className="flex flex-col my-[12px] gap-[16px]">
                            <hr />
                            <div className="flex flex-row gap-[8px]">
                                <ArrowBendDoubleUpRight size={20} className="text-[#949494] scale-y-[-1]" />
                                <span className="text-[16px] text-[#949494] leading-[25.6px] font-[500]">Suggested Actions</span>
                            </div>
                            {chatInfo?.quick_action_buttons.map((btn, index) => (
                                <>
                                    {
                                        btn.type === "legal_review_request" && showLegalButton() && (
                                            <button
                                                key={index}
                                                className="cursor-pointer flex flex-row w-fit p-[12px] text-[#FFF] rounded-[8px] border border-[#E0E0E0] bg-[#18475A] text-[14px] leading-[19.6px] font-[500] gap-[8px]"
                                                onClick={() => legalReviewAction()}
                                            >
                                                <Briefcase size={16} className="text-white my-auto" />
                                                <span className="my-auto">{btn.text}</span>
                                            </button>
                                        )
                                    }
                                    {
                                        btn.type === "draft" && (
                                            <button
                                                key={index}
                                                onClick={() => { openDraftIntent(btn) }}
                                                className="cursor-pointer flex flex-row w-fit p-[12px] bg-[#FFFFFF] rounded-[8px] border border-[#D1D1D1] text-[#264653] text-[14px] leading-[19.6px] font-[500] gap-[8px]"
                                            >
                                                <PenNib size={16} className="text-[#13866F] my-auto" />
                                                <span className="my-auto">{btn.text}</span>
                                            </button>
                                        )
                                    }
                                </>
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ChatMessage;
