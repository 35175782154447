import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { extractErrors } from "../helpers";
import axios from "axios";
const BASE_ENDPOINT = process.env.REACT_APP_BACKEND_BASE_ENDPOINT;


export const getAllThreads = createAsyncThunk("threads/getAllThreads", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const url = `${BASE_ENDPOINT}/api/user/list-threads`;
    const response = await axios.get(url, { headers: headers });
    return {
      data: data,
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error, data: data });
  }
});

export const deleteThread = createAsyncThunk("chat/deleteThread", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const url = `${BASE_ENDPOINT}/api/thread/${data.id}/delete`;
    const response = await axios.delete(url, { headers: headers });
    return { response: response.data, request: data };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error, request: data });
  }
});


export const updateTitle = createAsyncThunk("threads/updateTitle", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const url = `${BASE_ENDPOINT}/api/thread/${data.id}/update-title`;
    const response = await axios.post(url, data, { headers: headers });
    return {
      response: response.data,
      thread_id: data.id,
      request: data
      // "title": data[0]
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error, request: data });
  }
});


export const markThreadRead = createAsyncThunk("threads/markThreadRead", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const url = `${BASE_ENDPOINT}/api/thread/${data.threadId}/mark-read`;
    const response = await axios.get(url, { headers: headers });
    return {
      response: response.data,
      request: data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const getTitle = createAsyncThunk("threads/getTitle", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const url = `${BASE_ENDPOINT}/api/v0/thread/${data.thread[0]}/get-title`;
    const response = await axios.get(url, { headers: headers });
    // const response = null;
    return {
      response: response.data,
      index: data.thread[1],
      mode: data.mode,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const generateSharedId = createAsyncThunk("threads/generateSharedId", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const url = `${BASE_ENDPOINT}/api/thread/${data.thread.id}/share`;
    const response = await axios.post(url, {}, { headers: headers });
    return { response: response.data, request: data };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const generateDownloadDraftUrl = createAsyncThunk("threads/generateDownloadDraftUrl", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const url = `${BASE_ENDPOINT}/api/draft/download-document/${data.thread.id}`;
    const response = await axios.get(url, { headers: headers });
    return { response: response.data, request: data };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const generateReferenceDocDownloadUrl = createAsyncThunk("threads/generateReferenceDocDownloadUrl", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const url = `${BASE_ENDPOINT}/api/get-presigned-download-url/${data.storageId}`;
    const response = await axios.get(url, { headers: headers });
    return { response: response.data, request: data };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});


export const getUnreadThreads = createAsyncThunk("threads/getUnreadThreads", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const url = `${BASE_ENDPOINT}/api/user/unread-threads`;
    const response = await axios.get(url, { headers: headers });
    return {
      data: data,
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error, data: data });
  }
});


const ThreadsSlice = createSlice({
  name: "threads",
  initialState: {

    getTitleStatus: "idle",
    getTitleError: null,

    acceptLegalReviewStatus: "idle",
    acceptLegalReviewError: null,

    rejectLegalReviewStatus: "idle",
    rejectLegalReviewError: null,

    "threads": [],
    deletedThread: {},
    titleUpdated: {},
    threads_status: "idle",
    shareThreadStatus: "idle",
    shareThreadError: null,
    shareThreadId: "",
    currentThread: null,
    unreadThreads: [],
    unreadThreadsFetchStatus: "idle",
    markThreadReadStatus: "idle",

    threadSelectedToShare: null,
    downloadDraftUrl: "",
    downloadDraftUrlStatus: "idle",
    downloadDraftUrlError: "",
    referenceDocDownloadUrl: "",
  },
  reducers: {
    resetData: (state) => {
      state.deletedThread = {}
      state.titleUpdated = {}
    },
    resetReferenceDocDownloadUrl: (state) => {
      state.referenceDocDownloadUrl = "";
    },
    resetDownloadUrl: (state) => {
      state.downloadDraftUrl = "";
      state.downloadDraftUrlStatus = "idle";
      state.downloadDraftUrlError = "";
    },

    setThreadSelectedToShare: (state, action) => {
      state.threadSelectedToShare = action.payload;
    },
    insertNewThread: (state, action) => {
      var data = {
        "id": action.payload.id,
        "type": action.payload.thread_type,
        "title": "New thread",
        "document_storage_id": null,
        "is_shared": false,
        "messaging_disabled": action.payload.messaging_disabled,
        "user": "user",
        "is_user": true
      }
      state.threads["today"] = [data, ...state.threads["today"]];
    },

    updateThreadTitleLocal: (state, action) => {
      var threadId = action.payload.thread_id;
      var title = action.payload.title;
      var duration = null;
      var index = null
      for (var key in state.threads) {
        index = state.threads[key].findIndex((thread) => thread.id === threadId);
        if (index !== -1) {
          duration = key;
          break;
        }
      }
      if (index > -1 && duration) {
        // state.threads[duration][index].title = title;
        var durations = state.threads[duration]
        var thread = durations[index];
        thread["title"] = title;
        durations[index] = thread;
        state.threads = { ...state.threads, [duration]: durations };
      }
    },
    updateThreadFeedBackLocal: (state, action) => {
      if (action.payload.mode === "ask") {
        let tempArray = [...state.askThreadsList];
        let index = tempArray.findIndex((thread) => thread.id === action.payload.threadId);

        // Check if a thread was found
        if (index !== -1) {
          tempArray[index].legal_review_feedback = action.payload.legal_review_feedback;

          state.askThreadsList = tempArray;
        } else {
          console.warn("Thread not found in askThreadsList with ID:", action.payload.threadId);
        }
      } else {
        let tempArray = [...state.draftThreadsList];
        let index = tempArray.findIndex((thread) => thread.id === action.payload.threadId);

        // Check if a thread was found
        if (index !== -1) {
          tempArray[index].legal_review_feedback = action.payload.legal_review_feedback;

          state.draftThreadsList = tempArray;
        } else {
          console.warn("Thread not found in draftThreadsList with ID:", action.payload.threadId);
        }
      }
    },
    addNewThreadLocal: (state, action) => {
      if (action.payload.mode === "ask") {
        state.askThreadsList = [action.payload.data, ...state.askThreadsList];
      } else {
        state.draftThreadsList = [action.payload.data, ...state.draftThreadsList];
      }
    },
    removeDeletedThreadFromList: (state, action) => {
      if (action.payload.mode === "ask") {
        state.askThreadsList = state.askThreadsList.filter((thread) => thread.id !== action.payload.threadId);
      } else {
        state.draftThreadsList = state.draftThreadsList.filter((thread) => thread.id !== action.payload.threadId);
      }
    },
    resetLegalReviewStatus: (state) => {
      state.acceptLegalReviewStatus = "idle";
      state.acceptLegalReviewError = null;
      state.rejectLegalReviewStatus = "idle";
      state.rejectLegalReviewError = null;
    },
    addUnreadThread: (state, action) => {
      if (state.unreadThreads.indexOf(action.payload) === -1) {
        state.unreadThreads.push(action.payload);
      }
    },

    deleteUnreadThread: (state, action) => {
      state.unreadThreads = state.unreadThreads.filter((thread) => thread !== action.payload);
    },
    resetThreadStates: (state, action) => {
      state.shareThreadId = "";
      state.shareThreadStatus = "idle";
      state.shareThreadError = null;
    },
    resetAnimation: (state, action) => {
      if (action.payload.mode === "ask") {
        let tempArray = [...state.askThreadsList];
        let index = tempArray.findIndex((thread) => thread.id === action.payload.threadId);

        // Check if a thread was found
        if (index !== -1) {
          // tempArray[index].title = action.payload.title;
          // if (action.payload?.animate) {
          tempArray[index].animate = action.payload.animate;
          // }
          state.askThreadsList = tempArray;
        } else {
          console.warn("Thread not found in askThreadsList with ID:", action.payload.threadId);
        }
      } else {
        let tempArray = [...state.draftThreadsList];
        let index = tempArray.findIndex((thread) => thread.id === action.payload.threadId);

        // Check if a thread was found
        if (index !== -1) {
          // tempArray[index].title = action.payload.title;
          // if (action.payload?.animate) {
          tempArray[index].animate = action.payload.animate;
          // }
          state.draftThreadsList = tempArray;
        } else {
          console.warn("Thread not found in draftThreadsList with ID:", action.payload.threadId);
        }
      }
    },
    resetUpdateActions: (state) => {
      state.shareThreadStatus = "idle";
      state.shareThreadError = "";
      state.deletedThread = {};
      state.updatedTitle = {};
    },
    resetDeletedThread: (state) => {
      state.deletedThread = {};
    },
    resetUpdatedTitle: (state) => {
      state.updatedTitle = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllThreads.pending, (state, action) => {
        state.threads_status = "pending";
        // state.threads = [];
      })
      .addCase(getAllThreads.rejected, (state, action) => {
        // state.threads = [];
        state.threads_status = "failed";
      })
      .addCase(getAllThreads.fulfilled, (state, action) => {
        var results = action.payload.response;
        state.threads = results;
        state.threads_status = "success";
      })

      // getTitle handlers
      .addCase(getTitle.pending, (state) => {
        state.getTitleStatus = "loading";
        state.getTitleError = "";
      })
      .addCase(getTitle.fulfilled, (state, action) => {
        state.getTitleStatus = "idle";
        state.getTitleError = "";
        if (action.payload.mode === "ask") {
          state.askThreadsList[action.payload.index].title = action.payload.response.title;
        } else {
          state.draftThreadsList[action.payload.index].title = action.payload.response.title;
        }
      })
      .addCase(getTitle.rejected, (state, action) => {
        state.getTitleStatus = "failed";
        state.getTitleError = action.payload.error;
      })
      // shareThread handlers
      .addCase(generateSharedId.pending, (state) => {
        state.shareThreadStatus = "loading";
        state.shareThreadError = "";
      })
      .addCase(generateSharedId.fulfilled, (state, action) => {
        state.shareThreadError = "";
        state.shareThreadId = action.payload.request.thread.id;
        state.shareThreadStatus = "success";
        var threadId = action.payload.request.thread.id;
        var duration = action.payload.request.duration;
        // state.threads = state.threads[duration].map((thread) => thread.id === threadId ? { ...thread, is_shared: true } : thread);
        if (duration) {
          var index = state.threads[duration].findIndex((thread) => thread.id === threadId);
          var durationThreads = state.threads[duration];
          durationThreads[index].is_shared = true;
          state.threads = { ...state.threads, [duration]: durationThreads };
        }
        // state.updatedTitle = { "status": "success", "id": threadId, "duration": duration, "title": title }

        // state.threads = state.threads.map((thread) => thread.id === action.payload.request.threadId ? { ...thread, is_shared: true } : thread);
      })
      .addCase(generateSharedId.rejected, (state, action) => {
        state.shareThreadStatus = "failed";
        state.shareThreadError = extractErrors(action.payload.error);
      })

      // shareThread handlers
      .addCase(generateDownloadDraftUrl.pending, (state) => {
        state.downloadDraftUrlStatus = "loading";
        state.downloadDraftUrlError = "";
      })
      .addCase(generateDownloadDraftUrl.fulfilled, (state, action) => {
        state.downloadDraftUrl = action.payload.response.url;
        state.downloadDraftUrlStatus = "success";
      })
      .addCase(generateDownloadDraftUrl.rejected, (state, action) => {
        state.downloadDraftUrlStatus = "failed";
        state.downloadDraftUrl = "";
        state.downloadDraftUrlError = "Failed to generate download link";
      })


      // deleteThread handlers
      .addCase(deleteThread.pending, (state) => {
        state.deletedThread = { "status": "loading" };
      })
      .addCase(deleteThread.fulfilled, (state, action) => {
        var threadId = action.payload.request.id
        var duration = action.payload.request.duration

        var durationThreads = state.threads[duration].filter((thread) => thread.id !== threadId);
        state.threads = { ...state.threads, [duration]: durationThreads };

        // state.threads = state.threads[duration].filter((thread) => thread.id !== threadId);
        state.deletedThread = {
          id: threadId,
          duration: duration,
          "status": "success"
        }
      })
      .addCase(deleteThread.rejected, (state, action) => {
        state.deletedThread = { "error": "Failed to delete thread", "status": "rejected" }
      })

      // updateTitle handlers
      .addCase(updateTitle.pending, (state) => {
        state.updatedTitle = { "status": "loading" }
      })
      .addCase(updateTitle.fulfilled, (state, action) => {
        var threadId = action.payload.request.id;
        var duration = action.payload.request.duration;
        var title = action.payload.request.title;
        var index = state.threads[duration].findIndex((thread) => thread.id === threadId);
        var durationThreads = state.threads[duration];
        durationThreads[index].title = title;
        state.threads = { ...state.threads, [duration]: durationThreads };
        state.updatedTitle = { "status": "success", "id": threadId, "duration": duration, "title": title }
        // state.threads[duration]
        // state.threads = state.threads[duration].map((thread) => thread.id === threadId ? { ...thread, title: title } : thread);
        // var durationThreads = state.threads[duration];
        // var index = durationThreads.findIndex((thread) => thread.id === threadId);
        // durationThreads[index].title = title;
        // state.threads = { ...state.threads, [duration]: durationThreads };

      })
      .addCase(updateTitle.rejected, (state, action) => {
        state.updatedTitle = { "error": "Failed to update title", "status": "rejected" }
      })

      // get all unread thrads
      .addCase(getUnreadThreads.pending, (state) => {
        state.unreadThreadsFetchStatus = "loading";
        // state.unreadThreads = [];
      })
      .addCase(getUnreadThreads.fulfilled, (state, action) => {
        state.unreadThreadsFetchStatus = "success"
        state.unreadThreads = action.payload.response;
      })
      .addCase(getUnreadThreads.rejected, (state) => {
        state.unreadThreadsFetchStatus = "failed";
      })

      // markthread read
      .addCase(markThreadRead.pending, (state) => {
        state.markThreadReadStatus = "loading";
        // state.unreadThreads = [];
      })
      .addCase(markThreadRead.fulfilled, (state, action) => {
        state.markThreadReadStatus = "success"
      })
      .addCase(markThreadRead.rejected, (state) => {
        state.markThreadReadStatus = "failed";
      })

      .addCase(generateReferenceDocDownloadUrl.pending, (state) => {
        state.referenceDocDownloadUrl = "";
      })
      .addCase(generateReferenceDocDownloadUrl.fulfilled, (state, action) => {
        state.referenceDocDownloadUrl = action.payload.response.url;
      })
      .addCase(generateReferenceDocDownloadUrl.rejected, (state) => {
        state.referenceDocDownloadUrl = "";
      })

  },
});

export default ThreadsSlice.reducer;
export const {
  resetLegalReviewStatus,
  addUnreadThread,
  deleteUnreadThread,
  removeDeletedThreadFromList,
  updateThreadTitleLocal,
  addNewThreadLocal,
  updateThreadFeedBackLocal,
  resetThreadStates,
  resetAnimation,
  resetDeletedThread,
  resetData,
  setThreadSelectedToShare,
  resetUpdatedTitle,
  insertNewThread,
  resetUpdateActions,
  resetDownloadUrl,
  resetReferenceDocDownloadUrl
} = ThreadsSlice.actions;
