import React, { useEffect, useRef, useState } from 'react';
import Editor from './editor';
import Quill from 'quill';
import { QuillJson, PlaceholdersList, AssumptionsList, KeyClauseList, threadDummyData, DocumentData } from './QuillJson';
// import UpdatePlaceholderComponent from './UpdatePlaceholderComponent';
import BaseLayout from '../../pages/layouts/BaseLayout';
import { Copy, CornersOut, DownloadSimple, Export, EyeSlash, LightbulbFilament, PenNib, ShareFat, SketchLogo, X } from "@phosphor-icons/react";
import { useUser } from '@clerk/clerk-react';
import useValidatePageAccess from '../../hooks/useValidatePageAccess';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PlaceholderComponent from './components/PlaceholderComponent';
// import { setActivePlaceholder } from '../../pages/chatpage/chatSlice';
import ChatBoxComponent from "../../pages/chatpage/components/ChatBoxComponent";
import ReviewShimmer from "../Shimmers/ReviewShimmer";
import DraftShimmer from "../Shimmers/DraftShimmer";
import AskShimmer from "../Shimmers/AskShimmer";

import BracketsSquareBw from '../../images/icons/BracketsSquareBw.svg';
import { setAssumptions, setDocument, setKeyClauses, setThreadData } from '../../pages/chatpage/messageSlice';
import { getCalculatedIndices, removeBackgroundColors, setBackgroundColors, setUpDocument, updatePlaceholderValue, renderMessage } from './helpers';


const DocumentViewer = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [inputMessage, setInputMessage] = useState('');
    const Delta = Quill.import('delta');
    const [range, setRange] = useState();
    const [lastChange, setLastChange] = useState();
    const [placeholders, setPlaceholders] = useState();
    const [redlines, setRedlines] = useState([]);
    const [selectedText, setSelectedText] = useState('');
    const [placeholderInfocus, setPlaceholderInfocus] = useState(false);
    const [activeTab, setActiveTab] = useState("pending-actions");
    const [activeSubTab, setActiveSubTab] = useState("all");
    const [calculatedIndices, setCalculatedIndices] = useState({});
    const [defaultData, setDefaultData] = useState(null);
    const [activePlaceholder, setActivePlaceholder] = useState(null);
    const [chatDocument, setChatDocument] = useState(null);

    const threadsData = useSelector(state => state.messageSlice.threadData);


    // Use a ref to access the quill instance directly
    const quillRef = useRef();
    const data = QuillJson;

    useValidatePageAccess();
    const { isLoaded, isSignedIn, user } = useUser();

    useEffect(() => {
        dispatch(setThreadData(threadDummyData));
        // dispatch(setPlaceholders(PlaceholdersList));
        dispatch(setDocument(DocumentData))
        dispatch(setAssumptions(AssumptionsList))
        dispatch(setKeyClauses(KeyClauseList));
    }, []); // eslint-disable-line

    useEffect(() => {
        if (isSignedIn && user) {
            if (!user?.firstName || !user?.unsafeMetadata?.organization_name) {
                navigate('/onboarding')
            }
        }
    }, [user]); // eslint-disable-line



    useEffect(() => {
        if (threadsData.id) {
            // any change in the thread data will trigger this
            // this might result into re-render of the document
            // if the page slows down, we can use a useRef for placeholders, calculatedIndices and redlines
            // and many other places where we are using useState
            setDefaultData(setUpDocument(threadsData.document.content, Delta))
            removeBackgroundColors(quillRef, threadsData.document.placeholders.data);
            setCalculatedIndices(getCalculatedIndices(threadsData.document.placeholders.data));
            setBackgroundColors(quillRef, threadsData.document.placeholders.data);
            setPlaceholders(threadsData.document.placeholders.data);
            setActivePlaceholder(threadsData.document.placeholders.activePlaceholder);
            setChatDocument(threadsData.document);
        }
    }, [threadsData]);

    const updatePlaceholder = (index, updatedText) => {
        updatePlaceholderValue(quillRef, placeholders, index, updatedText, setPlaceholders)
    }


    const focusPlaceholder = (placeholder, index) => {
        // setPlaceholderInfocus(true)
        placeholder["indices"].forEach(index => {
            quillRef?.current?.setSelection(index.start_index, index.end_index - index.start_index);
            quillRef?.current?.scrollSelectionIntoView();
        });
        // dispatch(setActivePlaceholder(index));
    }

    const closeTooltip = () => {
        var tooltip = document.getElementById('custom-tooltip');
        tooltip.style.display = 'none';
        setSelectedText('');
    }
    const getUserDetailsStatus = useSelector((state) => state.auth.getUserDetailsStatus);

    const onsubmit = () => {
        alert('submitting message');
    }

    return (
        <>
            {isLoaded ? (
                <BaseLayout>
                    <div className="flex flex-row w-[1376px] mx-auto">
                        <div className="w-[570px] h-screen overflow-y-scroll flex flex-col">
                            <div className="h-[64px] flex justify-between px-[24px] py-[16px] w-full">
                                <div className="flex gap-[8px]">
                                    <PenNib size={16} className='text-[#949494] my-auto' />
                                    <span className="text-[#353535] text-[16px] leading-[21px] my-auto">{chatDocument?.title}</span>
                                </div>
                                <div className="my-auto">
                                    <Export size={24} className='text-[#949494] my-auto' />
                                </div>
                            </div>
                            <div id="selection-tabs-left flex flex-col" className='w-full'>
                                <div className="w-full flex flex-row py-[1]">
                                    <div className={`cursor-pointer w-[50%] border-b  py-[16px] ${activeTab === "chat" ? 'border-b-black' : 'border-b-[#E4E4E4]'}`}
                                        onClick={() => { setActiveTab('chat') }}>
                                        <p className='text-center'>Chat</p>
                                    </div>
                                    <div className={`cursor-pointer w-[50%]  border-b py-[16px] ${activeTab === "pending-actions" ? 'border-b-black' : 'border-b-[#E4E4E4]'}`}
                                        onClick={() => { setActiveTab("pending-actions") }}>
                                        <p className='text-center'>Pending Actions</p>
                                    </div>
                                </div>
                                {activeTab === "chat" ?
                                    <>
                                        <div className="flex justify-between flex-col w-full h-screen">
                                            <div className="flex flex-col px-[20px] py-[24px] mx-auto overflow-y-scroll gap-[12px]" id="scrolling_div">
                                                {threadsData.messages.map((message, index) => (
                                                    <>
                                                        {renderMessage(message, index, threadsData)}
                                                    </>
                                                )
                                                )}
                                                {threadsData.messagingDisabled
                                                    && !threadsData.legal_review_requested && (
                                                        <div className="mt-[12px]">

                                                            <>{threadsData.thread_type === "review" && <ReviewShimmer />}</>


                                                            <>{threadsData.thread_type === "ask" && <AskShimmer />}</>
                                                            <>{threadsData.thread_type === "draft" && <DraftShimmer />}</>
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="flex flex-col w-full">
                                                <ChatBoxComponent
                                                    size="half"
                                                    inputMessage={inputMessage}
                                                    setInputMessage={setInputMessage}
                                                    onsubmit={onsubmit} />
                                            </div>
                                        </div>

                                    </> :
                                    <div className="flex flex-col">
                                        <div className="flex flex-row gap-[10px] px-[24px] py-[16px]">
                                            <div className="cursor-pointer w-[168px] justify-center flex gap-[4px] bg-white border rounded-[88px] border-[#E4E4E4] py-[12px] px-[24px] text-center"
                                                onClick={() => { setActiveSubTab("Placeholders") }}>
                                                <img src={BracketsSquareBw} alt="" className="w-[16px] h-[16px] my-auto" />
                                                <p className='text-[14px] leading-[19.6px] text-[#353535] my-auto'>Placeholders</p>
                                            </div>
                                            <div className="cursor-pointer w-[168px] flex justify-center gap-[4px] bg-white border rounded-[88px] border-[#E4E4E4] py-[12px] px-[24px] text-center"
                                                onClick={() => { setActiveSubTab("Assumptions") }}>
                                                <LightbulbFilament size={12} className="text-[#949494] my-auto" />
                                                <p className='text-[14px] leading-[19.6px] text-[#353535] my-auto'>Assumptions</p>
                                            </div>
                                            <div className="cursor-pointer w-[168px] flex justify-center gap-[4px] bg-white border rounded-[88px] border-[#E4E4E4] py-[12px] px-[24px] text-center"
                                                onClick={() => { setActiveSubTab("Key Clauses") }}>
                                                <SketchLogo size={11.5} className="text-[#949494] my-auto" />
                                                <p className='text-[14px] leading-[19.6px] text-[#353535] my-auto'>Key Clauses</p>
                                            </div>
                                        </div>
                                        {(activeSubTab === "Placeholders" || activeSubTab === "all") &&
                                            <PlaceholderComponent
                                                placeholders={placeholders}
                                                focusPlaceholder={focusPlaceholder}
                                                updatePlaceholder={updatePlaceholder}
                                                activePlaceholder={activePlaceholder} />
                                        }
                                        {(activeSubTab === "Assumptions" || activeSubTab === "all") &&
                                            <PlaceholderComponent
                                                placeholders={placeholders}
                                                focusPlaceholder={focusPlaceholder}
                                                updatePlaceholder={updatePlaceholder}
                                                activePlaceholder={activePlaceholder} />
                                        }
                                        {(activeSubTab === "Key Clauses" || activeSubTab === "all") &&
                                            <PlaceholderComponent
                                                placeholders={placeholders}
                                                focusPlaceholder={focusPlaceholder}
                                                updatePlaceholder={updatePlaceholder}
                                                activePlaceholder={activePlaceholder} />
                                        }
                                    </div>
                                }

                            </div>
                        </div>
                        <div className='flex flex-col bg-white border-l-[#E4E4E4] w-[806px] h-screen overflow-y-scroll'>
                            <div className='flex flex-row justify-between py-[16px] px-[24px]'>
                                <div className="left-side flex justify-between">
                                    <div className='flex w-[52px] h-[36px] border-r border-r-[#e4e4e4] '><Copy size={20} className="hover:cursor-pointer text-[#353535] mx-auto my-auto" /></div>
                                    <div className='flex w-[52px] h-[36px] border-r border-r-[#e4e4e4]'><DownloadSimple size={20} className="hover:cursor-pointer text-[#353535] mx-auto my-auto" /></div>
                                    <div className='flex w-[52px] h-[36px]'><ShareFat size={20} className="hover:cursor-pointer text-[#353535] mx-auto my-auto" /></div>
                                    <div className='flex h-[36px]'>
                                        <button className="flex flex-row bg-[#F2F2F2] rounded-[7.2px] px-[12px] py-[8px] gap-[7.2px] text-[#353535] text-[14px] leading-[19.6px] font-[600]">
                                            <span className='my-auto'>Hide Annotations</span>
                                            <EyeSlash size={14.4} className='my-auto text-[#353535]' />
                                        </button>
                                    </div>
                                </div>
                                <div className="right-side flex flex-row gap-[12px]">
                                    <div className='flex h-[36px] border-r border-r-[#e4e4e4] px-[12px]'><CornersOut size={20} className="hover:cursor-pointer text-[#353535] mx-auto my-auto" /></div>
                                    <div className='flex h-[36px]'><p className="my-auto text-[14px] leading-[19.6px] text-[#353535]">Hide Document</p></div>
                                </div>
                            </div>
                            <div className="px-[64px] overflow-y-scroll">
                                <Editor
                                    ref={quillRef}
                                    defaultValue={defaultData}
                                    onSelectionChange={setRange}
                                    onTextChange={setLastChange}
                                    setSelectedText={setSelectedText}
                                    placeholderInfocus={placeholderInfocus}
                                />
                            </div>
                        </div>
                        <div id="custom-tooltip" class="custom-tooltip">
                            <div class="flex flex-col items-end w-full">
                                <span class="flex">
                                    <X
                                        size={15}
                                        className="hover:cursor-pointer"
                                        color="black"
                                        onClick={() => closeTooltip()}
                                        id="cancel-legal-review-button"
                                    />
                                </span>
                            </div>
                            <div class="flex flex-col">
                                <div >{selectedText}</div>
                                <div class="flex flex-row mt-3">
                                    <button class="mr-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => closeTooltip()}>Explain This</button>
                                    <button class="mr-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => closeTooltip()}>Need more details</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </BaseLayout >
            ) : <>
                {
                    (isLoaded && getUserDetailsStatus === "failed") ?
                        <div className="w-full h-full flex justify-center items-center">
                            <p className="text-red-500">Failed to load user details. Please refresh the page.</p>
                        </div> :
                        <div className="w-full h-full flex justify-center items-center mx-auto mt-[200px]">
                            {/* <div className="spinner-grow spinner-grow-sm text-primary me-3" role="status"></div> */}
                            {/* <div class="rotating-cursor !h-[50px]"></div> */}
                            <p className="text-black text-[40px]">{isLoaded}</p>
                        </div>
                }
            </>}
        </>);
};

export default DocumentViewer;