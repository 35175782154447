export const QuillJson = {
    "ops": [
        {
            "insert": "Unilateral Non Disclosure Agreement\n",
            "attributes": {
                "bold": true,
                "size": "20pt"
            }
        },
        {
            "insert": "\n",
            "attributes": {
                "bold": true,
                "size": "20pt"
            }
        },
        {
            "insert": "This Non-Disclosure Agreement (\"Agreement\") effective on [EFFECTIVE DATE] (\u201cEffective Date\u201d) is entered into by and between: a. Round Table AI Inc (\"Company\") a Apple Corporation, with its principal office located at [insert company office address], and b. [insert Recipient name] (\"Recipient\") a [insert Recipient state of formation] [insert Recipient entity type], with its principal office located at [insert Recipient office address] \""
        },
        {
            "insert": "Company"
        },
        {
            "insert": "\" "
        },
        {
            "insert": "Apple Inc"
        },
        {
            "insert": " and \"Recipient\" may be jointly referred to as the \u201cParties\u201d and referred to as a \u201cParty.\u201d The Company and the Recipient agree to the following terms and conditions:\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "A. GENERAL INFORMATION",
            "attributes": {
                "bold": true,
                "size": "13pt"
            }
        },
        {
            "insert": "\n"
        },
        {
            "insert": "a. Company and Recipient have or may enter into ewtewt a business connection for their possible mutual benefit. Part of that relationship may require access to Confidential Information and this Agreement shall provide for the protection of such information. This Agreement is to be independently enforceable, but it is to be interpreted in accordance with the terms of any other agreement with the understanding that protection of Confidential Information is an absolute priority of the Parties.\n"
        },
        {
            "insert": "b. Should Company disclose or allow access to Recipient any Trade Secrets or Confidential Information as defined herein, all such information and the fruits thereof shall be subject to the protection of this Agreement.\n"
        },
        {
            "insert": "c. Ownership Rights: The Recipient acknowledges that the Confidential Information is, and at all times will be, the Company's sole property, even if suggestions made by the Recipient are incorporated into the Confidential Information. The Recipient obtains no rights by license or otherwise in the Confidential Information under this Agreement. Neither party solicits any change in the other party's organization, business practice, service, or products, and the disclosure of Confidential Information may not be construed as evidencing any intent by a party to purchase any products or services of the other party or as an encouragement to expend funds in development or research efforts. The Confidential Information may pertain to prospective or unannounced products. The Recipient shall not use the Confidential Information as a basis on which to develop or have a third party develop a competing or similar plan or undertaking.\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "B. CONFIDENTIAL INFORMATION AND USE\n"
        },
        {
            "insert": "\n",
            "attributes": {
                "bold": true,
                "size": "13pt"
            }
        },
        {
            "insert": "1. Confidential Information",
            "attributes": {
                "bold": true
            }
        },
        {
            "insert": "\n"
        },
        {
            "insert": "For all purposes of this Agreement, the term Confidential Information or Trade Secret shall include any and all information and the fruits thereof which is not generally available to the general public and derives from Company or Company\u2019s clients. The Confidential Information may be oral, written, digital and/or stored in any medium or manner whatsoever, including but not limited to hard drives, cloud storage, magnetic disks, e-mails, recordings, etc. Such Confidential Information includes, but is not limited to, any proprietary information of Company including trademark, copyright, patent and know-how whether registered or otherwise, technical or scientific information, business plans, financial information, budgets and projections, marketing plans, customer identifying information, potential and intended customers, employers, products, computer programs, specifications, manuals, software, analyses, strategies, client lists and contact information, vendor and contractor lists, business ideas and concepts, or designs and other confidential information related to the business of providing back office software for infusion clinics, whether provided orally, in writing, or by any other media.\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "2. Exclusions",
            "attributes": {
                "bold": true
            }
        },
        {
            "insert": "\n"
        },
        {
            "insert": "Recipient may only claim that the Confidential Information is not truly confidential if it was previously known to Recipient, disclosed to Recipient by third parties not under contractual obligation to Company to keep said information confidential, or already known by the general public. Recipient shall have the burden of proving that such is the case. As to client list and contact information matters, such proof must be in writings created prior to the Recipient's access to Confidential Information and dated accordingly. \n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "Should Recipient have access to alleged Confidential Information which Recipient contends is not Confidential Information, Recipient shall, within one (1) day of access, specify in writing to Company Recipient's contentions regarding said Confidential Information or shall be forever barred from claiming said Confidential Information is not truly confidential information.\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "3. Permitted Use",
            "attributes": {
                "bold": true
            }
        },
        {
            "insert": "\n"
        },
        {
            "insert": "Recipient agrees to only utilize such information and/or the fruits thereof for the sole purpose of assisting in the performance of Recipient's duties undertaken at the direction of Company. All information imparted shall be kept strictly confidential and may not be used or disclosed in any form or in any manner, whether directly or indirectly, to or by any person or persons without prior written consent of Company. Upon the termination of the business relationship with Company, Recipient shall not utilize the Confidential Information for any purpose whatsoever, either directly or indirectly, nor reveal the information directly or indirectly, to any third person. Recipient shall destroy or return to Company all such Confidential Information previously delivered to Recipient, as directed by Company, as well as any copies, outlines, summaries, abstracts, or work product of any type and in any form deriving from such information and shall provide evidence of the same within seven (07) days of termination of this Agreement.\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "4. Protection",
            "attributes": {
                "bold": true
            }
        },
        {
            "insert": "\n"
        },
        {
            "insert": "During the business relationship, the Recipient shall take all steps to place all Confidential Information, software, and written forms of said Confidential Information in safe and secure areas as directed by the Company. All such Confidential Information shall be returned to the Company immediately upon request, including any notes or abstracts or the equivalent prepared by the Recipient or its agents based on the said Confidential Information.\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "Notice of Breach\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "The Recipient shall notify the Company immediately upon discovery of, or suspicion of, (1) any unauthorized use or disclosure of Confidential Information by the Recipient or its Representatives; or (2) any actions by the Recipient or its Representatives inconsistent with their respective obligations under this Agreement. The Recipient shall cooperate with any and all efforts of the Company to help regain possession of Confidential Information and prevent its further unauthorized use.\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "5. Employee Or Contractor Disclosure",
            "attributes": {
                "bold": true
            }
        },
        {
            "insert": "\n"
        },
        {
            "insert": "Notwithstanding the terms contained elsewhere herein, no employee, agent or contractor of Recipient shall have access to such Confidential Information without the prior written consent of Company. Any employee, agent, or contractor having access to such information shall first sign a copy of this Agreement and agree to be bound by its terms.\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "6. No Warranty",
            "attributes": {
                "bold": true
            }
        },
        {
            "insert": "\n"
        },
        {
            "insert": "ALL CONFIDENTIAL INFORMATION IS PROVIDED 'AS IS.' THE COMPANY MAKES NO WARRANTIES, EXPRESS, IMPLIED, OR OTHERWISE, REGARDING THE ACCURACY, COMPLETENESS, OR PERFORMANCE OF ANY SUCH INFORMATION.\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "7. No Reverse Engineering",
            "attributes": {
                "bold": true
            }
        },
        {
            "insert": "\n"
        },
        {
            "insert": "The Recipient may not reverse engineer, disassemble, or decompile any prototypes, software, or other tangible objects that embody the Confidential Information and that are provided to the Recipient under this Agreement.\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "8. Derivative Uses",
            "attributes": {
                "bold": true
            }
        },
        {
            "insert": "\n"
        },
        {
            "insert": "It is further agreed that the Confidential Information included under this Agreement includes not just the direct use of said Confidential Information, but all developments, improvements, additions, and extensions to such Confidential Information. This also includes any business strategies, market analysis, and any derived works created through any usage of the Confidential Information provided by the Company.\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "C. MISCELLANEOUS\n",
            "attributes": {
                "bold": true,
                "size": "13pt"
            }
        },
        {
            "insert": "\n",
            "attributes": {
                "bold": true,
                "size": "13pt"
            }
        },
        {
            "insert": "9. Non-Solicitation And Non-Circumvention",
            "attributes": {
                "bold": true
            }
        },
        {
            "insert": "\n"
        },
        {
            "insert": "In order to protect Company\u2019s Confidential Information, during the business relationship, the time the Recipient is contracted by or associated with Company, and for a period of 2 years following the termination of that relationship for any reason, Recipient agrees that they will not directly or indirectly: (a) make known to any third party the names, addresses, and/or contact information of any of the clients or vendors of Company, or any other information pertaining to those employees or vendors or (b) call on, accept calls from, solicit, induce to leave and/or take away, or attempt to call on, solicit, induce to leave and/or take away, any of the employees of Company, either for Recipient\u2019s own account or for any third party.\n"
        },
        {
            "insert": "Recipient hereby acknowledges and agrees that Recipient will not, at any time during the business relationship, for whatever reason, whether for Recipient\u2019s own account or for the account of any other individual or business entity, disrupt, interfere, or attempt to disrupt or interfere with business or contractual relationships between Company and any of its customers, clients, contractors, or employees.\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "10. Competition",
            "attributes": {
                "bold": true
            }
        },
        {
            "insert": "\n"
        },
        {
            "insert": "Should a competing product or service be created based wholly or in part on Confidential Information supplied hereunder, Company shall be entitled to all proceeds from said product or service in perpetuity.\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "11. Severability",
            "attributes": {
                "bold": true
            }
        },
        {
            "insert": "\n"
        },
        {
            "insert": "If any provision in this Agreement is held to be invalid, void, or unenforceable by a duly appointed arbitrator or by any Court, the remaining provisions shall nevertheless continue in full force. This Agreement shall be subject to the laws of the State of California.\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "12. Arbitration",
            "attributes": {
                "bold": true
            }
        },
        {
            "insert": "\n"
        },
        {
            "insert": "Any and all disputes relating to this Agreement or its breach shall be settled by arbitration in the county of [COUNTY] in the state of [STATE], in accordance with the then-current commercial rules of the American Arbitration Association (\"AAA\"), and judgment upon the award entered by the arbitrator may be entered in any Court having jurisdiction hereof. Costs of arbitration, including reasonable attorney's fees incurred in arbitration, as determined by the arbitrator, together with reasonable attorney's fees incurred by prevailing Party in Court enforcement of the arbitration award after it is rendered by the arbitrator, must be paid to the prevailing Party by the Party designated by the Arbitrator or Court. Said arbitration shall be conducted in the English language and the award rendered in United States dollars. Service of the Petition to Confirm Arbitration and written notice of the time and place of the hearing on the Petition to Confirm the Award of the Arbitrator shall be made in the manner provided herein for all notice. Such service shall be complete on personal delivery or the deposit of the Petition and notice in the United States mail.\n"
        },
        {
            "insert": "a. Should one party either dismiss or abandon his/her claim or counterclaim before hearing thereon, the other Party shall be deemed the \"prevailing Party\" pursuant to this Agreement. Should both Parties receive judgment or award on their respective claims, the Party in whose favor the larger judgment or award is rendered shall be deemed the \"prevailing Party\" pursuant to this Agreement.\n"
        },
        {
            "insert": "b. At any time after the initiation of arbitration and not less than twenty (20) days prior to the arbitration hearing, any Party may serve an offer in writing upon any other Party to the action to allow an arbitration award to be made in accordance with the terms and conditions stated in the written offer. If the offer is accepted, the offer, together with written acceptance, shall be submitted to the arbitrator and an award made thereon without further hearing between those Parties. If the offer is not accepted in writing, prior to five (5) days before the hearing or within ten (10) days of mailing of offer, whichever occurs first, it shall be deemed withdrawn and cannot be given in evidence at the hearing. If the Party to whom said written offer was made fails to obtain a larger or more beneficial monetary judgment than the offer from the arbitrator after hearing, the Party to whom the offer was made must pay to the offering Party the offering Party's costs of arbitration, including, but not limited to, AAA administrative fees, arbitrator's fees and the costs of experts necessarily incurred in preparation for the arbitration, as well as all attorney\u2019s fees incurred by the prevailing party. The attorney's fees so incurred shall form part of the judgment and shall not be reduced by the Arbitrator unless the Arbitrator determines that clear and convincing evidence has been presented that such fees are unconscionable.\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "13. Jury Waiver",
            "attributes": {
                "bold": true
            }
        },
        {
            "insert": "\n"
        },
        {
            "insert": "THE PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY, instead electing that all claims and disputes shall be resolved by arbitration. Arbitration procedures are typically more limited, more efficient and less costly than rules applicable in a court and are subject to very limited review by a court. In the event any litigation should arise between the Parties in any state or federal court in a suit to vacate or enforce an arbitration award or otherwise, THE RECIPIENT AND COMPANY WAIVE ALL RIGHTS TO A JURY TRIAL, instead electing that the dispute be resolved by a judge.\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "14. Waiver",
            "attributes": {
                "bold": true
            }
        },
        {
            "insert": "\n"
        },
        {
            "insert": "No waiver by any Party of any default or condition hereunder shall operate as a waiver of any other default or condition or of the same default or condition on a future occasion.\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "15. Entire Agreement\n"
        },
        {
            "insert": "This Agreement constitutes the entire agreement concerning the subject between the Parties and supersedes all prior and contemporaneous agreements between the Parties unless said other Agreement specifically refers to this Agreement and is executed by both Parties. This Agreement may only be amended, revoked, modified, or otherwise altered only by an instrument in writing signed by all Parties.\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "16. Injunctive Relief\n"
        },
        {
            "insert": "The Parties agree that the Confidential Information imparted hereunder is of a special, unique, and extraordinary character and the loss of exclusive use of such Confidential Information could not be reasonably compensated in any action at law. Recipient therefore agrees that Company, in addition to any other rights or remedies available by law, shall be entitled to injunctive relief, and such injunctive relief may be granted by a duly appointed Arbitrator or the Court.\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "17. Counterparts",
            "attributes": {
                "bold": true
            }
        },
        {
            "insert": "\n"
        },
        {
            "insert": "The Parties may execute this Agreement in any number of counterparts, each of which is to be deemed an original, and all of which together are to constitute one and the same instrument. Signatures to this Agreement delivered by facsimile or electronic means will have the same force, validity, and effect as original signatures.\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "IN WITNESS WHEREOF, the Parties hereto have executed this Agreement as of the Effective Date. \n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "For the Company:\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "Name: ___________________________\n"
        },
        {
            "insert": "Title: ___________________________\n"
        },
        {
            "insert": "Signature: ________________________\n"
        },
        {
            "insert": "Date: ____________________________\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "For the Recipient:\n"
        },
        {
            "insert": "\n"
        },
        {
            "insert": "Name: ___________________________\n"
        },
        {
            "insert": "Title: ___________________________\n"
        },
        {
            "insert": "Signature: ________________________\n"
        },
        {
            "insert": "Date: ____________________________\n"
        },
        {
            "insert": "\n",
            "attributes": {
                "bold": true
            }
        },
        {
            "insert": "\n",
            "attributes": {
                "bold": true
            }
        },
        {
            "insert": "\n"
        }
    ]
}


export const PlaceholdersList = {
    "data": [{
        "id": "1",
        "name_of_variable": "Effective Date",
        "Initial_value": "[EFFECTIVE DATE]",
        "current_value": "[EFFECTIVE DATE]",
        "required": true,
        "possible_actions": ["skip", "insert"],
        "status": "skip/insert/null",
        "indices": [{ "start_index": 94, "end_index": 94 + 16 }]
    },
    {
        "id": "2",
        "name_of_variable": "Company Address",
        "Initial_value": "[insert company office address]",
        "current_value": "[insert company office address]",
        "action": "skip/insert/null",
        "indices": [{ "start_index": 254, "end_index": 254 + 31 }]
    },
    {
        "id": "3",
        "name_of_variable": "Recipient name",
        "Initial_value": "[insert Recipient name]",
        "current_value": "[insert Recipient name]",
        "action": "skip/insert/null",
        "indices": [{ "start_index": 294, "end_index": 294 + 24 }]
    },
    {
        "id": "4",
        "name_of_variable": "Recipient's incorporation state",
        "Initial_value": "[insert Recipient state of formation]",
        "current_value": "[insert Recipient state of formation]",
        "action": "skip/insert/null",
        "indices": [{ "start_index": 334, "end_index": 334 + 37 }]
    },
    {
        "id": "5",
        "name_of_variable": "Recipient's entity type",
        "Initial_value": "[insert Recipient entity type]",
        "current_value": "[insert Recipient entity type]",
        "action": "skip/insert/null",
        "indices": [{ "start_index": 372, "end_index": 372 + 30 }]
    },
    {
        "id": "6",
        "name_of_variable": "Name of the Company",
        "Initial_value": "___________________________",
        "current_value": "___________________________",
        "action": "skip/insert/null",
        "indices": [{ "start_index": 14823, "end_index": 14823 + 27 }]
    }],
    "activePlaceholder": 0,
    "skippedPlaceholders": [],
    "answeredPlaceholders": []
}

export const KeyClauseList = [];
export const AssumptionsList = [];
export const DocumentData = {
    "document_id": "1",
    "version": "1",
    "content": QuillJson,
    "title": "Title of my document",
    "placeholders": PlaceholdersList,
    "key_clauses": KeyClauseList,
    "assumptions": AssumptionsList,
    "active_placeholder": null,
}

export const threadDummyData = {
    "id": "1",
    "messages": [
        {
            "id": "01c9ff25-8468-4617-bc04-38ab9d7794a0",
            "sequence_number": 1,
            "feedback_type": null,
            "message_type": "information_gathered_prompt",
            "text": "Successfully gathered information for document generation",
            "user": null,
            "created_at": "2024-09-04T06:10:45.138779Z",
            "quick_action_buttons": [
                {
                    "type": "view_collected_data",
                    "text": "View Data",
                    "hyperlink_url": null,
                    "confirmation_popup_text": null
                }
            ],
            "intent_state": null,
            "attachment_names": null
        },
        {
            "id": "01c9ff25-8468-4617-bc04-38ab9d7794a0",
            "sequence_number": 2,
            "feedback_type": null,
            "message_type": "conversation",
            "text": "Draft a Privacy Policy for my internet business Inhouse. I want to ensure that the policy is compliant with data protection laws.",
            "user": {
                "user_id": "0ed371fd-e698-4148-91ce-9b31b505b4a6",
                "first_name": "Pulkit",
                "last_name": "Pahwa",
                "user_type": "client"
            },
            "created_at": "2024-09-04T06:10:45.138779Z",
            "quick_action_buttons": [
                {
                    "type": "legal_review_request",
                    "text": "Talk to a lawyer",
                    "hyperlink_url": null,
                    "confirmation_popup_text": null
                }
            ],
            "intent_state": null,
            "attachment_names": null
        },
        {
            "id": "da9e7396-3ce0-4c23-a406-70704706ac16",
            "sequence_number": 3,
            "feedback_type": null,
            "message_type": "conversation",
            "text": "Before I can draft the Privacy Policy for INhouse, I'll need to ask you a few questions to ensure the document is tailored to your business and complies with relevant laws. Please provide answers to the following:\n\n1. What type of information will you collect from users of your website?\n2. How will you use the information collected from users?\n3. Under what circumstances will you share user information with third parties?\n4. What measures are you putting in place to protect user information?\n5. What cookies and tracking technologies will you use on your website?\n6. What is the official name of your business?\n7. Who should users contact if they have questions about the Privacy Policy?\n8. How will users be informed of changes to the Privacy Policy?\n9. What rights do users have regarding their personal information?\n\nYour answers to these questions will help in creating a comprehensive and accurate Privacy Policy for INhouse.",
            "user": null,
            "created_at": "2024-09-04T06:11:04.929963Z",
            "quick_action_buttons": [
                {
                    "type": "quick_response",
                    "text": "Skip these questions",
                    "hyperlink_url": null,
                    "confirmation_popup_text": "Are you sure you want to stop asking questions? The answers will help us tailor the draft for your requirements. I will leave placeholders in the contract for you to fill later."
                },
                {
                    "type": "legal_review_request",
                    "text": "Talk to a lawyer",
                    "hyperlink_url": null,
                    "confirmation_popup_text": null
                }
            ],
            "intent_state": "asking_questions",
            "attachment_names": null
        },
        {
            "id": "01c9ff25-8468-4617-bc04-38ab9d7794a1",
            "sequence_number": 4,
            "feedback_type": null,
            "message_type": "questions",
            "text": "Successfully gathered information for document generation",
            "user": null,
            "created_at": "2024-09-04T06:10:45.138779Z",
            "questions": [
                {
                    "text": "What is your primary reason for considering a liquor license?",
                    "hyperlink_url": null,
                    "confirmation_popup_text": null
                },
                {
                    "id": "01c9ff25-8468-4617-bc04-38ab9d7794a1",
                    "text": "What types of events will you be catering off-premise?",
                    "confirmation_popup_text": null
                },
                {
                    "id": "01c9ff25-8468-4617-bc04-38ab9d7794a1",
                    "text": "How often do you anticipate requiring the use of a liquor license for your catering services?",
                    "confirmation_popup_text": null
                }
            ],
            "intent_state": null,
            "attachment_names": null
        },
        {
            "id": "01c9ff25-8468-4617-bc04-38ab9d7794a1",
            "sequence_number": 4,
            "feedback_type": null,
            "message_type": "document_generated",
            "text": "Successfully gathered information for document generation",
            "user": null,
            "created_at": "2024-09-04T06:10:45.138779Z",
            "questions": [
                {
                    "type": "view_collected_data",
                    "text": "View Data",
                    "hyperlink_url": null,
                    "confirmation_popup_text": null
                }
            ],
            "intent_state": null,
            "attachment_names": null
        },
        {
            "id": "01c9ff25-8468-4617-bc04-38ab9d7794a1",
            "sequence_number": 5,
            "feedback_type": null,
            "message_type": "information",
            "text": "Successfully gathered information for document generation",
            "user": null,
            "created_at": "2024-09-04T06:10:45.138779Z",
            "questions": [
                {
                    "id": "01c9ff25-8468-4617-bc04-38ab9d7794a1",
                    "text": "View Data",
                    "confirmation_popup_text": null
                },
            ],
            "intent_state": null,
            "attachment_names": null
        },
    ], // ...list of thread messages
    "thread_type": "ask",
    "messagingDisabled": true,
    "document_id": null,
    "questions": { "status": "pending", "questions": [], "active_index": 2, "answers": { 0: "Thank you ji" }, "skipped": [1] },
    "title": "Thread title",
    "title_source": "ai/default",
    "legal_review_requested": null,
    "document": DocumentData
}
