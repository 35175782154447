import { X } from '@phosphor-icons/react/dist/ssr'
import useSelection from 'antd/es/table/hooks/useSelection'
import React, { useEffect } from 'react'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMeetingDetails, getLawyerHub, setShowFindBestLawyer, setShowMeetingConfirmation, setShowNextSteps, setShowScheduleMeeting } from 'src/pages/onboarding/onboardingSlice'


const ScheduleMeeting = () => {
    const dispatch = useDispatch()
    const [meetingScheduled, setMeetingScheduled] = React.useState(false)
    const [meetingDetails, setMeetingDetails] = React.useState(null)

    useCalendlyEventListener({
        onProfilePageViewed: () => console.log("onProfilePageViewed"),
        onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
        onEventTypeViewed: () => console.log("onEventTypeViewed"),
        onEventScheduled: (e) => { setMeetingScheduled(true); setMeetingDetails(e.data.payload) },
        onPageHeightResize: (e) => console.log(e.data.payload.height),
    });

    const submitLawyerMatchingRequest = () => {
        var meetingId = meetingDetails.event.uri.split('/').pop()
        if (meetingId) {
            dispatch(fetchMeetingDetails(meetingId))
            dispatch(setShowNextSteps(true));
            dispatch(setShowScheduleMeeting(false))
        }
    }

    // useEffect(() => {
    //     // TODO
    //     if (meetingScheduled) {
    //         setTimeout(() => {
    //             dispatch(setShowScheduleMeeting(false))
    //             dispatch(setShowMeetingConfirmation(true))
    //         }, 5000);
    //     }
    // }, [meetingScheduled]) // eslint-disable-line

    return (
        <>
            <div className='flex flex-col h-full p-0'>
                <X className='absolute top-4 right-4 cursor-pointer' size={20} onClick={() => dispatch(setShowScheduleMeeting(false))} />
                <InlineWidget url={process.env.REACT_APP_CALENDLY_MEETING_ID} styles={{ height: "680px", width: "1000px" }} />
                {meetingDetails && (
                    <div className='flex justify-end px-[24px] '>
                        <button className='bg-[#18475A] text-white px-4 py-2 rounded-lg mt-4' onClick={() => { submitLawyerMatchingRequest() }}>Next</button>
                    </div>
                )}
            </div >
        </>
    )
}

export default ScheduleMeeting