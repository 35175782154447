import { ArrowLeft, Briefcase, Info, PencilSimple, Plus, Users } from '@phosphor-icons/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WhiteRightArrowImage from "../../../../images/icons/arrow-right-white.svg";
import { setSelectedParties } from 'src/pages/Homepage/HomeSlice';
import ForwardIcon from "src/images/forward.svg";

const ConcernedPartyScreenComponent = (props) => {
    const dispatch = useDispatch()
    const { addNewPartyButtonClicked, backtoScreen1, createDraftContinueButtonClicked, whichPartyIam, setWhichPartyIam, concernedPartiesScreen } = props;
    const [party1Name, setParty1Name] = React.useState("");
    const [party2Name, setParty2Name] = React.useState("");
    const [primaryParty, setPrimaryParty] = React.useState({});
    const [secondaryParty, setSecondaryParty] = React.useState("");
    const [partyInvolvedCountError, setPartyInvolvedCountError] = React.useState("");

    const partiesList = useSelector((state) => state.homeslice.partiesList);
    const selectedParties = useSelector((state) => state.homeslice.selectedParties);
    const selectedDocumentType = useSelector((state) => state.homeslice.selectedDocumentType);

    useEffect(() => {
        if (selectedParties.length === 0) {
            var primaryParty = partiesList.filter(party => party.is_primary === true);
            dispatch(setSelectedParties(primaryParty));
        }
    }, [selectedParties]); // eslint-disable-line

    useEffect(() => {
        if (selectedDocumentType?.party_tags) {
            if (selectedDocumentType?.party_tags.length <= 1) {
                setParty1Name("Primary Party");
                setParty2Name("");
                setWhichPartyIam("Primary Party");
                setSecondaryParty("");

            }
            else {
                setParty1Name(selectedDocumentType.party_tags[0]);
                setParty2Name(selectedDocumentType.party_tags[1]);
                setWhichPartyIam(selectedDocumentType.party_tags[0]);
                setSecondaryParty(selectedDocumentType.party_tags[1]);
            }
        }
        else {
            setParty1Name("Primary Party");
            setParty2Name("");
        }
    }, [selectedDocumentType]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (partiesList && partiesList.length > 0) {
            const primaryParty = partiesList.find(party => party.is_primary === true);
            if (primaryParty) {
                setPrimaryParty(primaryParty);
            }
        }
    }, [partiesList]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setPartyInvolvedCountError("")
    }, [concernedPartiesScreen]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (party1Name && party1Name === whichPartyIam) {
            setSecondaryParty(party2Name);
        }
        else if (party2Name && party2Name === whichPartyIam) {
            setSecondaryParty(party1Name);
        }
    }, [whichPartyIam]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
    }, [])

    useEffect(() => {
        setPartyInvolvedCountError("")
    }, [selectedParties]); // eslint-disable-line react-hooks/exhaustive-deps

    const getInvolvedPartyCountError = () => {
        if (selectedParties.length < selectedDocumentType.min_parties || selectedParties.length > selectedDocumentType.max_parties) {

            if (selectedDocumentType.min_parties === selectedDocumentType.max_parties) {
                return `Please ensure that number of parties should be ${selectedDocumentType.min_parties}`
            } else {
                return `Please ensure that number of parties should be between ${selectedDocumentType.min_parties} and ${selectedDocumentType.max_parties}`
            }
        }
        return "";
    }

    const getEntityType = (party) => {
        if (party.entity_info) {
            return party.entity_info.type;
        }
        else {
            return "Individual";
        }
    }

    const continueButton = () => {
        // var error = getInvolvedPartyCountError();
        // if (error) {
        //     setPartyInvolvedCountError(error);
        // }
        // else {
        return createDraftContinueButtonClicked()
        // }
    }

    return (
        // <></>
        <>

            <div className="flex flex-col w-[860px] pt-[24px] pb-[32px] gap-[16px] px-[56px]">
                <h3 className='text-[18px] leading-[28.8px] font-[600] text-[#141414]'>Who are you in this document?</h3>
                {!(selectedDocumentType.min_parties === selectedDocumentType.max_parties && selectedDocumentType.min_parties === 1) && (
                    <div className="flex flex-row gap-[16px]">
                        <div className='flex flex-row gap-[16px]'>
                            <label htmlFor="whichPartyIam0" className="flex flex-row gap-[10px]" onClick={() => { setWhichPartyIam(party1Name) }}>
                                {whichPartyIam === party1Name ? <>
                                    <div className="grid place-items-center w-5">
                                        <input type="radio"
                                            onChange={() => { setWhichPartyIam(party1Name) }}
                                            className="col-start-1 row-start-1 appearance-none shrink-0 w-4 h-4 border-2 border-[#141414] rounded-full"
                                        />
                                        <div
                                            className="col-start-1 row-start-1 w-2 h-2 rounded-full bg-[#141414]"
                                        />
                                    </div>
                                </> :
                                    <input type="radio" name="whichPartyIam" id="whichPartyIam1" value={party1Name} checked={whichPartyIam === party1Name}
                                        onChange={() => { setWhichPartyIam(party1Name) }} />
                                }
                                <span className='text-[#141414]'>{party1Name}</span>
                            </label>
                            <label htmlFor="whichPartyIam0" className="flex flex-row gap-[10px]" onClick={() => { setWhichPartyIam(party2Name) }}>
                                {whichPartyIam === party2Name ?
                                    <>
                                        <div className="grid place-items-center w-5">
                                            <input type="radio"
                                                className="col-start-1 row-start-1 appearance-none shrink-0 w-4 h-4 border-2 border-[#141414] rounded-full"
                                                onChange={() => { setWhichPartyIam(party2Name) }}
                                            />
                                            <div
                                                className="col-start-1 row-start-1 w-2 h-2 rounded-full bg-[#141414]"
                                            />
                                        </div>
                                    </>
                                    :
                                    <input type="radio" name="whichPartyIam" id="whichPartyIam1" value={party2Name} checked={whichPartyIam === party2Name}
                                        onChange={() => { setWhichPartyIam(party2Name) }} />
                                }
                                <span className='text-[#141414]'>{party2Name}</span>
                            </label>
                        </div>
                    </div>
                )}
                <h3 className='text-[18px] leading-[28.8px] font-[600] text-[#141414] mt-[16px]'>
                    Party Details
                    {/* {selectedDocumentType.min_parties === 1 && ( */}
                    <span className='font-[400] text-[18px] text-[#949494]'> (optional)</span>
                    {/* )} */}
                </h3>
                <div className="flex flex-col gap-[16px] w-full ">
                    <div className="flex flex-col border rounded-[12px] w-full  pt-[16px] pb-[20px] px-[32px]">
                        <div className="flex flex-row justify-between w-[100%]">
                            <h3 className='text-[18px] leading-[28.8px] font-[600] text-[#141414] flex flex-row gap-[8px]'>
                                <span className='my-auto'>{whichPartyIam}</span>
                                <span className="text-[#949494] font-[400] my-auto">(you)</span>
                                {/* <span className="text-[#949494] font-[400] my-auto" title="Some info here"><Info size={16} className="my-auto" /></span> */}
                            </h3>
                            {/* <button className="border-white text-black py-2 rounded-lg self-end  flex flex-row gap-[10px]">
                                <span className='font-[600]'>CHANGE</span>
                                <PencilSimple size={16} className="text-[#18475A] my-auto" weight="bold" />
                            </button> */}
                        </div>
                        <div className="px-[56px]">
                            <hr className="border-[#F2F2F2] border" />
                        </div>
                        <div className="flex flex-col gap-[16px] w-full mt-[12px]">
                            <table className="w-full">
                                <tbody className="gap-y-[16px] h-[58px] flex flex-col justify-between">
                                    <tr className="leading-[21px] text-[14px] h-[21px]">
                                        <td className="text-[#141414] font-[600] w-[115px]">Legal Name</td>
                                        <td className="text-[#353535] font-[500]">{primaryParty?.full_name}</td>
                                    </tr>

                                    <tr className="leading-[21px] text-[14px] h-[21px]">
                                        <td className="text-[#141414] font-[600] w-[115px]">Type of Party</td>
                                        <td className="text-[#353535] font-[500]">{getEntityType(primaryParty)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {selectedDocumentType.max_parties !== 1 && (
                        <div className="flex flex-col gap-[8px]">
                            {selectedParties.length === 1 && (
                                <div className='flex flex-row w-full mx-auto justify-between bg-[#FAFAFA] border border-[#E4E4E4] p-[16px] gap-[12px] rounded-[8px]'>
                                    <div className="my-auto flex flex-row gap-[8px]">
                                        <Users size={24} className='my-auto' />
                                        <h3 className='text-[18px] font-[600] my-auto gap-[8px] flex flex-row'>
                                            <span className='my-auto'>{secondaryParty} Details </span>
                                            <span className="text-[#949494] font-[400]"> (other party involved) </span>
                                            <span className="text-[#949494] font-[400] my-auto" title="Some info here"><Info size={16} className="my-auto" /></span>

                                        </h3>
                                    </div>
                                    <div className="flex flex-row gap-[8px]"
                                        onClick={() => {
                                            addNewPartyButtonClicked();
                                        }}>
                                        <button className='bg-white border border-[#B6B6B6] px-[12px] py-[8px] text-[#302F2F] rounded-[8px] my-auto font-[600] flex flex-row gap-[10px]'>
                                            <span className="my-auto text-[14px] font-[500]">Add Details</span>
                                            <Plus size={16} className='my-auto text-[#13866F]' />
                                        </button>
                                    </div>
                                </div>
                            )}
                            {selectedParties.length > 1 && (
                                <div className="flex flex-col border rounded-[12px] w-full h-[210px] pt-[16px] pb-[20px] px-[32px]">
                                    <div className="flex flex-row justify-between w-[100%]">
                                        <h3 className='text-[18px] leading-[28.8px] font-[600] text-[#141414] flex flex-row  gap-[8px]'>
                                            <span className="my-auto">{secondaryParty}</span>
                                            <span className="text-[#949494] font-[400] my-auto"> (other party involved)</span>
                                            {/* <span className="text-[#949494] font-[400] my-auto" title="Some info here"><Info size={16} className="my-auto" /></span> */}
                                        </h3>
                                        <button className="border-white text-black py-2 rounded-lg self-end  flex flex-row gap-[10px]"
                                            onClick={() => { addNewPartyButtonClicked(); }}
                                        >
                                            <span className='font-[600]'>CHANGE</span>
                                            <PencilSimple size={16} className="text-[#18475A] my-auto" weight="bold" />
                                        </button>
                                    </div>
                                    {selectedParties.length > 1 && selectedParties.length >= selectedDocumentType.min_parties && (
                                        <>
                                            {selectedParties.filter(p => p.is_primary === false).map((party, index) => (
                                                <>
                                                    <div className="px-[56px]">
                                                        <hr className="border-[#F2F2F2] border" />
                                                    </div>

                                                    <table className="w-full my-[12px]">
                                                        <tbody className="gap-y-[16px] flex flex-col justify-between">
                                                            <tr className="leading-[21px] text-[14px] h-[21px]">
                                                                <td className="text-[#141414] font-[600] w-[115px]">Legal Name</td>
                                                                <td className="text-[#353535] font-[500]">{party.full_name}</td>
                                                            </tr>

                                                            {/* <tr className="leading-[21px] text-[14px] h-[21px]">
                                                                <td className="text-[#141414] font-[600] w-[115px]">Type of Party</td>
                                                                <td className="text-[#353535] font-[500]">{getEntityType(party)}</td>
                                                            </tr> */}
                                                            <tr className="leading-[21px] text-[14px] h-[21px]">
                                                                <td className="text-[#141414] font-[600] w-[115px]">State</td>
                                                                <td className="text-[#353535] font-[500]">{party?.entity_info?.state_incorporation || party?.individual_info?.state_residence}</td>
                                                            </tr>
                                                            <tr className="leading-[21px] text-[14px] h-[21px]">
                                                                <td className="text-[#141414] font-[600] w-[115px]">Address</td>
                                                                <td className="text-[#353535] font-[500]">{party?.entity_info?.address || party?.individual_info?.address || "-"}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </>
                                            ))}</>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>

            </div >
            {partyInvolvedCountError && (
                <p className=' text-center w-full text-red-500'>{partyInvolvedCountError}</p>
            )
            }
            <div className="flex justify-between w-[860px] px-[40px] mx-auto">
                <button
                    className="bg-white border-0 text-[#13866F] py-2 px-4 font-[600] text-[16p] self-end flex flex-row gap-[10px]"
                    id="document-draft-back-to-screen-1"
                    onClick={() => { backtoScreen1() }}
                >
                    <ArrowLeft size={13.75} className="text-[#13866F] my-auto" weight="bold" />  Back
                </button>
                {selectedParties.length > 1 ? (
                    <button
                        className={`bg-[#18475A] text-white py-2 px-4 rounded-lg self-end  flex flex-row gap-[10px]`}
                        id="document-draft-continue-2"
                        onClick={() => {
                            continueButton()
                        }}
                    >
                        Continue <img src={WhiteRightArrowImage} className='pt-[4px]' alt="->" />
                    </button>
                ) :
                    <button
                        className={`bg-transparent text-[#18475A] py-2 px-4 border-non self-end flex flex-row gap-[10px]`}
                        id="document-draft-continue-2"
                        onClick={() => {
                            continueButton()
                        }}
                    >
                        Skip and Proceed <img src={ForwardIcon} className='pt-[4px]' alt="->" />
                    </button>

                }
            </div>
        </>
    );
};

export default ConcernedPartyScreenComponent;