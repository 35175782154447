import { Star } from "@phosphor-icons/react";
import React, {
    useEffect,
} from "react";

import MaraLogo from '../../images/mara.png'
import LattizoriLogo from '../../images/lattizori.png'
import PresenceLogo from '../../images/presence.png'
import RanavatLogo from '../../images/ranavat.png'
import FamilyTimesLogo from '../../images/family-time.png'
import KingTide from '../../images/Kingtide.png'

import QuoteImg from '../../images/Quote.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Jordan from '../../images/authors/jordan.png'
import Dave from '../../images/authors/dave.png'
import Nostrant from '../../images/authors/jonathon-nostrant.png'
import Zabi from '../../images/authors/zabi.png'


import { useNavigate } from "react-router-dom";

const bgImages = Array.from({ length: 20 }).map((item, index) => `/images/signin-page-lawyers/${index + 1}.png`)
const brandImages = [MaraLogo, LattizoriLogo, PresenceLogo, RanavatLogo, FamilyTimesLogo];

const AuthenticationBase = ({ children }) => {
    const navigate = useNavigate();
    const testimonials = [
        {
            "text": "We tested ChatGPT, Anthropic, and several Legal AI platforms, and InHouse stood out as the most reliable. I recommend all of our business leaders use it for contracts, freeing up my time for a final review.",
            "author": "Zabi Nowaid",
            "designation": "General Counsel, (NASDAQ: MARA)",
            "rating": 5,
            "image": Zabi,
            "logo": MaraLogo
        },
        {
            "text": "I feel much more confident using AI knowing that a real lawyer is verifying everything. We have too much at stake if AI gets something wrong. Our new inhouse lawyer has become an integral part of our team.",
            "author": "Jonathon Nostrant",
            "designation": "Presence",
            "rating": 5,
            "image": Nostrant,
            "logo": PresenceLogo
        },
        {
            "text": "I'm a developer, but I don't handle enough projects to justify a full-time attorney. InHouse provides me with that same peace of mind. I regularly ask questions about construction contracts and local compliance matters.",
            "author": "Dave Lattizori",
            "designation": "Dave Lattizori",
            "rating": 5,
            "image": Dave,
            "logo": LattizoriLogo
        },
        {
            "text": "One year of InHouse costs less than what our old firm charged for a single contract. We would spend around $1,000 each time our customers negotiated our MSA. Now, we upload redlines to InHouse, and it instantly explains the impact and offers practical suggestions.",
            "author": "Jordan Rothstein",
            "designation": "King Tide Marketing Agency",
            "rating": 5,
            "image": Jordan,
            "logo": KingTide

        },
    ]
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
    };

    // useEffect(() => {
    //     // Create a script element
    //     const script = document.createElement("script");
    //     script.src = "//code.tidio.co/q4uyqgedykt3jbhlqoumm9rehps7pk0q.js";
    //     script.async = true;

    //     // Append the script to the document head or body
    //     document.body.appendChild(script);

    //     // Cleanup script on component unmount
    //     return () => {
    //         document.body.removeChild(script);
    //     };
    // }, []);

    useEffect(() => {
        var isCountryCodeAmerica = ((Intl.DateTimeFormat().resolvedOptions().timeZone) && Intl.DateTimeFormat().resolvedOptions().timeZone.includes('America'));
        if (isCountryCodeAmerica) {
            window.analytics.track("start_hotjar_recording", {
                "event": "start tracking on hotjar"
            })
        }
    }, []);

    return (
        <div className="flex flex-col overflow-y-scroll md:flex-row">
            <div className="left-side w-full md:w-[50%] md:h-screen overflow-y-scroll bg-white">
                {children}
            </div>

            <div className="right-side hidden md:flex md:w-[50%] h-screen max-h-screen overflow-hidden border justify-center items-center relative">
                <div role="card" className="absolute bg-white w-[90%] rounded-3xl py-6">
                    <Slider {...settings}>
                        {
                            testimonials.map(item => <div className="px-10">
                                <img src={QuoteImg} />
                                <p className="text-[#1B2B48] text-lg mt-3 font-normal">{item.text}</p>

                                <div role="profile" className='flex items-center justify-between mt-6'>
                                    <div role="profile-details" className="flex">
                                        <img className="object-cover w-16 h-16 rounded-full" src={item.image}></img>
                                        <div className="ml-3 ">
                                            <p className="text-[#101828] text-lg font-semibold">{item.author}</p>
                                            <p className="text-[#475467] text-base font-medium">{item.designation}</p>
                                        </div>
                                    </div>
                                    <div role="rating" className="flex text-[#FDB022]" >
                                        <Star weight="fill" size={16} />
                                        <Star weight="fill" size={16} />
                                        <Star weight="fill" size={16} />
                                        <Star weight="fill" size={16} />
                                        <Star weight="fill" size={16} />
                                    </div>
                                </div>
                            </div>)
                        }
                    </Slider>

                    <hr className="my-6 text-[#DFE1E6]" />

                    <p className="text-[#1B2B48] text-2xl font-bold font-[Lora] italic text-center">Created for happy customers</p>

                    <ul className="flex flex-wrap justify-center px-10 mt-10 grayscale ">
                        {brandImages.map(img => (
                            <li className="w-1/3 px-1 py-1 bg-transparent h-[60px] flex justify-center">
                                <img className="flex object-contain h-full rounded-lg " src={img} />
                            </li>
                        ))}
                    </ul>
                </div>

                <ul role="bg images" className="flex flex-wrap w-full h-full px-1 bg-gray-200">
                    {bgImages.map(img => (
                        <li className="w-1/4 px-1 py-1 bg-transparent h-1/5">
                            <img className="flex object-cover w-full h-full rounded-lg " src={img} />
                        </li>
                    ))}
                </ul>

            </div>

            <div className="right-side md:hidden flex md:w-[50%] border flex-col relative bg-white py-5">
                <hr className="my-6 text-[#DFE1E6]" />

                <p className="text-[#1B2B48] text-2xl font-bold font-[Lora] italic text-center">Created for happy customers</p>

                <ul className="flex flex-wrap justify-center px-10 mt-10 grayscale ">
                    {brandImages.map(img => (
                        <li className="w-1/3 px-1 py-1 bg-transparent h-[60px] flex justify-center">
                            <img className="flex object-contain h-full rounded-lg " src={img} />
                        </li>
                    ))}
                </ul>

            </div>

        </div>
    );
};

export default AuthenticationBase;
